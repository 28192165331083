import { InfoHeader } from 'components/custom/info_header';
import { TopHeader } from 'components/layouts/page/topHeader';
import SideNavbar from 'components/layouts/side_navbar';

import { MfaCard } from '../../MFA/MfaCard';

import * as API from 'services/API/MFA';
import { MfaProps } from 'services/API/MFA/interface';

import { RouteComponentProps } from 'react-router-dom';

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslate } from 'context/TranslateContext';
import { GlobalTypes } from 'redux/types';
import Swal from 'sweetalert2';

interface TwoStepVerificationPageProps {
  location: RouteComponentProps;
}

export const TwoStepVerification = ({ location }: TwoStepVerificationPageProps) => {
  const {
    auth: { user },
  } = useSelector((state: GlobalTypes.RootState) => state);

  const { translate } = useTranslate();

  const [mfaList, setMfaList] = useState<Array<MfaProps>>([]);

  const [isLoading, setIsLoading] = useState(false);

  const getMfaList = async () => {
    if (user?.id) {
      setIsLoading(true);

      if (!isLoading) {
        const res = await API.getMfaList(user.id);

        setIsLoading(false);

        if (!res.success) {
          await showErrorDialog(res?.message);
          return;
        }
        setMfaList(res.data);
      }
    } else {
      await showErrorDialog('User Not Found');
    }
  };

  const showErrorDialog = async (message: string | undefined) => {
    await Swal.fire({
      icon: 'error',
      title: translate('two_step_verification'),
      text: message ?? 'Oops',
    });
  };

  useEffect(() => {
    getMfaList();
  }, [user]);

  return (
    <>
      <TopHeader title={translate('user')} />
      <SideNavbar location={location as any} type="user" />
      <div id="main-content" className="hide-scrollbar">
        <div className="box">
          <InfoHeader name="two_step_verification" />
          <div className="bg-white round-bottom-lg py-4">
            <MfaCard
              mfaList={mfaList}
              getMfaList={getMfaList}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              isSelfMfa={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};
