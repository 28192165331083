import { InfoHeader } from 'components/custom/info_header';
import { TopHeader } from 'components/layouts/page/topHeader';
import SideNavbar from 'components/layouts/side_navbar';

import { MfaCard } from 'components/MFA/MfaCard';

import * as API from 'services/API/MFA';
import { MfaProps } from 'services/API/MFA/interface';

import { RouteComponentProps } from 'react-router-dom';
import { PaginationProps } from 'services/API/common/Fetcher';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslate } from 'context/TranslateContext';
import { FunctionDispatch, GlobalTypes } from 'redux/types';
import Swal from 'sweetalert2';
import { getFeaturesConfig } from 'actions/FeaturesConfigActions';

interface TwoStepVerificationPageProps {
  location: RouteComponentProps;
}

export const TwoStepVerification = ({ location }: TwoStepVerificationPageProps) => {
  const {
    auth: { user },
  } = useSelector((state: GlobalTypes.RootState) => state);

  const { translate } = useTranslate();
  const dispatch = useDispatch<FunctionDispatch>();

  const [mfaList, setMfaList] = useState<Array<MfaProps>>([]);

  const [pagination, setPagination] = useState<PaginationProps | null>(null);
  const [currentPage, setCurrentPage] = useState(1);

  const [isLoading, setIsLoading] = useState(false);

  const filterToParams = () => {
    const data: { [key: string]: string } = {};

    Object.assign(data, {
      limit: 10,
      page: currentPage,
    });

    return data;
  };
  const getFeatures = async () => {
    const res = await dispatch(getFeaturesConfig());
    if (!res.success) {
      await Swal.fire({
        icon: 'error',
        title: translate('two_step_verification'),
        text: res.message,
      });
    }
  };
  const getMfaList = async () => {
    const params = filterToParams();

    if (user?.company_id) {
      setIsLoading(true);

      if (!isLoading) {
        const res = await API.getCompanyMfaList(user?.company_id, params);

        setIsLoading(false);

        if (!res.success) {
          await showErrorDialog(res?.message);
          return;
        }
        setMfaList(res.data);
        setPagination(res.meta?.pagination ?? null);
      }
    } else {
      await showErrorDialog('Company Not Found');
    }
  };

  const showErrorDialog = async (message: string | undefined) => {
    await Swal.fire({
      icon: 'error',
      title: translate('two_step_verification'),
      text: message ?? 'Oops',
    });
  };

  useEffect(() => {
    if (user?.role == 'owner') {
      getFeatures();
    }
  }, []);

  useEffect(() => {
    getMfaList();
  }, [user, currentPage]);

  return (
    <>
      <TopHeader title={translate('company_info')} />
      <SideNavbar location={location as any} type="admin" />
      <div id="main-content" className="hide-scrollbar">
        <div className="box">
          <InfoHeader name="two_step_verification" />
          <div className="bg-white round-bottom-lg py-4">
            <MfaCard
              mfaList={mfaList}
              getMfaList={getMfaList}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              isSelfMfa={false}
              pagination={pagination}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
      </div>
    </>
  );
};
