import { packValidateResults, required, isInt, lengthEqual } from 'helpers/validators';
import { login } from 'types';

export const validate = (data: login, withMfa: boolean) => {
  const result = [required(data, ['username', 'password'])];

  // also validate mfa verify code
  if (withMfa) {
    result.push(required(data, ['answer']));
    result.push(isInt(data, ['answer']));
    result.push(lengthEqual(data, ['answer'], { min: 6 }));
  }

  return packValidateResults(result);
};
