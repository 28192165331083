import { between, lengthLessEqualThan, packValidateResults, required } from 'helpers/validators';

export const validate = (body: any, companyId: string) => {
  return packValidateResults([
    required({ ...body, companyId }, ['companyId', 'store_id', 'custom_id']),
    lengthLessEqualThan(body, ['custom_id'], { max: 36 }),
  ]);
};

export const validateEditToken = (body: any, companyId: string) => {
  return packValidateResults([
    required({ ...body, companyId }, ['companyId', 'custom_id']),
    lengthLessEqualThan(body, ['custom_id'], { max: 36 }),
  ]);
};

export const validateCreateTransation = (body: any) => {
  return packValidateResults([between(body, ['amount'], { min: 0.1, max: 9999999.99 })]);
};
