import axios, { defaultRequestHandler } from '../common/Fetcher';
import { AnyResponse, getToken } from './../common/Fetcher';

export const getNoticeData = (): Promise<AnyResponse> =>
  defaultRequestHandler(
    axios.get('/features/notice', {
      headers: {
        authorization: getToken(),
      },
    }),
  );
