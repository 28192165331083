import { Input } from 'components/custom/input';
import { ChangeEvent, useState, useEffect } from 'react';
import { useTranslate } from 'context/TranslateContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

interface OtpInputProps {
  legend: string;
  name: string;
  value: string;
  handleChange: (value: string) => void;
  placeholder: string;
  errMsg: string;
  isLoading: boolean;
  showResendButton?: boolean;
  onResendClick?: () => Promise<void>;
  countdowmTime?: number; // in second
  startCountDownWhenInit?: boolean;
  otpLength?: number;
}

export const OtpInput = ({
  legend,
  name,
  value,
  handleChange,
  placeholder,
  errMsg,
  isLoading,
  showResendButton = false,
  onResendClick,
  startCountDownWhenInit = false,
  countdowmTime = 60,
  otpLength = 6,
}: OtpInputProps) => {
  const { translate } = useTranslate();

  const [countdown, setCountdown] = useState<number>(0);
  const [isResendingCode, setIsResendingCode] = useState<boolean>(false);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!isLoading) {
      handleChange(e.target.value);
    }
  };

  const getResendButtonContent = () => {
    if (isResendingCode) {
      return <FontAwesomeIcon spin={true} icon={faSpinner} />;
    } else if (countdown === 0) {
      return translate('resend_otp_code');
    } else {
      return translate(`please_send_code_again_after_sec`).replace('{sec}', `${countdown}`);
    }
  };

  const onClick = async () => {
    setIsResendingCode(true);

    onResendClick && (await onResendClick());
    setCountdown(countdowmTime);

    setIsResendingCode(false);
  };

  const resendButton = () => {
    if (showResendButton) {
      return (
        <button
          type="button"
          className="btn btn-sm text-black colfax-regular"
          disabled={countdown !== 0 || isLoading || isResendingCode}
          onClick={onClick}>
          {getResendButtonContent()}
        </button>
      );
    }
  };

  useEffect(() => {
    if (showResendButton && startCountDownWhenInit) {
      setCountdown(countdowmTime);
    }
  }, []);

  // Countdown 60 seconds
  useEffect(() => {
    if (showResendButton) {
      let timeout: NodeJS.Timeout;
      if (countdown > 0) {
        timeout = setTimeout(() => setCountdown(countdown - 1), 1000);
      }

      return () => {
        timeout && clearTimeout(timeout);
      };
    }
  }, [countdown]);

  return (
    <Input
      isRequired
      type="text"
      legend={legend}
      name={name}
      value={value}
      onChange={(code) => {
        onChange(code);
      }}
      disabled={isLoading}
      placeholder={placeholder}
      error={errMsg}
      maxLength={otpLength}
      suffix={resendButton()}
    />
  );
};
