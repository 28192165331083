import { Input } from 'components/custom/input';
import { DATE_RANGE_OPTIONS, translateLabel } from 'constants/format';
import { useTranslate } from 'context/TranslateContext';
import moment, { DurationInputArg1 } from 'moment';
import { useEffect, useState } from 'react';
import { components as ReactSelectComponents } from 'react-select';
import { CustomDateRangeModal } from './CustomDateRangeModal';
import { isEqual } from 'lodash';

type DateRangeValueProps =
  | (DurationInputArg1 & {
      start_date?: string;
      end_date?: string;
    })
  | null
  | string;

interface DateRangeDropdownProps {
  value: DateRangeValueProps;
  onChange: (value: DateRangeValueProps) => void;
  isDisabled?: boolean;
}

const optionValueToObject = (optionValue: DateRangeValueProps) => {
  if (!optionValue) {
    return {
      start_date: '',
      end_date: '',
    };
  }
  const startDateMoment = moment().add(optionValue);
  const endDateMoment = moment();
  if ('months' in (optionValue as any)) {
    startDateMoment.startOf('month');
    endDateMoment.add({ months: -1 }).endOf('month');
  }
  if ('years' in (optionValue as any)) {
    startDateMoment.startOf('year');
    endDateMoment.add(optionValue).endOf('year');
  }
  return {
    start_date: startDateMoment.format('YYYY-MM-DD'),
    end_date: endDateMoment.format('YYYY-MM-DD'),
  };
};

const DateRangeOption = (props: any) => {
  if (props.data.value === null || props.data.value.years <= -100) {
    return (
      <ReactSelectComponents.Option {...props}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>{props.data.label}</span>
        </div>
      </ReactSelectComponents.Option>
    );
  }

  const { start_date, end_date } = optionValueToObject(props.data.value);

  return (
    <ReactSelectComponents.Option {...props}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span>{props.data.label}</span>
        <span>
          <small className="text-muted">
            {start_date} - {end_date}
          </small>
        </span>
      </div>
    </ReactSelectComponents.Option>
  );
};

export const DateRangeDropdown = ({
  value,
  onChange,
  isDisabled = false,
}: DateRangeDropdownProps) => {
  const { translate } = useTranslate();
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeValueProps>(value);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (selectedDateRange === null) {
      setSelectedDateRange('');
      setIsOpen(true);
      return;
    } else if (!isEqual(selectedDateRange, value)) {
      onChange(optionValueToObject(selectedDateRange));
    }
  }, [selectedDateRange]);

  const Placeholder = ({ children, ...props }: any) => {
    if (
      !props.hasValue &&
      value &&
      typeof value !== 'string' &&
      value.start_date &&
      value.end_date
    ) {
      let dateStr = `${value.start_date} ${translate('to')} ${value.end_date}`;
      if (value.start_date === value.end_date) {
        dateStr = value.start_date;
      }

      return (
        <ReactSelectComponents.Placeholder {...props}>
          <span className="text-black">{dateStr}</span>
        </ReactSelectComponents.Placeholder>
      );
    }

    return (
      <ReactSelectComponents.Placeholder {...props}>{children}</ReactSelectComponents.Placeholder>
    );
  };

  return (
    <>
      <Input
        type="react-select"
        legend=""
        options={translateLabel(DATE_RANGE_OPTIONS, translate, true)}
        value={value}
        name=""
        onChange={setSelectedDateRange}
        menuPortalTarget={document.body}
        components={{ Option: DateRangeOption, Placeholder }}
        isSearchable={false}
        disabled={isDisabled}
        styles={{
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? '#DEEBFF' : 'default',
            color: state.isSelected ? '#4D7BF3' : 'inherit',
          }),
          singleValue: (provided, state) => ({ ...provided }),
        }}
      />
      <CustomDateRangeModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onSelect={onChange}
        isDisplayTime={true}
      />
    </>
  );
};
