import { getList } from 'actions/MerchantTokenActions';
import { CustomTable } from 'components/custom/table/CustomTable';
import { TopHeader } from 'components/layouts/page/topHeader';
import { useTranslate } from 'context/TranslateContext';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FunctionDispatch, GlobalTypes } from 'redux/types';
import { MerchantTokenProps } from 'services/API/MerchantToken/interface';

import { DetailsModal } from './DetailsModal';
import SideFilter from 'components/custom/sideFilter';
import { Input } from 'components/custom/input';
import {
  MERCHANT_TOKEN_STATUS_COLOR,
  MERCHANT_TOKEN_STATUS_OPTIONS,
  translateLabel,
} from 'constants/format';
import { isAccountant, isSuperowner } from 'constants/Roles';
import { ActionBox } from './ActionBox';
import { CreateMerchantTokenModal } from './CreateMerchantTokenModal';
import { useSwal } from 'helpers/sweetalert';
import { CreateTransacitonModal } from './CreateTransactionModal';
import { SelectOptionProps } from 'components/custom/input/interfaces';
import { getAllStore } from 'services/API/Store';
import { useHistory } from 'react-router-dom';
import { ViewTransactionModal } from './ViewTransactionModal';

const initialFilterState = {
  custom_id: '',
  store: '',
  status: '',
};

export const MerchantToken = () => {
  const {
    auth: { user, roles, features },
    data: { merchantTokens },
  } = useSelector((state: GlobalTypes.RootState) => state);
  const dispatch = useDispatch<FunctionDispatch>();
  const [Swal] = useSwal();
  const { translate } = useTranslate();
  const history = useHistory();

  const { merchantTokens: merchantTokensData, merchantTokensPagination } = merchantTokens;
  const [filter, setFilter] = useState(initialFilterState);
  const [currentPage, setCurrentPage] = useState(1);
  const [isFetching, setIsFetching] = useState(false);
  const [selectedMerchantToken, setSelectedMerchantToken] = useState<null | MerchantTokenProps>(
    null,
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCreateMerchantTokenModalOpen, setIsCreateMerchantTokenModalOpen] = useState(false);
  const [isCreateTransactionModalOpen, setIsCreateTransactionModalOpen] = useState(false);
  const [isViewTransactionModalOpen, setIsViewTransactionModalOpen] = useState(false);
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  const [storeOptions, setStoreOptions] = useState<Array<SelectOptionProps>>([]);

  const { custom_id, store, status } = filter;

  const getInitialData = async () => {
    const data = filterToParams();

    setIsFetching(true);
    const res = await dispatch(getList(user?.company_id, data));
    setIsFetching(false);
    if (!res.success) {
      return await Swal.fire({
        icon: 'error',
        title: translate('payment_token'),
        text: res.message || 'Oops',
      });
    }
  };

  const getStoreOptions = async () => {
    const res = await getAllStore(user?.company_id);
    if (!res.success) {
      Swal.fire({
        icon: 'error',
        title: translate('store'),
        text: res?.message || 'Oops',
      });
      return;
    }
    const list = res.data.map((store) => {
      return {
        label: store.name,
        value: store.id,
      };
    });
    setStoreOptions(list);
  };

  const filterToParams = () => {
    const data: { [key: string]: any } = {
      custom_id: custom_id,
      store_id: store,
      status: status,
    };

    const names = Object.keys(data);
    const values = Object.values(data);

    values.forEach((val, index) => {
      if (val === '' || val === '0' || val.length === 0) {
        delete data[names[index]];
      }
    });

    Object.assign(data, {
      limit: 10,
      page: currentPage,
      include: 'store',
      sort: `-created_at`,
    });

    return data;
  };

  const onFilterSearch = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (currentPage === 1) {
      getInitialData();
    } else {
      setCurrentPage(1);
    }
  };

  const onFilterChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value,
    });
  };

  const onFilterSelectChange = (name: string) => (value: any) => {
    setFilter({
      ...filter,
      [name]: value,
    });
  };

  const resetFilter = () => {
    setFilter(initialFilterState);
  };

  const onViewBtnClicked = () => {
    setIsModalOpen(true);
  };

  const onCreateTransactionBtnCliced = () => {
    setIsCreateTransactionModalOpen(true);
  };

  const onViewTransactionBtnCliced = () => {
    setIsViewTransactionModalOpen(true);
  };

  const tableHeader = () => {
    return (
      <tr>
        <th className="border-right border-top-0">{translate('store')}</th>
        <th className="border-right border-top-0">{translate('custom_id')}</th>
        <th className="border-right border-top-0">{translate('name')}</th>
        <th className="border-right border-top-0">{translate('gateway')}</th>
        <th className="border-right border-top-0">{translate('status')}</th>
        <th className="border-right border-top-0">{translate('created_at')}</th>
        <th className="border-right border-top-0">{translate('updated_at')}</th>
      </tr>
    );
  };

  const renderData = () => {
    if (isFetching) {
      return [];
    }

    return merchantTokensData.map((data, index) => {
      const {
        id,
        store,
        store_id,
        custom_id,
        name,
        gateway_sub_name,
        type,
        status,
        created_at,
        updated_at,
      } = data;

      const onClick = () => {
        setSelectedMerchantToken(data as any);
        setIsCollapseOpen(true);
      };

      return (
        <tr className="cursor-pointer" key={index} onClick={onClick}>
          <td className="">{store?.name ?? store_id}</td>
          <td className="">{custom_id}</td>
          <td className="">{name || '-'}</td>
          <td className="">{gateway_sub_name || '-'}</td>
          <td className={`text-${MERCHANT_TOKEN_STATUS_COLOR[status]}`}>
            {translate(`merchantTokenStatus.${status}`)}
          </td>
          <td className="">{created_at}</td>
          <td className="">{updated_at}</td>
        </tr>
      );
    });
  };

  useEffect(() => {
    if (!features?.tokenization?.enable) {
      history.push('/');
      return;
    }

    getInitialData();
    getStoreOptions();

    // eslint-disable-next-line
  }, [currentPage]);

  useEffect(() => {
    if (selectedMerchantToken && merchantTokensData) {
      const paymentToken = merchantTokensData.find(
        (token) => token.id === selectedMerchantToken.id,
      );
      setSelectedMerchantToken(paymentToken ?? null);
    }
  }, [merchantTokensData]);

  return (
    <>
      <TopHeader title={translate('payment_token')}>
        <button
          className="header-button btn btn-success"
          onClick={() => setIsCreateMerchantTokenModalOpen(true)}
          disabled={isFetching}>
          {translate('create_payment_token')}
        </button>
      </TopHeader>
      <div id="main-content" className="hide-scrollbar">
        <CustomTable
          renderHeading={() => tableHeader()}
          renderData={renderData}
          totalColumn={9}
          setCurrentPage={setCurrentPage}
          pagination={merchantTokensPagination}
          isLoading={isFetching}
        />
      </div>
      <div id="side-content" className="hide-scrollbar">
        <ActionBox
          reload={getInitialData}
          isOpen={!!selectedMerchantToken}
          onView={onViewBtnClicked}
          onCreateTransaction={onCreateTransactionBtnCliced}
          onViewTransaction={onViewTransactionBtnCliced}
          selectedMerchantToken={selectedMerchantToken}
        />
        <SideFilter isLoading={isFetching} onFilter={onFilterSearch} resetFilter={resetFilter}>
          <Input
            type="text"
            legend={translate('custom_id').toUpperCase()}
            value={custom_id}
            name="custom_id"
            onChange={onFilterChange}
            placeholder={translate('custom_id')}
          />
          <Input
            type="react-select"
            legend={translate('store').toUpperCase()}
            options={storeOptions}
            value={store}
            name="store"
            onChange={onFilterSelectChange('store')}
            placeholder={translate('store')}
            defaultLabel={translate('all')}
            menuPortalTarget={document.body}
          />
          <Input
            type="react-select"
            legend={translate('status').toUpperCase()}
            options={translateLabel(MERCHANT_TOKEN_STATUS_OPTIONS, translate)}
            value={status}
            name="status"
            onChange={onFilterSelectChange('status')}
            placeholder={translate('status')}
            defaultLabel={translate('all')}
            menuPortalTarget={document.body}
          />
        </SideFilter>
      </div>
      {selectedMerchantToken && (
        <DetailsModal
          refreshData={getInitialData}
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          paymentToken={selectedMerchantToken as any}
        />
      )}

      <CreateMerchantTokenModal
        isOpen={isCreateMerchantTokenModalOpen}
        setIsOpen={setIsCreateMerchantTokenModalOpen}
        reloadData={getInitialData}
      />

      <CreateTransacitonModal
        isOpen={isCreateTransactionModalOpen}
        setIsOpen={setIsCreateTransactionModalOpen}
        selectedMerchantToken={selectedMerchantToken}
      />

      <ViewTransactionModal
        isOpen={isViewTransactionModalOpen}
        setIsOpen={setIsViewTransactionModalOpen}
        selectedMerchantToken={selectedMerchantToken}
        reloadData={getInitialData}
      />
    </>
  );
};
