import { DetailRow } from 'components/custom/DetailRow';
import { Input } from 'components/custom/input';
import { useTranslate } from 'context/TranslateContext';
import { useSwal } from 'helpers/sweetalert';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap';
import { PreAuthTransactionProps } from 'services/API/PreAuthorize/interface';
import { validate } from './validator';
import { capturePreAuthTransaction } from 'services/API/PreAuthorize';

interface CaptureModalProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  preAuth?: PreAuthTransactionProps;
  refreshDetails: () => void;
}

const initialData = {
  currency: 'HKD',
  amount: '',
};

export const CaptureModal = ({ isOpen, setIsOpen, preAuth, refreshDetails }: CaptureModalProps) => {
  const [Swal] = useSwal();
  const { translate } = useTranslate();
  const [errors, setErrors] = useState<any>({});
  const [formData, setFormData] = useState(initialData);
  const [isLoading, setIsLoading] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const onChange = (e: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    const body = {
      amount: formData.amount,
      currency: formData.currency,
    };

    const { errors, isValid } = validate(body, Number(preAuth?.amount ?? 0));
    setErrors(errors);

    if (!isValid) {
      setIsLoading(false);
      return;
    }

    const result = await Swal.fire({
      title: translate('capture'),
      text: translate('are_you_sure_capture_authorization'),
      icon: 'warning',
      showCancelButton: true,
    });

    if (!result.value) {
      setIsLoading(false);
      return;
    }

    const res = await capturePreAuthTransaction(preAuth!.id, body as any);

    if (!res.success) {
      Swal.fire({
        title: translate('capture'),
        text: res?.message || 'Oops',
        icon: 'error',
      });
      setIsLoading(false);
      return;
    }

    await Swal.fire({
      icon: 'success',
      title: translate('pre_authorize'),
      text: translate('congratulations_authorization_successfully_captured'),
    });

    refreshDetails();
    setIsLoading(false);
    setIsOpen(false);
  };

  useEffect(() => {
    setFormData({
      ...formData,
      amount: '',
    });
  }, [isOpen]);

  if (!preAuth) {
    return <></>;
  }

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>{translate('capture')}</ModalHeader>
        <ModalBody>
          <div className="p-4 text-center">
            <h5>{translate('pre_authorize_details')}</h5>
            <div className="row justify-content-center  text-secondary mt-2">
              <div className="col-lg-8 px-lg-4 transaction-details text-start">
                <DetailRow
                  name={translate('authorization_id')}
                  value={preAuth?.authorization_id ?? ''}
                />
                <DetailRow name={translate('authorized_amount')} value={preAuth?.amount ?? ''} />
                <DetailRow name={translate('currency')} value={preAuth?.currency ?? ''} />
              </div>
            </div>
            <form onSubmit={onSubmit}>
              <Input
                legend={translate('amount').toUpperCase()}
                type="cleave"
                value={formData.amount}
                name="amount"
                onChange={onChange}
                prefix="$"
                cleaveOptions={{
                  numeral: true,
                  numeralDecimalScale: 2,
                  numeralIntegerScale: 7,
                  numeralPositiveOnly: true,
                }}
                error={translate(...(errors.amount ?? ''))}
              />
              <div className="text-center mt-2">
                <button className="btn btn-primary btn-sm">
                  {isLoading ? <Spinner size="sm" /> : translate('capture')}
                </button>
              </div>
            </form>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
