import { getMe } from 'actions/AuthActions';
import { accountant, operator, owner } from 'constants/Roles';
import { useTranslate } from 'context/TranslateContext';
import { toggleLoader } from 'helpers/screenLoader';
import { FC, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Route, RouteComponentProps, Switch, useHistory } from 'react-router-dom';
import { AuthStateProps } from 'redux/state/AuthState';
import { FunctionDispatch, GlobalTypes } from 'redux/types';
import { useSwal } from 'helpers/sweetalert';
import './main.layout.scss';
import { Navbar } from './navbar/Navbar';
import { PushNotification } from './PushNotification';
import { getAccessibleRoutes, RouteProps } from './routeSettings';
import { getNoticeData } from 'services/API/Notice';

const Layout: FC<RouteComponentProps> = ({ location }: RouteComponentProps) => {
  const [loading, setLoading] = useState(true);

  const [routes, setRoutes] = useState<Array<RouteProps>>([]);

  const history = useHistory();
  const dispatch = useDispatch<FunctionDispatch>();

  const [Swal] = useSwal();
  const { translate, language } = useTranslate();

  const auth: AuthStateProps = useSelector((state: GlobalTypes.RootState) => state.auth);

  useEffect(() => {
    const fetch = async () => {
      toggleLoader(true);
      const res = await dispatch(getMe());
      if (!res.success) {
        Swal.fire({
          icon: 'error',
          title: translate('login'),
          text: res?.message ?? 'Oops',
        });
        setLoading(false);
        toggleLoader(false);
        return;
      }
      if (res.data.activated === false) {
        history.replace('/business-setup');
      }

      setRoutes(getAccessibleRoutes(res.data.role));
      setLoading(false);
      toggleLoader(false);

      const noticeDataResponse = await getNoticeData();
      if (noticeDataResponse.success) {
        await checkNotice(noticeDataResponse.data);
      }

      if (res.data.password_valid_till <= 30) {
        const result = await Swal.fire({
          icon: 'info',
          title: translate('password_will_expire', {
            days: Math.max(1, res.data.password_valid_till) + '',
          }),
          text: translate('password_will_expire_msg'),
          cancelButtonText: translate('password_will_expire_cancel_text'),
          showCancelButton: true,
        });
        if (result.value) {
          history.replace('/user/profile-info');
        }
      }
    };

    if (auth.isAuthenticated) {
      fetch();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (auth.user) {
      setRoutes(getAccessibleRoutes(auth.user.role ?? ''));
    }
  }, [auth]);

  const checkNotice = async (noticeList: any) => {
    if (noticeList.length == 0) {
      return;
    }

    // for the first phase, only show the first notice
    const noticeRecord = noticeList[0];

    if (!noticeRecord) {
      return;
    }

    const ignoreVersion = localStorage.getItem('notice_ignore_version');

    const noticeVersion = noticeRecord?.version ?? 1;
    // if noticeVersion is larger than ignoreVersion, force show the notice
    if (parseInt(noticeVersion, 10) > parseInt(ignoreVersion ?? '0', 10)) {
      localStorage.removeItem('notice_ignore_time');
      localStorage.removeItem('notice_ignore_version');
    }

    const currentTime = new Date().getTime();
    const ignoreTime = localStorage.getItem('notice_ignore_time');

    if (ignoreTime) {
      if (currentTime < parseInt(ignoreTime, 10) || !noticeRecord) {
        return;
      }
    }

    if (noticeRecord?.enable === true) {
      // for mfa features, if mfa is not enabled, show the notice
      const nextNoticeDay = parseInt(noticeRecord?.show_every_days, 10);
      let nextNoticeMessage = `${translate('remind_me_after')} ${nextNoticeDay} 
        ${translate('unit_day')}`;
      if (language == 'zh') {
        nextNoticeMessage = `${translate('remind_me_after')}`;
        nextNoticeMessage = nextNoticeMessage.replace('x', nextNoticeDay.toString());
      }

      const noticeMessageInHtml = convertNoticeMessageToHtml(noticeRecord[`message_${language}`]);

      const confirmResult = await Swal.fire({
        icon: 'info',
        title: translate('notice_title'),
        html: `<div class="notice-content">${noticeMessageInHtml}</div>`,
        confirmButtonText: nextNoticeMessage,
      });

      if (confirmResult.isConfirmed) {
        const nextTime = new Date();
        nextTime.setDate(nextTime.getDate() + nextNoticeDay);
        localStorage.setItem('notice_ignore_time', nextTime.getTime().toString());
        localStorage.setItem('notice_ignore_version', noticeRecord?.version ?? '1');
      }
    }
  };

  const convertNoticeMessageToHtml = (message: string) => {
    // handle new paragraph
    return message.replaceAll('\n', '<br><br>');
  };

  if (loading) {
    return <></>;
  }

  return (
    <div className={`d-flex ${language}`}>
      <Navbar location={location} routes={routes} role={auth.user?.role ?? ''} />

      <div id="main-container">
        <Switch>
          {routes.map((route) => (
            <Route exact path={route.path} component={route.component} key={route.path} />
          ))}
        </Switch>
      </div>
      {auth.user && [owner, operator, accountant].includes(auth.user.role) && <PushNotification />}
    </div>
  );
};

const mapStateToProps = (state: GlobalTypes.RootState) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { getMe })(Layout);
