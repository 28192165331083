import { CustomTable } from 'components/custom/table/CustomTable';
import { REFUNDED, TRANSACTION_STATUS_COLOR } from 'constants/format';
import { useTranslate } from 'context/TranslateContext';
import { useSwal } from 'helpers/sweetalert';
import { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap';
import { getMerchantTokenDetails } from 'services/API/MerchantToken';
import { MerchantTokenProps } from 'services/API/MerchantToken/interface';

interface ViewTransactionModalProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  selectedMerchantToken: MerchantTokenProps | null;
  reloadData: () => void;
}

export const ViewTransactionModal = ({
  isOpen,
  setIsOpen,
  selectedMerchantToken,
  reloadData,
}: ViewTransactionModalProps) => {
  const [Swal] = useSwal();
  const { translate } = useTranslate();
  const [isDetailsLoading, setIsDetailsLoading] = useState(true);
  const [detailsData, setDetailsData] = useState<MerchantTokenProps | null>(null);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const getDetails = async () => {
    setIsDetailsLoading(true);
    const data = {
      include: ['store', 'transactions'],
    };

    try {
      const res = await getMerchantTokenDetails(
        selectedMerchantToken?.company_id ?? '',
        selectedMerchantToken?.id ?? '',
        data,
      );
      if (res.success) {
        setDetailsData(res.data);
      } else {
        throw new Error(res.message);
      }
    } catch (error: any) {
      await Swal.fire({
        title: translate('payment_token_details'),
        text: error.message || 'Oops',
        icon: 'error',
      });
      toggle();
    } finally {
      setIsDetailsLoading(false);
    }
  };

  const viewTransaction = (id: string) => {
    const url = `/transaction?transaction_id=${id}`;
    window.open(url, '_blank');
  };

  useEffect(() => {
    if (isOpen) {
      getDetails();
    } else {
      setDetailsData(null);
    }
  }, [isOpen]);

  useEffect(() => {
    detailsData && setIsDetailsLoading(false);
  }, [detailsData]);

  const tableHeader = () => {
    return (
      <tr>
        <th className="border-right border-top-0"></th>
        <th className="border-right border-top-0">{translate('transaction_id')}</th>
        <th className="border-right border-top-0">{translate('amount')}</th>
        <th className="border-right border-top-0">{translate('status')}</th>
        <th className="border-right border-top-0">{translate('created_at')}</th>
      </tr>
    );
  };

  const transactionsData = () => {
    if (!detailsData?.transactions) {
      return [];
    }

    return detailsData?.transactions?.map((transaction: any, index: any) => {
      const { amount, tips, currency, status, transaction_id, created_at } = transaction;

      return (
        <tr key={index} className="cursor-pointer">
          <td>
            <button
              className="btn btn-success btn-sm"
              onClick={() => viewTransaction(transaction.transaction_id)}>
              {translate('view')}
            </button>
          </td>
          <td>
            <span>{transaction_id}</span>
          </td>
          <td>
            <div className="amount" data-currency={currency}>
              {amount}&nbsp;
            </div>
          </td>
          <td>
            <div className={`text-${TRANSACTION_STATUS_COLOR[status]} px-2 text-uppercase`}>
              {translate(status)}
            </div>
            {transaction.status === REFUNDED.toLowerCase() && transaction.refunded > 0 && (
              <div className="amount text-light" data-currency={currency}>
                {transaction.refunded}&nbsp;
              </div>
            )}
          </td>
          <td>{created_at}</td>
        </tr>
      );
    });
  };

  const onClickSeeMore = () => {
    return window.open(`/transaction?merchant_token_id=${selectedMerchantToken?.id}`, '_blank');
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>
        {translate('transaction_details')} ({translate('lastest_records', ['10'])})
      </ModalHeader>
      <ModalBody>
        <div className="transaction-content">
          {isDetailsLoading ? (
            <>
              <div style={{ textAlign: 'center', margin: '10px' }}>
                <Spinner />
              </div>
            </>
          ) : (
            <>
              <div>
                <CustomTable
                  renderHeading={() => tableHeader()}
                  renderData={transactionsData}
                  totalColumn={5}
                />
              </div>
              {detailsData?.transactions?.length > 0 && (
                <div className="d-flex justify-content-end px-3 py-3">
                  <button className="btn btn-primary px-2" onClick={() => onClickSeeMore()}>
                    {translate('see_more')}
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};
