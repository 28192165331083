import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import { Input } from 'components/custom/input';
import { OtpInput } from 'components/custom/OtpInput';

import { useTranslate } from 'context/TranslateContext';
import { ChangeEvent, useState, useEffect } from 'react';
import * as API from 'services/API/MFA';
import { validateCreateMfa, validateVerifyMfa } from './validator';
import { maskPhoneNumber, maskEmail } from 'helpers/functions/masking';
import { MfaType, MFA_OPTIONS } from 'constants/mfa';
import { ANY_OBJECT } from 'constants/format';
import { useSwal } from 'helpers/sweetalert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import './Mfa.scss';

export interface CreateMfaModalProps {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  setIsAddingMfa: (value: boolean) => void;
  userId: string;
  mfaType: MfaType | null;
  reload: () => void;
  isSelfCreateMfa: boolean;
}

export const CreateMfaModal = ({
  isModalOpen,
  setIsModalOpen,
  setIsAddingMfa,
  userId,
  mfaType,
  reload,
  isSelfCreateMfa,
}: CreateMfaModalProps) => {
  const countryCode = '+852';
  const initialState = {
    display: '',
    phone: '',
    email: '',
    qrCodeUrl: '',

    challengeId: '',
    verifyCode: {},
  };

  const initialErrorState = initialState;

  const { translate, language } = useTranslate();
  const [Swal] = useSwal();

  const [data, setData] = useState<ANY_OBJECT>(initialState);
  const [errors, setErrors] = useState<any>(initialErrorState);

  const [isLoading, setIsLoading] = useState(false);

  const [isQrCodeLoading, setIsQrCodeLoading] = useState(false);
  const [isResendingCode, setIsResendingCode] = useState(false);

  const [step, setStep] = useState(1);
  const [verifyCodeStep, setVerifyCodeStep] = useState(1);

  const { display, phone, email, qrCodeUrl, challengeId, verifyCode } = data;

  const reset = () => {
    setStep(1);
    setVerifyCodeStep(1);

    setData(initialState);
    setErrors(initialErrorState);

    setIsLoading(false);
    setIsAddingMfa(false);
  };

  const toggle = () => {
    if (isLoading || isResendingCode) {
      return;
    }

    setIsModalOpen(!isModalOpen);
    reset();
  };

  const goToNextStep = () => {
    setStep(step + 1);
  };

  const gotNextVerifyCodeStep = () => {
    setVerifyCodeStep(verifyCodeStep + 1);
  };

  const getNumOfCodeNeedInRegister = () => {
    const mfa = MFA_OPTIONS.find((mfa) => mfa.value == mfaType);

    if (mfa != null) {
      return mfa.numOfCodeNeedInRegister;
    }

    return 1;
  };

  const initializeVerifyCode = () => {
    const numOfCodes = getNumOfCodeNeedInRegister();
    const verifyCode: ANY_OBJECT = {};

    for (let i = 1; i <= numOfCodes; i++) {
      verifyCode[i] = '';
    }

    return verifyCode;
  };

  const spacingBetweenWords = () => {
    return language == 'en' ? ' ' : '';
  };

  const getModelHeader = () => {
    return translate('setup') + spacingBetweenWords() + translate('two_step_verification');
  };

  const getButtonText = () => {
    if (step == 2 && verifyCodeStep < getNumOfCodeNeedInRegister()) {
      return translate('next');
    }

    return translate('submit');
  };

  const canResendCode = () => {
    const mfa = MFA_OPTIONS.find((mfa) => mfa.value == mfaType);

    if (mfa != null) {
      return mfa.canResendCode;
    }

    return false;
  };

  const getOtpInputSection = () => {
    const numOfCodes = getNumOfCodeNeedInRegister();

    const legendNameList: ANY_OBJECT = {
      1:
        numOfCodes == 1
          ? translate('verify_code')
          : translate('verify_code') + ` ${verifyCodeStep}`,
      2: translate('enter_the_second_6_digit_code'),
    };

    const fieldName = `verifyCode${verifyCodeStep}`;

    return (
      <div key={fieldName}>
        <OtpInput
          legend={legendNameList[verifyCodeStep] ?? ''}
          name={fieldName}
          value={verifyCode[verifyCodeStep] ?? ''}
          handleChange={(code) => {
            handleVerifyCodeChange(code, verifyCodeStep);
          }}
          placeholder={translate('enter_verify_code')}
          isLoading={isLoading}
          errMsg={translate(...(errors[fieldName] ?? ''))}
          showResendButton={canResendCode()}
          onResendClick={resendCode}
          startCountDownWhenInit={true}
        />
      </div>
    );
  };

  const getVerifyMfaReminder = () => {
    const msgPrefix = translate('please_enter_the_verification_code') + spacingBetweenWords();

    switch (mfaType) {
      case MfaType.SMS:
        return (
          msgPrefix +
          translate('sent_to') +
          spacingBetweenWords() +
          maskPhoneNumber(countryCode + phone)
        );
      case MfaType.EMAIL:
        return msgPrefix + translate('sent_to') + spacingBetweenWords() + maskEmail(email);
      case MfaType.TOTP:
        return msgPrefix + translate('shown_in_your_authenticator_application');
      default:
        return '';
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handlePhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (/^\d*$/.test(e.target.value) && e.target.value.length <= 8) {
      setData({
        ...data,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleVerifyCodeChange = (verifyCode: string, index: number) => {
    if (/^\d*$/.test(verifyCode)) {
      const newData = { ...data };
      newData.verifyCode[index] = verifyCode;

      setData(newData);
    }
  };

  const getCreateMfaValidateData = () => {
    const createMfaValidateData = { display: data.display };

    switch (mfaType) {
      case MfaType.SMS:
        return {
          ...createMfaValidateData,
          phone: phone,
        };
      case MfaType.EMAIL:
        return {
          ...createMfaValidateData,
          email: email,
        };
      case MfaType.TOTP:
        return createMfaValidateData;
      default:
        return {};
    }
  };

  const getCreateMfaSubmitData = () => {
    const createMfaSubmitData: ANY_OBJECT = { challengeName: mfaType };

    if (!isSelfCreateMfa) {
      createMfaSubmitData.display = data.display;
    }

    switch (mfaType) {
      case MfaType.SMS:
        return {
          ...createMfaSubmitData,
          entity: countryCode + phone,
        };
      case MfaType.EMAIL:
        return {
          ...createMfaSubmitData,
          entity: email,
        };
      case MfaType.TOTP:
        return createMfaSubmitData;
      default:
        return {};
    }
  };

  const setVerifyMfaDataFromCreateMfaResponse = (createMfaReponseData: ANY_OBJECT) => {
    switch (mfaType) {
      case MfaType.SMS:
      case MfaType.EMAIL:
        setData({
          ...data,
          challengeId: createMfaReponseData?.challengeId,
        });
        break;
      case MfaType.TOTP:
        setData({
          ...data,
          challengeId: createMfaReponseData?.challengeId,
          qrCodeUrl: createMfaReponseData?.otpAuth,
        });
        break;
      default:
        break;
    }
  };

  const onButtonClick = () => {
    if (step == 1) {
      onCreateMfa();
    } else {
      onVerifyMfa();
    }
  };

  const getVerifyMfaSubmitData = () => {
    return {
      challengeId: challengeId,
      answer: Object.values(verifyCode).join(''),
      display: display,
    };
  };

  const loadQrCode = async () => {
    setIsQrCodeLoading(true);

    const createMfaSubmitData = getCreateMfaSubmitData();

    // send setup mfa API
    const res = await API.createMfa(userId, createMfaSubmitData);

    if (res.status !== 200) {
      await showErrorDialog(res?.data?.message);
      setIsQrCodeLoading(false);
      toggle();
      return;
    }

    if (res?.data?.otpAuth == null || res?.data?.otpAuth == '') {
      await showErrorDialog('QR Code not found');
      setIsQrCodeLoading(false);
      toggle();
      return;
    }

    // DEBUG LOG : show Secret
    console.log('DEBUG Secret: ', res?.data?.secret);

    setVerifyMfaDataFromCreateMfaResponse(res?.data);

    setIsQrCodeLoading(false);
  };

  const onCreateMfa = async () => {
    setIsLoading(true);
    // data submit in setup mfa API
    const createMfaValidateData = getCreateMfaValidateData();

    // validation
    const { errors, isValid } = validateCreateMfa(createMfaValidateData, mfaType);
    setErrors(errors);

    if (!isValid) {
      setIsLoading(false);
      return;
    }

    // if Mfa type is TOTP ,send setup mfa API (already send in loadQrCode)
    if (mfaType == MfaType.TOTP) {
      goToNextStep();
      setIsLoading(false);
      return;
    }

    const createMfaSubmitData = getCreateMfaSubmitData();

    // send setup mfa API
    const res = await API.createMfa(userId, createMfaSubmitData);

    if (res.status !== 200) {
      if (!isSelfCreateMfa && res?.data?.error_code === 'permission_denied') {
        await showErrorDialog(translate('please_create_two_step_verification_for_yourself_first'));
        setIsLoading(false);
        return;
      }

      await showErrorDialog(res?.data?.message);
      setIsLoading(false);
      return;
    }

    if (!isSelfCreateMfa) {
      // if owner help other set mfa, no need verify code
      setIsLoading(false);
      toggle();

      await showSuccessDialog();

      reload();
      return;
    }

    // Go to verify mfa step
    setVerifyMfaDataFromCreateMfaResponse(res?.data);

    goToNextStep();

    setIsLoading(false);
  };

  const onVerifyMfa = async () => {
    setIsLoading(true);

    // data submit in setup mfa API
    const verifyMfaValidateData = { verifyCode: verifyCode };

    // validation
    const { errors, isValid } = validateVerifyMfa(verifyMfaValidateData, verifyCodeStep);

    setErrors(errors);

    if (!isValid) {
      setIsLoading(false);
      return;
    }

    // Not yet input all verify code
    if (step == 2 && verifyCodeStep < getNumOfCodeNeedInRegister()) {
      gotNextVerifyCodeStep();
      setIsLoading(false);
      return;
    }

    const verifyMfaSubmitData = getVerifyMfaSubmitData();

    // send setup mfa API
    const res = await API.verifyMfa(verifyMfaSubmitData);

    if (res.status !== 200) {
      await showErrorDialog(res?.data?.message);
      setIsLoading(false);

      // Too many attempt of submitting answer
      if (res?.data?.error_code == 'rate_limited') {
        toggle();
        return;
      }

      // fall back to verify code step 1
      setVerifyCodeStep(1);
      return;
    }

    setIsLoading(false);
    toggle();

    await showSuccessDialog();

    reload();
  };

  const resendCode = async () => {
    // if Mfa type is TOTP , not support resend code
    if (mfaType == MfaType.TOTP) {
      return;
    }

    const createMfaSubmitData = getCreateMfaSubmitData();

    setIsResendingCode(true);

    // send setup mfa API
    const res = await API.createMfa(userId, createMfaSubmitData);

    if (res.status !== 200) {
      await showErrorDialog(res?.data?.message);
      setIsResendingCode(false);
      return;
    }

    // Go to verify mfa step
    setVerifyMfaDataFromCreateMfaResponse(res?.data);
    setIsResendingCode(false);
  };

  const showErrorDialog = async (message: string | undefined) => {
    await Swal.fire({
      icon: 'error',
      title: translate('two_step_verification'),
      text: message ?? 'Oops',
    });
  };

  const showSuccessDialog = async () => {
    await Swal.fire({
      icon: 'success',
      title: translate('two_step_verification'),
      text: translate('congratulations_two_step_verification_successfully_created'),
    });
  };

  useEffect(() => {
    if (isModalOpen) {
      reset();
      setIsAddingMfa(true);

      if (mfaType == MfaType.TOTP && !isQrCodeLoading) {
        loadQrCode();
      }
    }
  }, [isModalOpen]);

  useEffect(() => {
    setData({
      ...data,
      verifyCode: initializeVerifyCode(),
    });
  }, [mfaType]);

  if (mfaType == null) {
    return <></>;
  }

  return (
    <Modal isOpen={isModalOpen && !isQrCodeLoading} toggle={toggle} backdrop="static">
      <ModalHeader toggle={toggle}>{getModelHeader()}</ModalHeader>
      <ModalBody>
        {step == 1 && (
          <>
            <Input
              isRequired
              type="text"
              legend={translate('name_of_this_verification').toUpperCase()}
              onChange={handleChange}
              name="display"
              value={display}
              disabled={isLoading}
              error={translate(...(errors.display ?? ''))}
              placeholder={translate('enter_name_of_this_verification')}
            />

            {mfaType == MfaType.TOTP && (
              <>
                <div className="fs-6 ms-2 my-2">{translate('scan_mfa_qr_code')}</div>
                <div className="fs-6 ms-2 mt-2">{translate('install_authenticator_reminder')}</div>
                <div className="fs-6 ms-2">
                  iOS -{' '}
                  <a
                    href="https://apps.apple.com/hk/app/microsoft-authenticator/id983156458"
                    target="_blank"
                    rel="noreferrer"
                    className="break-all">
                    https://apps.apple.com/hk/app/microsoft-authenticator/id983156458
                  </a>
                </div>
                <div className="fs-6 ms-2">
                  Android -{' '}
                  <a
                    href="https://play.google.com/store/apps/details?id=com.azure.authenticator"
                    target="_blank"
                    rel="noreferrer"
                    className="break-all">
                    https://play.google.com/store/apps/details?id=com.azure.authenticator
                  </a>
                </div>
                <div className="">
                  {isQrCodeLoading ? (
                    <div className="ms-3">
                      <div className="spinner-border">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <img src={qrCodeUrl} alt="MFA TOTP QR Code" className="w-50" />
                  )}
                </div>
              </>
            )}

            {mfaType == MfaType.EMAIL && (
              <Input
                isRequired
                type="text"
                legend={translate('email').toUpperCase()}
                onChange={handleChange}
                name="email"
                value={email}
                disabled={isLoading}
                error={translate(...(errors.email ?? ''))}
                placeholder={translate('enter_email')}
              />
            )}

            {mfaType == MfaType.SMS && (
              <div className="row g-0">
                <div className="col-lg-4">
                  <Input
                    isRequired
                    type="text"
                    legend={translate('country_code').toUpperCase()}
                    name="countryCode"
                    value={countryCode}
                    disabled={true}
                  />
                </div>
                <div className="col-lg-8">
                  <Input
                    isRequired
                    type="text"
                    legend={translate('phone_number').toUpperCase()}
                    onChange={handlePhoneChange}
                    name="phone"
                    value={phone}
                    disabled={isLoading}
                    error={translate(...(errors.phone ?? ''))}
                    placeholder={translate('enter_phone')}
                  />
                </div>
              </div>
            )}
          </>
        )}

        {step == 2 && (
          <>
            <p className="fs-6">{getVerifyMfaReminder()}</p>
            {getOtpInputSection()}
          </>
        )}

        <div className="d-grid">
          <button
            className="btn btn-primary mt-3 rounded-3"
            onClick={onButtonClick}
            disabled={isLoading || isQrCodeLoading || isResendingCode}>
            {getButtonText()}
            {isLoading && <FontAwesomeIcon spin={true} icon={faSpinner} />}
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};
