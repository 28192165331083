import { TopHeader } from 'components/layouts/page/topHeader';

import { isCanAccessTopUpBalance } from 'constants/Roles';
import { useTranslate } from 'context/TranslateContext';
import { useSwal } from 'helpers/sweetalert';
import { Input } from 'components/custom/input';
import { Spinner } from 'reactstrap';

import { ANY_OBJECT } from 'constants/format';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { FunctionDispatch, GlobalTypes } from 'redux/types';
import { TopUpBalanceFieldProps } from 'services/API/TopUpBalance/interface';
import { validate } from './validator';
import { SelectOptionProps } from 'components/custom/input/interfaces';
import { getAllStore } from 'services/API/Store';
import { topUpBalance } from 'services/API/TopUpBalance';
import { DisplayCodeModal } from './DisplayCodeModal';
import { DBS_FPS_YEDPAY_ONLINE_CODE } from 'constants/TransactionGateways';

const initialState = {
  amount: '',
  store_id: '',
  email: '',
  remark: '',
  gateway_code: DBS_FPS_YEDPAY_ONLINE_CODE,
  currency: 'HKD',
} as TopUpBalanceFieldProps;

export const TopUpBalancePay = ({ location }: RouteComponentProps) => {
  const {
    auth: { user, features },
  } = useSelector((state: GlobalTypes.RootState) => state);

  const [Swal] = useSwal();
  const { translate } = useTranslate();
  const [isLoading, setIsLoading] = useState(false);
  const [isInitPage, setIsInitPage] = useState(false);
  const [showCodeModal, setShowCodeModal] = useState(false);

  const [QRCodeStore, setQRCodeStore] = useState<ANY_OBJECT>({});
  const [storeOptions, setStoreOptions] = useState<Array<SelectOptionProps>>([]);

  const [topUpBalanceForm, setTopUpBalanceForm] = useState(initialState);

  const [errors, setErrors] = useState<ANY_OBJECT>({});

  const getStoreOptions = async () => {
    setIsLoading(true);
    const res = await getAllStore(user?.company_id);
    if (!res.success) {
      Swal.fire({
        icon: 'error',
        title: translate('store'),
        text: res?.message || 'Oops',
      });
      setIsLoading(false);
      return;
    }
    const list = res.data.map((store) => {
      return {
        label: store.name,
        value: store.id,
      };
    });
    setStoreOptions(list);
    setIsLoading(false);
  };

  const onTopUpInputChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    if (e.currentTarget.name === 'amount') {
      // get value without comma
      let amount = (e.target as any).rawValue;

      // formatting
      if (amount.startsWith('.')) {
        amount = '0' + amount;
      }

      setTopUpBalanceForm({
        ...topUpBalanceForm,
        amount: amount,
      });
    } else {
      setTopUpBalanceForm({
        ...topUpBalanceForm,
        [e.target.name]: e.target.value,
      });
    }
  };

  const onTopUpSelectChange = (name: string) => (value: any) => {
    setTopUpBalanceForm({
      ...topUpBalanceForm,
      [name]: value,
    });
  };

  const onTopUpBalanceFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
    setIsLoading(true);
    e.preventDefault();
    const { isValid, errors } = validate(topUpBalanceForm);
    setErrors(errors);
    if (!isValid) {
      setIsLoading(false);
      return;
    }
    const res = await topUpBalance(topUpBalanceForm.store_id, topUpBalanceForm);
    if (!res.success) {
      await Swal.fire({
        icon: 'error',
        title: translate('top_up_balance'),
        text: res?.message || 'Oops',
      });
      setIsLoading(false);
      return;
    }

    setQRCodeStore(res.data);
    setShowCodeModal(true);
    setIsLoading(false);
  };

  useEffect(() => {
    setIsInitPage(true);
    if (features && isCanAccessTopUpBalance(features, user?.role)) {
      setIsInitPage(false);
      getStoreOptions();
      return;
    }
    window.location.href = '/';
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {!isInitPage && <TopHeader title={translate('top_up_balance')} />}
      <div id="main-content" className="hide-scrollbar">
        {!isInitPage && (
          <div className="box">
            <div className="bg-white round-bottom-lg">
              <div className="bg-white p-4 mt-1 round-bottom-lg">
                <div className="">
                  <form onSubmit={onTopUpBalanceFormSubmit}>
                    <Input
                      value={topUpBalanceForm.amount}
                      legend={translate('amount').toUpperCase()}
                      name="amount"
                      type="cleave"
                      prefix="$"
                      cleaveOptions={{
                        numeral: true,
                        numeralDecimalScale: 2,
                        numeralIntegerScale: 7,
                        numeralPositiveOnly: true,
                      }}
                      onChange={onTopUpInputChange}
                      isRequired
                      error={translate(...(errors.amount ?? ''))}
                      disabled={isLoading}
                    />
                    <Input
                      value={topUpBalanceForm.store_id}
                      legend={translate('store').toUpperCase()}
                      name="store_id"
                      type="react-select"
                      options={storeOptions}
                      placeholder={translate('store')}
                      onChange={onTopUpSelectChange('store_id')}
                      isRequired
                      menuPortalTarget={document.body}
                      error={translate(...(errors.store_id ?? ''))}
                      disabled={isLoading}
                    />
                    <Input
                      value={topUpBalanceForm.email}
                      name="email"
                      type="text"
                      placeholder={translate('email')}
                      onChange={onTopUpInputChange}
                      isRequired
                      legend={translate('email').toUpperCase()}
                      error={translate(...(errors.email ?? ''))}
                    />
                    <Input
                      value={topUpBalanceForm.remark}
                      legend={translate('remark').toUpperCase()}
                      name="remark"
                      type="textarea"
                      placeholder={translate('remark')}
                      onChange={onTopUpInputChange}
                      error={translate(...(errors.remark ?? ''))}
                      disabled={isLoading}
                    />
                    <div className="text-end mt-3 d-grid">
                      <button className="btn btn-primary" disabled={isLoading}>
                        {isLoading ? <Spinner size="sm" /> : translate('submit')}
                      </button>
                    </div>
                  </form>
                </div>
                <div className="mb-5 mb-md-0"></div>
              </div>
            </div>
          </div>
        )}
      </div>
      <DisplayCodeModal
        isOpen={showCodeModal}
        setIsOpen={setShowCodeModal}
        QRCodeStore={QRCodeStore}
      />
    </>
  );
};
