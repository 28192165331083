import { DetailRow } from 'components/custom/DetailRow';
import { useTranslate } from 'context/TranslateContext';
import { useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap';
import { GlobalTypes } from 'redux/types';
import {
  TopUpBalanceHistoryProps,
  TopUpBalanceHistoryDetailsProps,
} from 'services/API/TopUpBalance/interface';
import { getTopUpBalanceHistoryDetails } from 'services/API/TopUpBalance';
import { CustomTable } from 'components/custom/table/CustomTable';
import {
  PRECREATED,
  CAPTURED,
  AUTHORIZED,
  PARTIAL_CAPTURED,
  PENDING,
  TRANSACTION_STATUS_COLOR,
  REFUNDED,
} from 'constants/format';
import { useSwal } from 'helpers/sweetalert';

interface HistoryDetailModalProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  balance?: TopUpBalanceHistoryProps;
  refreshData: () => void;
}

export const HistoryDetailModal = ({
  isOpen,
  setIsOpen,
  balance,
  refreshData,
}: HistoryDetailModalProps) => {
  if (!balance) {
    return <></>;
  }
  const [Swal] = useSwal();
  const { store } = balance;
  const { translate } = useTranslate();
  const {
    auth: { user },
  } = useSelector((state: GlobalTypes.RootState) => state);
  const [isDetailsLoading, setIsDetailsLoading] = useState(true);
  const [detailsData, setDetailsData] = useState<TopUpBalanceHistoryDetailsProps>(
    {} as TopUpBalanceHistoryDetailsProps,
  );

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const getStatus = (code: string) => {
    switch (code) {
      case '0':
        return 'active';
      case '1':
        return 'inactive';
      case '2':
        return 'completed';
      case '3':
        return 'expired';
      default:
        return '';
    }
  };

  const viewTransaction = (id: string) => {
    const url = `/transaction?transaction_id=${id}`;
    window.open(url, '_blank');
  };

  useEffect(() => {
    if (isOpen) {
      getDetails();
    } else {
      setDetailsData({} as TopUpBalanceHistoryDetailsProps);
    }
  }, [isOpen]);

  useEffect(() => {
    if (Object.keys(detailsData).length > 0) {
      setIsDetailsLoading(false);
    }
  }, [detailsData]);

  const getDetails = async () => {
    setIsDetailsLoading(true);
    const data = {
      include: ['store', 'transactions'],
    };
    const id = balance.id;

    if (balance.status != '2') {
      // only load the transaction details if the status is completed
      setIsDetailsLoading(false);
      return;
    }

    try {
      const res = await getTopUpBalanceHistoryDetails(user?.company_id, id, data);
      if (res.success) {
        setDetailsData(res.data);
      } else {
        throw new Error(res.message);
      }
    } catch (error: any) {
      Swal.fire({
        title: translate('top_up_balance_details'),
        text: error.message || 'Oops',
        icon: 'error',
      });
    } finally {
      setIsDetailsLoading(false);
    }
  };

  const tableHeader = () => {
    return (
      <tr>
        <th className="border-end border-top-0"></th>
        <th className="border-end border-top-0">{translate('transaction_id')}</th>
        <th className="border-end border-top-0">{translate('amount')}</th>
        <th className="border-end border-top-0">{translate('tip_amount')}</th>
        <th className="border-end border-top-0">{translate('status')}</th>
        <th className="border-end border-top-0">{translate('created_at')}</th>
      </tr>
    );
  };

  const transactionsData = () => {
    return detailsData.transactions?.map((transaction: any, index: any) => {
      const { amount, tips, currency, status, transaction_id, created_at } = transaction;

      return (
        <tr key={index} className="cursor-pointer">
          <td>
            <button
              className="btn btn-success btn-sm"
              onClick={() => viewTransaction(transaction.transaction_id)}>
              {translate('view')}
            </button>
          </td>
          <td>
            <span>{transaction_id}</span>
          </td>
          <td>
            <div className="amount" data-currency={currency}>
              {amount}&nbsp;
            </div>
          </td>
          <td>
            <div className="amount" data-currency={currency}>
              {tips}&nbsp;
            </div>
          </td>
          <td>
            <div className={`text-${TRANSACTION_STATUS_COLOR[status]} px-2 text-uppercase`}>
              {translate(status)}
            </div>
            {transaction.status === REFUNDED.toLowerCase() && transaction.refunded > 0 && (
              <div className="amount text-light" data-currency={currency}>
                {transaction.refunded}&nbsp;
              </div>
            )}
          </td>
          <td>{created_at}</td>
        </tr>
      );
    });
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>{translate('top_up_balance_details')}</ModalHeader>
      <ModalBody>
        <div className="p-3">
          <div className="transaction-content">
            <h4>
              <DetailRow
                name={translate('ref_no')}
                value={balance.reference_id || '-'}
                nameWidth="col-5"
              />
              <DetailRow
                name={translate('status')}
                value={translate(getStatus(balance.status))}
                nameWidth="col-5"
              />
              <DetailRow
                name={translate('expired_at')}
                value={balance.expired_at}
                nameWidth="col-5"
              />
              <DetailRow
                name={translate('amount')}
                value={balance.amount ? `${balance.amount} ${balance.currency}` : '-'}
                nameWidth="col-5"
              />
              <DetailRow
                name={translate('store_name')}
                value={store.name ?? '-'}
                nameWidth="col-5"
              />
              <DetailRow
                name={translate('remarks')}
                value={balance.remark ?? '-'}
                nameWidth="col-5"
              />
            </h4>
          </div>
          {isDetailsLoading ? (
            <>
              <div style={{ textAlign: 'left', margin: '10px' }}>
                <Spinner size="sm" />
              </div>
            </>
          ) : (
            <>
              {detailsData.transactions && (
                <>
                  <hr className="dash-border" />
                  <div>
                    <h5>{translate('transaction_details')}</h5>
                    <CustomTable
                      renderHeading={() => tableHeader()}
                      renderData={transactionsData}
                      totalColumn={5}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};
