import { Password } from 'components/custom/Auth/Password';
import { Input } from 'components/custom/input';
import { ANY_OBJECT, TAXI_MCC } from 'constants/format';
import { useTranslate } from 'context/TranslateContext';
import isEmpty from 'lodash/isEmpty';
import { ChangeEvent, FC, FormEvent, useEffect, useState } from 'react';
import { Spinner } from 'reactstrap';
import { userProps } from 'redux/state/AuthState';
import { getMccList } from 'services/API/MCC';
import { MCCGroupProps, MCCProps } from 'services/API/MCC/interface';
import { updateProfile } from 'services/API/User';
import { UpdateProfileProps } from 'services/API/User/interface';
import { useSwal } from 'helpers/sweetalert';
import '../../layouts/button/button.scss';
import { validate } from './validator';
import { VerifyCodeModal } from 'components/MFA/VerifyCodeModal';
import { MfaType } from 'constants/mfa';
import { MfaDataProps } from '../../MFA/interface';

export interface ProfileFormProps {
  data: userProps;
  info?: any;
  reloadData: () => void;
}

const convertGroupOptions = (mcc_list: Array<MCCGroupProps>) => {
  return mcc_list.map((item) => ({
    label: `${item.name}\n${item.name_zh}`,
    value: item.group_id,
    description: item.description,
    options: convertMCCOptions(item.mcc_list),
  }));
};

const convertMCCOptions = (mcc_options: Array<MCCProps>) => {
  if (mcc_options.length === 0) {
    return [];
  }
  return mcc_options.map((mcc) => ({
    label: `${mcc.description}\n${mcc.description_zh}`,
    value: mcc.mcc,
    group_id: mcc.group_id,
  }));
};

export const ProfileForm: FC<ProfileFormProps> = ({ data, reloadData }: ProfileFormProps) => {
  const { first_name, last_name, username, phone, email } = data;

  const [Swal] = useSwal();
  const { translate } = useTranslate();

  const initialState = Object.assign(
    {
      first_name: first_name ?? '',
      last_name: last_name ?? '',
      username: username ?? '',
      phone: phone ?? '',
      email: email ?? '',
    },
    data.company
      ? {
          mcc_description: data.company.mcc ?? '',
          address: data.company.address ?? '',
        }
      : {},
    data?.company?.mcc === TAXI_MCC.toString()
      ? {
          operation_id: data.operation_id ?? '',
        }
      : {},
    data?.company?.mcc === TAXI_MCC.toString()
      ? {
          driving_license: data.driving_license ?? '',
        }
      : {},
    data?.company?.mcc === TAXI_MCC.toString()
      ? {
          taxi_number: data.taxi_number ?? '',
        }
      : {},
  );

  const [form, setForm] = useState(initialState);
  const [originalForm, setOriginalForm] = useState(initialState);
  const [errors, setErrors] = useState<any>({});

  const [passwordForm, setPassword] = useState({
    password: '',
    confirm_password: '',
  });

  const [rawMccList, setRawMccList] = useState<Array<MCCGroupProps>>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [isVerifyCodeModalOpen, setIsVerifyCodeModalOpen] = useState(false);
  const [mfaData, setMfaData] = useState<MfaDataProps>({});

  const { password, confirm_password } = passwordForm;

  const getSubmitData = () => {
    const body: UpdateProfileProps = {};
    const originalValues: Array<string> = Object.values(originalForm);
    const currentValues: Array<string> = Object.values(form);

    const names = Object.keys(originalForm);

    currentValues.forEach((val, index) => {
      if (val !== originalValues[index] && names[index] !== 'phone') {
        body[names[index]] = val;
      }
    });

    // Check if the phone exists
    const phone_number = form.phone.split(' ');
    const original_phone_number = originalForm.phone.split(' ');
    if (phone_number.length > 1) {
      if (phone_number[1] !== original_phone_number[1]) {
        body.phone = phone_number[1];
        body.country_code = phone_number[0];
      }
    } else {
      if (phone_number[0] !== original_phone_number[0]) {
        body.phone = phone_number[0];
        // @todo might change to input option for user to select different country code
        body.country_code = '+852';
      }
    }

    if (password !== '') {
      body.password = password;
    }

    return body;
  };

  const setMFaDataFromResponse = (res: ANY_OBJECT) => {
    setMfaData({
      challengeName: res?.challengeName as MfaType,
      challengeId: res?.challengeId,
      entity: res?.entity,
    });
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { errors, isValid } = validate({
      ...form,
      ...passwordForm,
    });
    setErrors(errors);

    if (!isValid) {
      return;
    }

    setIsLoading(true);

    const body = getSubmitData();

    if (isEmpty(body)) {
      Swal.fire({
        icon: 'error',
        title: translate('update_account'),
        text: translate('please_change_field_to_update'),
      });
      setIsLoading(false);
      return;
    }

    const res = await updateProfile(body);

    if (res?.code == 'MFA_CHALLENGE_REQUIRED') {
      setMFaDataFromResponse(res);

      setIsVerifyCodeModalOpen(true);
      setIsLoading(false);

      return;
    }

    showDialogFromResponse(res);

    if (res.success) {
      await reloadData();
      setOriginalForm(form);
    }
    setIsLoading(false);
  };

  const resendCode = async () => {
    const body = getSubmitData();

    const res = await updateProfile(body);

    if (res?.code == 'MFA_CHALLENGE_REQUIRED') {
      setMFaDataFromResponse(res);
      return true;
    }

    // Invalid response will close the modal
    Swal.fire({
      title: translate('two_step_verification'),
      text: translate('resend_otp_code_fail'),
      icon: 'error',
    });

    return false;
  };

  const onSubmitWithMfa = async (code: string) => {
    const body = getSubmitData();

    // set mfa Data in body
    body.challengeId = mfaData.challengeId;
    body.answer = code;

    const res = await updateProfile(body);

    showDialogFromResponse(res);

    if (res.success) {
      reloadData();
      setOriginalForm(form);
      return true;
    }

    return false;
  };

  const showDialogFromResponse = (res: ANY_OBJECT) => {
    Swal.fire({
      title: translate('update_account'),
      text: res.success
        ? translate('congratulations_profile_successfully_updated')
        : res?.message || 'Oops',
      icon: res.success ? 'success' : 'error',
    });
  };

  const getInitialMccList = async () => {
    const res = await getMccList();
    if (!res.success) {
      Swal.fire({
        title: translate('mcc'),
        text: res?.message || '',
        icon: 'error',
      });
      return;
    }
    setRawMccList(res.data);
  };

  const handleFormChange = (e: ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };
  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword({
      ...passwordForm,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (data?.company) {
      getInitialMccList();
    }
    setForm(initialState);
    setOriginalForm(initialState);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <form onSubmit={onSubmit}>
        {data?.company && (
          <Input
            type="react-select"
            value={data?.company?.mcc}
            placeholder=""
            legend={translate('business_nature').toUpperCase()}
            name="mcc"
            disabled
            options={convertGroupOptions(rawMccList)}
            error={errors.mcc}
          />
        )}
        <Input
          type="text"
          value={`${form.username}`}
          placeholder=""
          legend={translate('username').toUpperCase()}
          name="username"
          disabled={isLoading}
          readonly
        />
        <Input
          type="text"
          value={`${form.email}`}
          onChange={handleFormChange}
          placeholder=""
          legend={translate('email').toUpperCase()}
          name="email"
          disabled={isLoading}
          error={translate(...(errors.email ?? ''))}
        />
        <Input
          type="text"
          value={`${form.phone}`}
          onChange={handleFormChange}
          placeholder=""
          legend={translate('phone').toUpperCase()}
          name="phone"
          disabled={isLoading}
          isRequired={data?.company?.mcc === TAXI_MCC.toString()}
          error={translate(...(errors.phone ?? ''))}
        />
        {data?.company?.mcc === TAXI_MCC.toString() && (
          <>
            <Input
              type="text"
              value={`${form.operation_id}`}
              placeholder=""
              legend={translate('taxi_operation_id').toUpperCase()}
              name="operation_id"
              disabled
            />
            <Input
              type="text"
              value={`${form.driving_license}`}
              onChange={handleFormChange}
              placeholder=""
              legend={translate('driving_license').toUpperCase()}
              name="driving_license"
              disabled={isLoading}
              isRequired
              error={translate(...(errors.driving_license ?? ''))}
            />
            <Input
              type="text"
              value={`${form.taxi_number}`}
              onChange={handleFormChange}
              placeholder=""
              legend={translate('taxi_number').toUpperCase()}
              name="taxi_number"
              disabled={isLoading}
              isRequired
              error={translate(...(errors.taxi_number ?? ''))}
            />
          </>
        )}
        <div className="row g-0">
          <div className="col-md-6">
            <Input
              type="text"
              value={`${form.first_name}`}
              onChange={handleFormChange}
              placeholder=""
              legend={translate('first_name').toUpperCase()}
              name="first_name"
              disabled={isLoading}
              isRequired
              error={translate(...(errors.first_name ?? ''))}
            />
          </div>
          <div className="col-md-6">
            <Input
              type="text"
              value={`${form.last_name}`}
              onChange={handleFormChange}
              placeholder=""
              legend={translate('last_name').toUpperCase()}
              name="last_name"
              disabled={isLoading}
              isRequired
              error={translate(...(errors.last_name ?? ''))}
            />
          </div>
        </div>
        <div className="row g-0">
          <div className="col-md-6">
            <Password
              password={password}
              setPassword={(password) => setPassword({ ...passwordForm, password })}
              setErrors={setErrors}
              errors={errors}
              disabled={isLoading}
              isRequired={false}
            />
          </div>
          <div className="col-md-6">
            <Input
              type="password"
              value={`${confirm_password}`}
              onChange={handlePasswordChange}
              placeholder=""
              legend={translate('confirm_password').toUpperCase()}
              name="confirm_password"
              disabled={isLoading}
              error={translate(...(errors.confirm_password ?? ''))}
            />
          </div>
        </div>
        <div className="d-grid">
          <button className="btn btn-primary round-lg mt-4" disabled={isLoading}>
            {isLoading ? <Spinner size="sm" /> : translate('save')}
          </button>
        </div>
      </form>
      <VerifyCodeModal
        isModalOpen={isVerifyCodeModalOpen}
        setIsModalOpen={setIsVerifyCodeModalOpen}
        mfaType={mfaData.challengeName}
        entity={mfaData.entity}
        onResendCode={resendCode}
        onSubmit={onSubmitWithMfa}
      />
    </>
  );
};
