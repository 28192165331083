import { faAngleDown, faEye, faEyeSlash, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cancelIcon from 'assets/img/registrationForm/cancelLogo.png';
import editIcon from 'assets/img/registrationForm/editLogo.png';
import uploadLogo from 'assets/img/registrationForm/uploadLogo.png';
import cross from 'assets/img/ValidationRule/cross.png';
// import imageIcon from 'assets/img/registrationForm/imageIcon.png';
import tick from 'assets/img/ValidationRule/tick.png';
import Cleave from 'cleave.js/react';
import { ChangeEvent as CleaveChangeEvent } from 'cleave.js/react/props';
import { useTranslate } from 'context/TranslateContext';
import { handleEnterKeyPress } from 'helpers/functions/enterKey';
import { truncate } from 'helpers/functions/string';
import moment from 'moment';
import * as React from 'react';
import { ChangeEvent, KeyboardEvent as KEvent, useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Spinner } from 'reactstrap';
import { Calender } from '../datepicker/Calender';
import {
  CleaveInput,
  DateInput,
  DefaultInput,
  FileInput,
  FileProgressInput,
  NumberProps,
  ReactSelectAsyncInput,
  ReactSelectInput,
  SelectInput,
  SelectOptionProps,
  SelectOptionsContainerProps,
  SelectWithText,
  TextareaInput,
} from './interfaces';

const handleEnterKeyUp = (e: KEvent, callback: any) => {
  handleEnterKeyPress(e, callback);
};
const handleKeyUp = (e: KEvent, callback: any) => {
  callback(e);
};

export const InputBody = (
  props:
    | DefaultInput
    | TextareaInput
    | ReactSelectInput
    | SelectInput
    | SelectWithText
    | CleaveInput
    | DateInput
    | FileInput
    | SelectInput
    | FileProgressInput
    | ReactSelectAsyncInput
    | NumberProps,
) => {
  const { type, onChange, name, placeholder = '', disabled, onBlur, onFocus } = props;

  const { translate } = useTranslate();
  const [isEyeOpen, setIsEyeOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const handleEyeOpen = () => {
    setIsEyeOpen(!isEyeOpen);
  };

  useEffect(() => {
    if (type === 'date') {
      const { setShowCalender } = props as DateInput;
      // const newDate = value! instanceof Date && new Date();

      // isDate(newDate) && onChange(newDate);

      const closeKey = (e: KeyboardEvent) => {
        if (e.key === 'Escape' || e.key === 'Enter') {
          setShowCalender && setShowCalender(false);
        }
      };

      document.addEventListener('keydown', closeKey);

      return () => {
        document.removeEventListener('keydown', closeKey);
      };
    }
    //eslint-disable-next-line
  }, []);

  //@todo add typescript enum
  if (type === 'file-progress') {
    const { status, accept, multiple } = props as FileProgressInput;
    const className = status.split(' ').join('-').toLowerCase();

    let uploadIcon = <img src={uploadLogo} alt="upload Icon" />;
    let statusIcon = <></>;
    switch (status) {
      case 'uploading':
        statusIcon = <Spinner size="sm" color="warning" />;
        uploadIcon = <img src={cancelIcon} alt="upload Icon" />;
        break;
      case 'on_verification':
        statusIcon = <img src={tick} alt="" className="w-4" />;
        uploadIcon = <img src={editIcon} alt="upload Icon" />;
        break;
      case 'error':
        statusIcon = <img src={cross} alt="" className="w-4" />;
        break;
    }

    return (
      <>
        <button
          className="d-flex align-items-center w-100 pt-0 btn text-start"
          onClick={() => inputRef?.current?.click()}
          type="button"
          disabled={status === 'uploading'}>
          <div className="d-flex align-items-center w-100">
            <div className="ms-2">
              {statusIcon}
              <span
                className={`ms-3 text-secondary fx-16 colfax-regular form-upload-message form-upload-${className} text-${className}`}>
                {status && translate(status)}
              </span>
            </div>
          </div>
          {uploadIcon}
        </button>
        <input
          className="d-none"
          ref={inputRef}
          onChange={(e) => onChange && onChange(e)}
          type="file"
          multiple={multiple}
          accept={accept}
          name={name}
        />
      </>
    );
  }

  if (type === 'file') {
    const { multiple, value, files, accept } = props as FileInput;

    let text = placeholder;
    if (files?.length && files.length > 1) {
      text = translate('selected_files', files.length + '');
    } else if (value || files?.length === 1) {
      text = `${truncate(value?.name || (files?.[0] as File).name || '')}`;
    }

    return (
      <div className="d-grid w-100">
        <button
          className="btn d-flex justify-content-between align-items-center px-2 py-1"
          onClick={() => inputRef?.current?.click()}
          type="button">
          <span style={{ color: '#9092a5' }}>{text}</span>
          <img src={uploadLogo} alt="upload Icon" />
        </button>
        <input
          className="d-none"
          ref={inputRef}
          onChange={(e) => onChange && onChange(e)}
          type="file"
          multiple={multiple}
          accept={accept}
          name={name}
        />
      </div>
    );
  }

  if (type === 'date') {
    const { showCalender, setShowCalender, value } = props as DateInput;
    return (
      <>
        <div
          className="d-flex justify-content-between cursor-pointer"
          onClick={() => setShowCalender && setShowCalender(!showCalender ?? false)}>
          <input
            type="text"
            className="input form-control cursor-pointer"
            value={moment(value).format('YYYY-MM-DD')}
            onChange={(e) => e.preventDefault()}
          />
          <FontAwesomeIcon
            icon={faAngleDown}
            size={'lg'}
            className="me-2 mt-1"
            color="hsl(0,0%,80%)"
          />
        </div>
        {showCalender && (
          <div className="input-calender">
            <div className="calender">
              <div className="calender-dropdown">
                <div className="calender-header-options">
                  <h4>{translate('select_date')}</h4>
                  <button
                    className="btn text-primary"
                    onClick={() => setShowCalender && setShowCalender(false)}>
                    {translate('done')}
                  </button>
                </div>
                <Calender
                  date={value instanceof Date ? value : new Date()}
                  onDateChanged={(date: Date, name: string) => {
                    setShowCalender && setShowCalender(false);
                    onChange && onChange(date, name);
                  }}
                  name={name}
                />
              </div>
            </div>
          </div>
        )}
      </>
    );
  }

  if (type === 'cleave') {
    const { value, cleaveOptions, numberOnly } = props as CleaveInput;
    const handleChange = (e: CleaveChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
      if (numberOnly === true && !/^\d+$/.test(e.target.rawValue)) {
        e.preventDefault();
        return;
      }
      onChange && onChange(e);
    };
    return (
      <Cleave
        placeholder={placeholder}
        value={value}
        name={name}
        onChange={(e) => handleChange(e)}
        options={cleaveOptions}
        className="form-control input"
        disabled={disabled}
      />
    );
  }

  if (type === 'password') {
    const { onKeyUp, onEnterKeyUp, value, maxLength, minLength, focusRef, autoComplete } =
      props as DefaultInput;
    const { step } = props as NumberProps;

    return (
      <div style={{ display: 'flex' }}>
        <input
          ref={focusRef}
          type={isEyeOpen ? 'text' : 'password'}
          placeholder={placeholder}
          onBlur={(e) => {
            onBlur && onBlur(e);
          }}
          onChange={(e: ChangeEvent<HTMLInputElement>) => onChange && onChange(e)}
          onFocus={onFocus}
          onKeyUp={(e) => {
            onEnterKeyUp && handleEnterKeyUp(e, onEnterKeyUp);
            onKeyUp && handleKeyUp(e, onKeyUp);
          }}
          maxLength={maxLength ? maxLength : undefined}
          minLength={minLength ? minLength : undefined}
          value={value}
          name={name}
          step={step}
          disabled={disabled}
          className="form-control input col"
          autoComplete={autoComplete}
        />
        <button type="button" className="btn shadow-none passwordEye" onClick={handleEyeOpen}>
          {isEyeOpen ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
        </button>
      </div>
    );
  }

  if (type === 'checkbox') {
    const { value, onChange, name, children, focusRef } = props as DefaultInput;
    return (
      <label className="form-label checkbox mt-2 d-flex">
        <input type="hidden" name={name} value={false as any} disabled={disabled} />
        <input
          type="checkbox"
          ref={focusRef}
          name={name}
          onChange={(e: ChangeEvent<HTMLInputElement>) => onChange && onChange(e)}
          value={true as any}
          checked={value as any}
          disabled={disabled}
          style={{ cursor: disabled ? 'not-allowed' : 'inherit' }}
        />
        {children}
      </label>
    );
  }

  if (type === 'select') {
    const { value, onChange, options, name, defaultLabel } = props as SelectInput;
    return (
      <select
        name={name}
        onChange={(e: ChangeEvent<HTMLSelectElement>) => onChange && onChange(e)}
        value={value as any}
        disabled={disabled}
        className="flex-fill py-1">
        {defaultLabel && (
          <option value="">
            <span style={{ color: '#9092a5' }}>{defaultLabel}</span>
          </option>
        )}
        {options.map((option, index) => (
          <option value={option.value} key={index} className="text-black">
            {option.label}
          </option>
        ))}
      </select>
    );
  }

  if (type === 'select-text') {
    const {
      selectName,
      handleSelectChange,
      selectValue,
      options,
      onEnterKeyUp,
      onKeyUp,
      value,
      defaultLabel,
    } = props as SelectWithText;
    return (
      <div className="d-flex">
        <select
          name={selectName}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            handleSelectChange && handleSelectChange(e);
          }}
          disabled={disabled}
          value={selectValue}>
          {defaultLabel && (
            <option value="">
              <span style={{ color: '#9092a5' }}>{defaultLabel}</span>
            </option>
          )}
          {options.map((option, index) => {
            return (
              <option value={option.value} key={index}>
                {option.label}
              </option>
            );
          })}
        </select>
        <input
          type={type}
          placeholder={placeholder}
          onChange={(e) => onChange && onChange(e)}
          onKeyUp={(e) => {
            onEnterKeyUp && handleEnterKeyUp(e, onEnterKeyUp);
            onKeyUp && handleKeyUp(e, onKeyUp);
          }}
          value={value}
          name={name}
          disabled={disabled}
          className="form-control input full-width"
        />
      </div>
    );
  }

  if (type === 'react-select-async') {
    const {
      defaultOptions,
      loadOptions,
      searchIcon,
      value,
      isMulti,
      menuPortalTarget,
      components,
      style,
    } = props as ReactSelectAsyncInput;

    const styles = {
      control: () => ({
        borderStyle: 'none',
        display: 'flex',
      }),
      indicatorSeparator: () => ({
        display: 'none',
      }),
      placeholder: (provided: any) => ({
        ...provided,
        fontSize: '14px',
        fontFamily: 'open sans',
      }),
      option: (provided: any) => ({
        ...provided,
        fontSize: '14px',
        fontFamily: 'open sans',
      }),
      valueContainer: (provided: any) => ({
        ...provided,
        fontSize: '15px',
        fontFamily: 'open sans',
      }),
      ...style,
    };

    const DropdownIndicator = () => <FontAwesomeIcon icon={faSearch} color="#9092a5" />;
    const onSelect = (option: SelectOptionProps | Array<SelectOptionProps>) => {
      if (isMulti) {
        if (!option) {
          return onChange && onChange([]);
        }
        return (
          onChange && onChange((option as Array<SelectOptionProps>).map((option) => option.value))
        );
      }
      onChange && onChange((option as SelectOptionProps).value);
    };

    return (
      <AsyncPaginate
        defaultOptions={defaultOptions}
        loadOptions={loadOptions}
        styles={styles}
        components={searchIcon ? { DropdownIndicator, ...components } : components}
        placeholder={placeholder}
        loadOptionsOnMenuOpen={true}
        debounceTimeout={1000}
        // options={options}
        value={value}
        onChange={onSelect as any}
        isClearable={false}
        isDisabled={disabled}
        isMulti={isMulti}
        menuPortalTarget={menuPortalTarget}
        loadingMessage={() => translate('loading')}
        noOptionsMessage={() => translate('no_data')}
      />
    );
  }

  if (type === 'react-select') {
    const {
      options,
      value,
      searchIcon,
      defaultLabel,
      isMulti,
      isSearchable,
      menuPortalTarget,
      components,
      styles,
    } = props as ReactSelectInput;

    const defaultOption = {
      label: defaultLabel ? defaultLabel : '',
      value: '',
    };

    const reactSelectStyles = {
      control: () => ({
        borderStyle: 'none',
        display: 'flex',
      }),
      indicatorSeparator: () => ({
        display: 'none',
      }),
      placeholder: (provided: any) => ({
        ...provided,
        fontSize: '14px',
        fontFamily: 'open sans',
        cursor: isSearchable ? 'auto' : 'default',
      }),
      option: (provided: any) => ({
        ...provided,
        fontSize: '14px',
        fontFamily: 'open sans',
      }),
      valueContainer: (provided: any) => ({
        ...provided,
        fontSize: '15px',
        fontFamily: 'open sans',
      }),
      ...styles,
    };

    const DropdownIndicator = () => <FontAwesomeIcon icon={faSearch} color="#9092a5" />;

    const onSelect = (option: SelectOptionProps | Array<SelectOptionProps>) => {
      if (isMulti) {
        if (!option) {
          return onChange && onChange('');
        }
        return (
          onChange &&
          onChange((option as Array<SelectOptionProps>).map((option) => option.value).join(','))
        );
      }
      onChange && onChange((option as SelectOptionProps).value);
    };

    const instanceofSelectOptionsProps = (
      options: Array<SelectOptionProps | SelectOptionsContainerProps>,
    ) => {
      if (options.length > 0) {
        return 'options' in options[0];
      }
    };

    let flatten = options as Array<SelectOptionProps>;

    if (instanceofSelectOptionsProps(options)) {
      flatten = (options as Array<SelectOptionsContainerProps>)
        .map((options) => {
          return options.options;
        })
        .flat();
    }

    const data = isMulti
      ? flatten
          .concat(defaultLabel ? [defaultOption] : [])
          .filter((option: SelectOptionProps) => (value as Array<any>)?.includes(option.value))
      : flatten
          .concat(defaultLabel ? [defaultOption] : [])
          .filter((option: SelectOptionProps) => option.value === value)[0];

    return (
      <>
        <Select
          key={`${value}`}
          styles={reactSelectStyles}
          components={searchIcon ? { DropdownIndicator, ...components } : components}
          placeholder={placeholder}
          options={defaultLabel ? [defaultOption, ...options] : options}
          value={data}
          onChange={onSelect as any}
          isDisabled={disabled}
          isMulti={isMulti}
          isSearchable={isSearchable}
          menuPortalTarget={menuPortalTarget}
          loadingMessage={() => translate('loading')}
          noOptionsMessage={() => translate('no_data')}
        />
      </>
    );
  }

  if (type === 'textarea') {
    const { onBlur, onChange, onKeyUp, onEnterKeyUp, value, maxLength } = props as TextareaInput;

    return (
      <textarea
        placeholder={placeholder}
        onBlur={(e) => {
          onBlur && onBlur(e);
        }}
        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => onChange && onChange(e)}
        onKeyUp={(e) => {
          onEnterKeyUp && handleEnterKeyUp(e, onEnterKeyUp);
          onKeyUp && handleKeyUp(e, onKeyUp);
        }}
        maxLength={maxLength ? maxLength : undefined}
        value={value}
        name={name}
        disabled={disabled}
        className="form-control input col">
        {value}
      </textarea>
    );
  }

  const { onKeyUp, onEnterKeyUp, value, maxLength, minLength, focusRef, autoComplete } =
    props as DefaultInput;
  const { step } = props as NumberProps;

  return (
    <input
      ref={focusRef}
      type={type}
      placeholder={placeholder}
      onBlur={(e) => {
        onBlur && onBlur(e);
      }}
      onChange={(e) => onChange && onChange(e)}
      onFocus={onFocus}
      onKeyUp={(e) => {
        onEnterKeyUp && handleEnterKeyUp(e, onEnterKeyUp);
        onKeyUp && handleKeyUp(e, onKeyUp);
      }}
      maxLength={maxLength ? maxLength : undefined}
      minLength={minLength ? minLength : undefined}
      value={value}
      name={name}
      step={step}
      disabled={disabled}
      className="form-control input col"
      autoComplete={autoComplete}
    />
  );
};
