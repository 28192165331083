import { faInfoCircle, faPen, faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isAgent, isGatewayProvider } from 'constants/Roles';
import { useTranslate } from 'context/TranslateContext';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Collapse } from 'reactstrap';
import { FunctionDispatch, GlobalTypes } from 'redux/types';
import { getFpsTransaction } from 'services/API/FpsTransaction';
import { CompanyPayByLinkCode } from 'services/API/PayByLinkCode/interface';
import { getOneTransaction } from 'services/API/Transactions';
import { PayByLinkViewModal } from './PayByLinkViewModal';
import { getPreAuthTransactionDetails } from 'services/API/PreAuthorize';
import { PayByLinkAuthorizationViewModal } from './PayByLinkAuthorizationViewModal ';

interface ActionBoxProps {
  isOpen: boolean;
  payByLinkCode: CompanyPayByLinkCode;
  onEdit: (payByLinkCode: CompanyPayByLinkCode) => void;
  reload: () => void;
  setIsPrintModalOpen: (isPrintOpen: boolean) => void;
}

interface RowProps {
  name: string;
  data: string;
}

export const ActionBox = ({
  isOpen,
  payByLinkCode,
  onEdit,
  reload,
  setIsPrintModalOpen,
}: ActionBoxProps) => {
  const dispatch = useDispatch<FunctionDispatch>();
  const {
    auth: { user },
  } = useSelector((state: GlobalTypes.RootState) => state);
  const { translate } = useTranslate();
  const STATUS_COMPLETED = 2;
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState();
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);

  const openPrintModal = () => {
    setIsPrintModalOpen(true);
  };

  const getPayByLinkTransaction = async () => {
    let res = null;
    if (payByLinkCode.transaction_id) {
      res = await getOneTransaction(payByLinkCode.transaction_id);
    } else if (payByLinkCode.authorization_id) {
      res = await getPreAuthTransactionDetails(
        payByLinkCode.company_id,
        payByLinkCode.authorization_id,
        {
          include: ['store', 'transactions'],
        },
      );
    } else {
      res = await getFpsTransaction(user.company_id, payByLinkCode.integration_transaction_id);
    }
    setSelectedTransaction(res.data);
    setIsViewModalOpen(true);
  };

  const Row = ({ name, data }: RowProps) => {
    return (
      <div className="row">
        <div className="col-6">{name}</div>
        <div className="col-6 colfax-regular">{data}</div>
      </div>
    );
  };

  const code: any = {};
  if (payByLinkCode) {
    code.store_name = payByLinkCode.store_name;
    code.status = payByLinkCode.status;
    code.subject = payByLinkCode.subject;
  }

  return (
    <>
      <div className="bg-white mb-4 round-sm">
        <div className="filter-title ">
          <span className="colfax-bold">{translate('actions')}</span>
        </div>
        <div className="py-1">
          <Collapse isOpen={isOpen}>
            <div className="px-3 py-3">
              <Row name={translate('name')} data={code.subject ?? ''} />
              <Row name={translate('store_name')} data={code.store_name ?? ''} />
            </div>

            <div className="border-bottom" />
            <div className="px-3 py-3">
              <div className="row g-0">
                <div className="col-auto text-center">
                  {code.status !== STATUS_COMPLETED && (
                    <button
                      className="btn btn-warning"
                      onClick={() => onEdit(payByLinkCode)}
                      disabled={isLoading}>
                      <FontAwesomeIcon icon={faPen} />
                      &nbsp;
                      {translate('edit')}
                    </button>
                  )}
                </div>
                &nbsp;
                <div className="col-auto text-center">
                  {code.status == STATUS_COMPLETED && (
                    <button
                      className="btn btn-success"
                      onClick={() => getPayByLinkTransaction()}
                      disabled={isLoading}>
                      <FontAwesomeIcon icon={faInfoCircle} />
                      &nbsp;
                      {translate('view_transaction_details')}
                    </button>
                  )}
                </div>
                &nbsp;
                <div className="col text-center d-none">
                  <button className="btn btn-primary" onClick={openPrintModal}>
                    <FontAwesomeIcon icon={faPrint} />
                    &nbsp;
                    {translate('download_printable')}
                  </button>
                </div>
              </div>
            </div>
          </Collapse>
        </div>
      </div>

      {payByLinkCode?.authorization_id ? (
        <PayByLinkAuthorizationViewModal
          isAgentOrGatewayProvider={isAgent(user.role) || isGatewayProvider(user.role)}
          isOpen={isViewModalOpen}
          setIsOpen={setIsViewModalOpen}
          preAuth={selectedTransaction}
        />
      ) : (
        <PayByLinkViewModal
          isAgentOrGatewayProvider={isAgent(user.role) || isGatewayProvider(user.role)}
          isOpen={isViewModalOpen}
          setIsOpen={setIsViewModalOpen}
          transaction={selectedTransaction}
        />
      )}
    </>
  );
};
