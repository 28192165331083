import analysisImg from 'assets/img/navbar_components/analysis.png';
import analysisActiveImg from 'assets/img/navbar_components/analysis_active.png';
import balanceImg from 'assets/img/navbar_components/balance.png';
import balanceActiveImg from 'assets/img/navbar_components/balance_active.png';
import preAuthImg from 'assets/img/navbar_components/pre_auth.png';
import preAuthActiveImg from 'assets/img/navbar_components/pre_auth_active.png';
import codeImg from 'assets/img/navbar_components/code.png';
import codeActiveImg from 'assets/img/navbar_components/code_active.png';
import companyImg from 'assets/img/navbar_components/company.png';
import companyActiveImg from 'assets/img/navbar_components/company_active.png';
import documentImg from 'assets/img/navbar_components/document.png';
import documentActiveImg from 'assets/img/navbar_components/document_active.png';
import gatewayImg from 'assets/img/navbar_components/gateway.png';
import gatewayActiveImg from 'assets/img/navbar_components/gateway_active.png';
import paymentLinkImg from 'assets/img/navbar_components/payment_link.png';
import paymentLinkActiveImg from 'assets/img/navbar_components/payment_link_active.png';
import payPlusImg from 'assets/img/navbar_components/payplus.png';
import payPlusActiveImg from 'assets/img/navbar_components/payplus_active.png';
import settlementImg from 'assets/img/navbar_components/settlement.png';
import settlementActiveImg from 'assets/img/navbar_components/settlement_active.png';
import summaryImg from 'assets/img/navbar_components/summary.png';
import summaryActiveImg from 'assets/img/navbar_components/summary_active.png';
import transactionImg from 'assets/img/navbar_components/transaction.png';
import transactionActiveImg from 'assets/img/navbar_components/transaction_active.png';
import TtpDevices from 'components/Admin/ttp_devices';
import { StaticPayLinks } from 'components/PayLinks';
import { DynamicPayLinks } from 'components/PayLinks/DynamicPayLinks';
import { PayPlusPaymentGateway } from 'components/PayPlus/payment-gateway';
import { PayPlusTransactions } from 'components/PayPlus/transaction';
import { accountant, agent, gatewayProvider, operator, owner, superOwner } from 'constants/Roles';
import { AccountSummary, PaymentGateway } from '../../Account';
import {
  AmlDomains,
  AppKeys,
  BankAccounts,
  CheckoutDomains,
  CompanyInfo,
  Device,
  Documents,
  Stores,
  UsersAndRoles,
} from '../../Admin';
import { Balance } from '../../Balance';
import { PreAuthorize } from '../../PreAuthorize';
import GatewayProviderDocuments from '../../Documents';
import { MyCodes } from '../../MyCodes';
import { MyPersonalToken, Profile } from '../../Profile';
import { SalesAnalysis } from '../../SalesAnalysis';
import { Settlement } from '../../Settlement';
import { Transactions } from '../../Transaction';
import { MerchantToken } from 'components/MerchantToken';

export interface RouteProps {
  name: string;
  img: string;
  activeImg: string;
  path: string;
  component: any;
  deniedRoles: Array<string>;
}

export const COMPANY_INFO = {
  img: companyImg,
  activeImg: companyActiveImg,
  name: 'company_info',
  path: '/admin/documents',
  component: Documents,
  deniedRoles: [operator, gatewayProvider, agent],
};

export const GATEWAY_PROVIDER_DOCUMENTS = {
  img: documentImg,
  activeImg: documentActiveImg,
  name: 'documents',
  path: `/documents`,
  component: GatewayProviderDocuments,
  deniedRoles: [owner, operator, superOwner, agent, accountant],
};

export const getAccessibleRoutes = (role: string) =>
  ROUTES.filter((route) => !route.deniedRoles.includes(role));

const ROUTES: Array<RouteProps> = [
  {
    img: summaryImg,
    activeImg: summaryActiveImg,
    name: 'account_summary',
    path: `/account-summary`,
    component: AccountSummary,
    deniedRoles: [operator, gatewayProvider, agent],
  },
  {
    img: gatewayImg,
    activeImg: gatewayActiveImg,
    name: 'payment_gateways',
    path: `/payment-gateway`,
    component: PaymentGateway,
    deniedRoles: [operator, accountant, superOwner, gatewayProvider, agent],
  },
  {
    img: preAuthImg,
    activeImg: preAuthActiveImg,
    name: 'pre_authorize',
    path: `/pre-authorize`,
    component: PreAuthorize,
    deniedRoles: [gatewayProvider, superOwner, agent],
  },
  {
    img: balanceImg,
    activeImg: balanceActiveImg,
    name: 'balance',
    path: `/balance`,
    component: Balance,
    deniedRoles: [operator, gatewayProvider, agent],
  },
  {
    img: settlementImg,
    activeImg: settlementActiveImg,
    name: 'settlements',
    path: `/settlement`,
    component: Settlement,
    deniedRoles: [operator, gatewayProvider, agent],
  },
  {
    img: transactionImg,
    activeImg: transactionActiveImg,
    name: 'transactions',
    path: `/transaction`,
    component: Transactions,
    deniedRoles: [],
  },
  {
    img: codeImg,
    activeImg: codeActiveImg,
    name: 'my_codes',
    path: `/my-codes`,
    component: MyCodes,
    deniedRoles: [gatewayProvider, agent],
  },
  {
    img: paymentLinkImg,
    activeImg: paymentLinkActiveImg,
    name: 'pay_links',
    path: `/pay-links/static`,
    component: StaticPayLinks,
    deniedRoles: [gatewayProvider, agent],
  },
  {
    img: paymentLinkImg,
    activeImg: paymentLinkActiveImg,
    name: 'pay_links',
    path: `/pay-links/dynamic`,
    component: DynamicPayLinks,
    deniedRoles: [gatewayProvider, agent],
  },
  {
    img: analysisImg,
    activeImg: analysisActiveImg,
    name: 'analysis',
    path: `/analysis`,
    component: SalesAnalysis,
    deniedRoles: [operator, accountant, owner, superOwner],
  },
  {
    img: payPlusImg,
    activeImg: payPlusActiveImg,
    name: 'pay_plus',
    path: `/pay-plus/transaction`,
    component: PayPlusTransactions,
    deniedRoles: [gatewayProvider],
  },
  {
    img: payPlusImg,
    activeImg: payPlusActiveImg,
    name: 'pay_plus',
    path: `/pay-plus/payment-gateway`,
    component: PayPlusPaymentGateway,
    deniedRoles: [operator, accountant, superOwner, gatewayProvider, agent],
  },
  {
    img: preAuthImg,
    activeImg: preAuthActiveImg,
    name: 'payment_token',
    path: `/tokenization`,
    component: MerchantToken,
    deniedRoles: [gatewayProvider, superOwner, agent],
  },
  {
    img: companyImg,
    activeImg: companyActiveImg,
    name: 'company_info',
    path: `/admin/company-info`,
    component: CompanyInfo,
    deniedRoles: [operator, gatewayProvider, agent, accountant],
  },
  {
    img: companyImg,
    activeImg: companyActiveImg,
    name: 'documents',
    path: `/admin/documents`,
    component: Documents,
    deniedRoles: [operator, gatewayProvider, agent],
  },
  {
    img: companyImg,
    activeImg: companyActiveImg,
    name: 'stores',
    path: `/admin/stores`,
    component: Stores,
    deniedRoles: [operator, gatewayProvider, agent],
  },
  {
    img: companyImg,
    activeImg: companyActiveImg,
    name: 'domains',
    path: `/admin/aml-domains`,
    component: AmlDomains,
    deniedRoles: [accountant, operator, gatewayProvider, agent],
  },
  {
    img: companyImg,
    activeImg: companyActiveImg,
    name: 'checkout_domains',
    path: `/admin/checkout-domains`,
    component: CheckoutDomains,
    deniedRoles: [accountant, operator, gatewayProvider, agent],
  },
  {
    img: companyImg,
    activeImg: companyActiveImg,
    name: 'users_and_roles',
    path: `/admin/users-and-roles`,
    component: UsersAndRoles,
    deniedRoles: [operator, gatewayProvider, agent],
  },
  {
    img: companyImg,
    activeImg: companyActiveImg,
    name: 'bank_accounts',
    path: `/admin/bank-accounts`,
    component: BankAccounts,
    deniedRoles: [operator, gatewayProvider, agent],
  },
  {
    img: companyImg,
    activeImg: companyActiveImg,
    name: 'device',
    path: `/admin/device`,
    component: Device,
    deniedRoles: [operator, gatewayProvider, agent, superOwner],
  },
  {
    img: companyImg,
    activeImg: companyActiveImg,
    name: 'ttp_device',
    path: `/admin/ttp-device`,
    component: TtpDevices,
    deniedRoles: [operator, gatewayProvider, agent, superOwner],
  },
  {
    img: companyImg,
    activeImg: companyActiveImg,
    name: 'app_keys',
    path: `/admin/app-keys`,
    component: AppKeys,
    deniedRoles: [operator, gatewayProvider, agent, accountant, superOwner],
  },
  {
    img: companyImg,
    activeImg: companyActiveImg,
    name: 'profile_info',
    path: `/user/profile-info`,
    component: Profile,
    deniedRoles: [],
  },
  {
    img: companyImg,
    activeImg: companyActiveImg,
    name: 'my_personal_token',
    path: `/user/my-personal-token`,
    component: MyPersonalToken,
    deniedRoles: [gatewayProvider, superOwner, agent],
  },
  GATEWAY_PROVIDER_DOCUMENTS,
];

export default ROUTES;
