import Axios from 'axios';
import { params } from 'helpers/functions/param';
import {
  defaultRequestHandler,
  loginRequestHandler,
  showDocumentRequestHandler,
  rawResponseRequestHandler,
} from '../../api_middleware/requests.middleware';
import { ContentType } from './Headers';

export interface DefaultResponse {
  success: boolean;
  data: any;
  message?: string;
  file?: string;
  errors?: Array<ErrorMessage>;
  meta?: {
    pagination: PaginationProps | null;
  };
  [key: string]: unknown;
}

export interface AnyResponse extends DefaultResponse {
  data: any;
}

export interface PaginationProps {
  total: number;
  per_page: number;
  current_page: number;
  total_pages: number;
  links: {
    next: string;
    prev: string;
  };
}

export interface ErrorMessage {
  code: number;
  field: string;
  message: string;
}

export const apiBaseURL = (window as any).yedpayConfig.endpoint as string;
export const webhookBaseUrl = apiBaseURL.replace('/v1', '/webhook');

const defaultTransformRequests: any = Axios.defaults.transformRequest;

const createAxiosInstance = (url: string) => {
  return Axios.create({
    baseURL: url,
    timeout: 15000,
    transformRequest: [
      function (data, headers) {
        const dataIsString = typeof data === 'string';
        if (dataIsString) {
          data = Object.fromEntries(new URLSearchParams(data));
        }

        if (data?.['_idempotency'] ?? false) {
          headers['X-Yedpay-Idempotency-Key'] = data['_idempotency'];
          delete data['_idempotency'];
        }

        if (dataIsString) {
          data = new URLSearchParams(data).toString();
        }
        return data;
      },
      ...defaultTransformRequests,
    ],
  });
};

const axios = createAxiosInstance(apiBaseURL);
const axiosWebHook = createAxiosInstance(webhookBaseUrl);

// overwrite original error handler from axios, because we need to access the error response
// ref: https://stackoverflow.com/questions/47216452/how-to-handle-401-authentication-error-in-axios-and-react
axios.interceptors.response.use(
  (res) => res,
  (error) => error.response,
);

export const getToken = () => {
  return `Bearer ${localStorage.authYDP}`;
};

export {
  defaultRequestHandler,
  showDocumentRequestHandler,
  loginRequestHandler,
  rawResponseRequestHandler,
  ContentType,
  params,
};

export default axios;
export { axiosWebHook };
