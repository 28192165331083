export const maskPhoneNumber = (phoneNumberWithCounrtyCode: string) => {
  // assume phone number start with +852
  if (!phoneNumberWithCounrtyCode.startsWith('+852')) {
    return '*'.repeat(phoneNumberWithCounrtyCode.length);
  }

  const countryCode = '+852';
  const phoneNumber = phoneNumberWithCounrtyCode.slice(4); // remove country code

  if (phoneNumber.length <= 4) {
    return '+852' + '*'.repeat(phoneNumber.length);
  }
  const visibleDigits = 4;

  const maskedPart = '*'.repeat(phoneNumber.length - visibleDigits);
  const visiblePart = phoneNumber.slice(-visibleDigits);
  return countryCode + maskedPart + visiblePart;
};

export const maskEmail = (email: string) => {
  const parts = email.split('@');
  const localPart = parts[0];
  const domainPart = parts[1];

  if (localPart.length < 2) {
    return '*@' + domainPart;
  }

  const maskedLocalPart =
    localPart.charAt(0) + '*'.repeat(localPart.length - 2) + localPart.charAt(localPart.length - 1);
  return maskedLocalPart + '@' + domainPart;
};
