import { Input } from 'components/custom/input';
import { useTranslate } from 'context/TranslateContext';
import { useSwal } from 'helpers/sweetalert';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap';
import { validateCreateTransation } from './validator';
import { createMerchantTokenTransaction } from 'services/API/MerchantToken';
import { MerchantTokenProps } from 'services/API/MerchantToken/interface';
import { useSelector } from 'react-redux';
import { GlobalTypes } from 'redux/types';

interface CreateTransacitonModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  selectedMerchantToken: MerchantTokenProps | null;
}

const initialState = {
  amount: '',
  custom_id: '',
  currency: 'HKD',
};

export const CreateTransacitonModal = ({
  isOpen,
  setIsOpen,
  selectedMerchantToken,
}: CreateTransacitonModalProps) => {
  if (!selectedMerchantToken) {
    return <></>;
  }

  const {
    auth: { user },
  } = useSelector((state: GlobalTypes.RootState) => state);

  const [Swal] = useSwal();
  const { translate } = useTranslate();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [form, setForm] = useState<any>(initialState);

  const { amount, custom_id } = form;

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const onChange = (e: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    if (e.target.name === 'amount') {
      const { rawValue } = e.target as any;
      const regex = /^\d*\.?\d{0,2}$/;

      let amount: number | '' = rawValue;
      if (!regex.test(rawValue)) {
        amount = '';
      }
      setForm({
        ...form,
        amount,
      });
      return;
    }
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = {
      ...form,
    };

    const { errors, isValid } = validateCreateTransation(data);

    if (!isValid) {
      return setErrors(errors);
    }

    setIsLoading(true);
    const res = await createMerchantTokenTransaction(selectedMerchantToken?.id, data);
    setIsLoading(false);
    if (!res.success) {
      return await Swal.fire({
        icon: 'error',
        title: translate('payment_token'),
        text: res.message || 'Oops',
      });
    }
    await Swal.fire({
      icon: 'success',
      title: translate('create_transaction'),
      text: translate('congratulations_transaction_successfully_created'),
    });
    setIsOpen(false);
  };

  useEffect(() => {
    setForm(initialState);
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        {translate('create_token_transaction')} - {selectedMerchantToken?.custom_id}
      </ModalHeader>
      <ModalBody>
        <div className="p-2">
          <form onSubmit={onSubmit}>
            <Input
              type="text"
              value={custom_id}
              name="custom_id"
              legend={translate('custom_id').toUpperCase()}
              onChange={onChange}
              error={translate(...(errors.custom_id ?? ''))}
            />
            <Input
              isRequired
              type="cleave"
              prefix="$"
              cleaveOptions={{
                numeral: true,
                numeralDecimalScale: 2,
                numeralIntegerScale: 7,
                numeralPositiveOnly: true,
              }}
              onChange={onChange}
              value={amount as number}
              name="amount"
              legend={translate('amount').toUpperCase()}
              error={translate(...(errors.amount ?? ''))}
            />
            <button className="btn btn-primary btn-block mt-3" disabled={isLoading}>
              {isLoading ? <Spinner size="sm" /> : translate('submit')}
            </button>
          </form>
        </div>
      </ModalBody>
    </Modal>
  );
};
