export enum MfaType {
  TOTP = 'MFA_TOTP',
  SMS = 'MFA_SMS',
  EMAIL = 'MFA_EMAIL',
}

export const MFA_OPTIONS = [
  {
    label: 'authenticator',
    value: MfaType.TOTP,
    max: 3,
    numOfCodeNeedInRegister: 2,
    canResendCode: false,
    canRegisterByOther: false,
  },
  {
    label: 'phone_number',
    value: MfaType.SMS,
    max: 1,
    numOfCodeNeedInRegister: 1,
    canResendCode: true,
    canRegisterByOther: true,
  },
  {
    label: 'email',
    value: MfaType.EMAIL,
    max: 1,
    numOfCodeNeedInRegister: 1,
    canResendCode: true,
    canRegisterByOther: true,
  },
];
