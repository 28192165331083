import { getPayByLinkList } from 'actions/PayByLinkActions';
import { Input } from 'components/custom/input';
import { SideFilter } from 'components/custom/sideFilter';
import { TopHeader } from 'components/layouts/page/topHeader';
import SideNavbar from 'components/layouts/side_navbar';
import { ANY_OBJECT } from 'constants/format';
import { isAccountant, isSuperowner, operator, owner } from 'constants/Roles';
import {
  DBS_FPS_ONLINE_GATEWAY_CODE,
  HSBC_FPS_ONLINE_GATEWAY_CODE,
  ONLINE_GATEWAY_GROUP,
} from 'constants/TransactionGateways';
import { useTranslate } from 'context/TranslateContext';
import { getUserActivatedGateways } from 'helpers/functions/getUserActivatedGateways';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FunctionDispatch, GlobalTypes } from 'redux/types';
import { CompanyPayByLinkCode } from 'services/API/PayByLinkCode/interface';
import { useSwal } from 'helpers/sweetalert';
import { ActionBox } from './ActionBox';
import { CreateEditCodeModal } from './CreateEditCodeModal';
import { PrintModal } from './PrintModal';
import { StaticDataTable } from './StaticDataTable';
import { Spinner } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { getFeaturesConfig } from 'actions/FeaturesConfigActions';

const initialFilter: ANY_OBJECT = {
  amount: '',
  custom_id: '',
  subject: '',
};

export const StaticPreAuthPayLinks = () => {
  const {
    auth: {
      user,
      roles,
      features,
      user: { stores },
    },
  } = useSelector((state: GlobalTypes.RootState) => state);
  const dispatch = useDispatch<FunctionDispatch>();

  const [Swal] = useSwal();
  const { translate } = useTranslate();
  const history = useHistory();

  const [isFetching, setIsFetching] = useState(false);
  const [isFetchFeature, setIsFetchFeature] = useState(false);
  const [filter, setFilter] = useState(initialFilter);

  const [currentPage, setCurrentPage] = useState(1);

  const [payByLinkCode, setPayByLinkCode] = useState<any | null>(null);

  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  const [hasOnlineStore, setHasOnlineStore] = useState<boolean>(false);
  const [isCreateOrEditModalOpen, setIsCreateOrEditModalOpen] = useState(false);
  const [isCreateAuthorization, setIsCreateAuthorization] = useState(false);

  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);

  const onlineGatewayWithFPS = [
    ...ONLINE_GATEWAY_GROUP.code,
    HSBC_FPS_ONLINE_GATEWAY_CODE,
    DBS_FPS_ONLINE_GATEWAY_CODE,
  ];
  const hasQrCodeAvailableGateway = getUserActivatedGateways().some((item) =>
    onlineGatewayWithFPS.includes(item),
  );

  // Filter Functions
  const { amount, custom_id, subject } = filter;

  const onFilterChange = (e: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    if (e.target.name === 'amount') {
      const { rawValue } = e.target as any;
      const regex = /^\d*\.?\d{0,2}$/;

      let amount: number | '' = rawValue;
      if (!regex.test(rawValue)) {
        amount = '';
      }
      setFilter({
        ...filter,
        amount,
      });
      return;
    }
    setFilter({
      ...filter,
      [e.target.name]: e.target.value,
    });
  };

  const onFilterSearch = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (currentPage === 1) {
      getInitialData();
    } else {
      setCurrentPage(1);
    }
  };

  const resetFilter = () => {
    setFilter(initialFilter);
  };

  // Data Functions

  const getInitialData = async () => {
    setIsFetching(true);
    const data: ANY_OBJECT = {
      page: currentPage,
      limit: 10,
      include: 'store',
      is_authorization: 1,
    };

    for (const key in filter) {
      if (filter[key] !== '') {
        data[key] = filter[key];
      }
    }

    const res = await dispatch(getPayByLinkList(user.company_id, data));
    setIsFetching(false);
    if (!res.success) {
      return Swal.fire({
        icon: 'error',
        title: translate('qr_code'),
        text: res?.message || 'Oops',
      });
    }
  };

  const getFeatureData = async () => {
    setIsFetchFeature(true);
    if ([owner, operator].includes(user?.role)) {
      // reload the feature config if the user is owner
      const featureRes = await dispatch(getFeaturesConfig());
      setIsFetchFeature(false);
    } else {
      setIsFetchFeature(false);
    }
  };

  useEffect(() => {
    getFeatureData();
    getInitialData();
    // Check if user owns offline store
    setHasOnlineStore(stores?.find(({ type }) => type === 2) ? true : false);
    // eslint-disable-next-line
  }, [currentPage]);

  const onRowClick = (payByLinkCode: CompanyPayByLinkCode) => () => {
    setPayByLinkCode(payByLinkCode);
    setIsCollapseOpen(true);
    return;
  };

  const onCreateBtnClicked = (isCreateAuthorization = false) => {
    setIsCollapseOpen(false);
    setPayByLinkCode(null);
    setIsCreateAuthorization(isCreateAuthorization);
    setIsCreateOrEditModalOpen(true);
  };

  const onEditBtnClicked = (fixedCode: any) => {
    setPayByLinkCode(fixedCode);
    setIsCreateOrEditModalOpen(true);
  };

  const cannotCreateCodeAlert = () => {
    if (hasOnlineStore) {
      Swal.fire({
        title: translate('access_denied'),
        text: translate('no_gateway_available_for_qr_code'),
        icon: 'error',
      });
    } else {
      Swal.fire({
        title: translate('access_denied'),
        text: translate('online_store_only'),
        icon: 'error',
      });
    }
  };

  if (
    !features?.pre_auth_online?.enable ||
    features?.pre_auth_online?.pre_auth_pay_by_link_disabled
  ) {
    history.push('/pay-links/static');
    return <></>;
  }

  return (
    <>
      <TopHeader title={translate('pay_links')}>
        {!roles.isAccountant && !isSuperowner(user.role) && (
          <>
            <div className="d-flex gap-2">
              {features?.pre_auth_online?.enable &&
                !features?.pre_auth_online?.pre_auth_pay_by_link_disabled && (
                  <button
                    className="header-button btn btn-primary"
                    disabled={isFetchFeature}
                    onClick={
                      hasQrCodeAvailableGateway
                        ? () => onCreateBtnClicked(true)
                        : cannotCreateCodeAlert
                    }>
                    {translate('create_authorization_pay_link')}
                  </button>
                )}
              <button
                className="header-button btn btn-primary"
                onClick={
                  hasQrCodeAvailableGateway
                    ? () => onCreateBtnClicked(false)
                    : cannotCreateCodeAlert
                }>
                {translate('create_pay_link')}
              </button>
            </div>
          </>
        )}
      </TopHeader>
      <SideNavbar location={location as any} type="paylink" />
      <div id="main-content" className="hide-scrollbar">
        {isFetching ? (
          <Spinner size="sm" />
        ) : (
          <StaticDataTable
            setCurrentPage={setCurrentPage}
            onRowClick={onRowClick}
            selectedCode={payByLinkCode}
          />
        )}
      </div>

      <div id="side-content" className="hide-scrollbar">
        {!isAccountant(user.role) && !isSuperowner(user.role) && (
          <ActionBox
            setIsPrintModalOpen={setIsPrintModalOpen}
            reload={getInitialData}
            onEdit={onEditBtnClicked}
            isOpen={isCollapseOpen}
            payByLinkCode={payByLinkCode}
          />
        )}
        <SideFilter isLoading={isFetching} resetFilter={resetFilter} onFilter={onFilterSearch}>
          <Input
            type="text"
            legend={translate('subject').toUpperCase()}
            value={subject}
            name="subject"
            onChange={onFilterChange}
            placeholder={translate('subject').toUpperCase()}
          />
          <Input
            type="text"
            legend={translate('custom_id').toUpperCase()}
            value={custom_id}
            name="custom_id"
            onChange={onFilterChange}
            placeholder={translate('custom_id').toUpperCase()}
          />

          <Input
            type="cleave"
            cleaveOptions={{
              numeral: true,
              numeralDecimalScale: 2,
              numeralIntegerScale: 7,
              numeralPositiveOnly: true,
            }}
            onChange={onFilterChange}
            value={amount}
            name="amount"
            legend={translate('amount').toUpperCase()}
            placeholder={translate('amount').toUpperCase()}
          />
        </SideFilter>
      </div>

      {/* <DisplayCodeModal isOpen={showCodeModal} setIsOpen={setShowCodeModal} fixedCode={payByLinkCodes} /> */}

      <CreateEditCodeModal
        isOpen={isCreateOrEditModalOpen}
        setIsOpen={setIsCreateOrEditModalOpen}
        payByLinkCode={payByLinkCode}
        reloadCodes={getInitialData}
        isCreateAuthorization={isCreateAuthorization}
      />

      <PrintModal
        fpsCode={payByLinkCode}
        isOpen={isPrintModalOpen}
        setIsOpen={setIsPrintModalOpen}
      />
    </>
  );
};
