import { getTopUpBalanceList } from 'actions/TopUpBalanceAction';
import { HistoryDetailModal } from 'components/TopUpBalance/HistoryDetailModal';

import { CustomTable } from 'components/custom/table/CustomTable';
import { TopHeader } from 'components/layouts/page/topHeader';
import { isCanAccessTopUpBalance } from 'constants/Roles';

import { useTranslate } from 'context/TranslateContext';
import { useSwal } from 'helpers/sweetalert';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FunctionDispatch, GlobalTypes } from 'redux/types';
import { TopUpBalanceHistoryProps } from 'services/API/TopUpBalance/interface';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const TopUpBalanceHistory = () => {
  const {
    auth: { user, features },
    data: { topUpBalanceHistory },
  } = useSelector((state: GlobalTypes.RootState) => state);

  const dispatch = useDispatch<FunctionDispatch>();

  const [Swal] = useSwal();
  const { translate } = useTranslate();

  const { topUpBalanceHistory: topUpBalanceHistoryData, topUpBalanceHistoryPagination } =
    topUpBalanceHistory;

  const [currentPage, setCurrentPage] = useState(1);
  const [isFetching, setIsFetching] = useState(false);
  const [isInitPage, setIsInitPage] = useState(false);

  const [selectedBalance, setSelectedBalance] = useState<null | TopUpBalanceHistoryProps>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const getInitialData = async () => {
    const data = filterToParams();
    setIsFetching(true);
    const res = await dispatch(getTopUpBalanceList(user?.company_id, data));
    setIsFetching(false);
    if (!res.success) {
      return Swal.fire({
        icon: 'error',
        title: translate('balance'),
        text: res.message || 'Oops',
      });
    }
  };

  const filterToParams = () => {
    return {
      limit: 10,
      page: currentPage,
      include: 'paid_item_count',
    };
  };

  const tableHeader = () => {
    return (
      <tr>
        <th className="border-end border-top-0">{translate('store_name')}</th>
        <th className="border-end border-top-0">{translate('top_up_amount')}</th>
        <th className="border-end border-top-0">{translate('ref_no')}</th>
        <th className="border-top-0">{translate('status')}</th>
        <th className="border-top-0"></th>
      </tr>
    );
  };

  const getStatus = (code: string) => {
    switch (code) {
      case '0':
        return 'active';
      case '1':
        return 'inactive';
      case '2':
        return 'completed';
      case '3':
        return 'expired';
      default:
        return '';
    }
  };

  const renderData = () => {
    return topUpBalanceHistoryData.map((topUpBalanceHistory, index) => {
      const {
        store: { name },
        amount,
        currency,
        reference_id,
        status,
        paid_item_count,
      } = topUpBalanceHistory;

      const onClick = () => {
        setSelectedBalance(topUpBalanceHistory as any);
        setIsModalOpen(true);
      };

      return (
        <tr className="cursor-pointer" key={index} onClick={onClick}>
          <td className="">{name}</td>
          <td className="amount" data-currency={currency}>
            {amount}&nbsp;
          </td>
          <td className="">{reference_id}</td>
          <td className="">
            {translate(getStatus(status))}&nbsp;
            {paid_item_count && paid_item_count > 0 && <label>{`(${paid_item_count})`}</label>}
          </td>
          <td>
            <FontAwesomeIcon icon={faEye} color="#000000" />
          </td>
        </tr>
      );
    });
  };

  useEffect(() => {
    setIsInitPage(true);
    // temporary block the access to history page
    window.location.href = '/';

    if (features && isCanAccessTopUpBalance(features, user?.role)) {
      setIsInitPage(false);
      getInitialData();
      return;
    }
    window.location.href = '/';
    // eslint-disable-next-line
  }, [currentPage]);

  return (
    <>
      {!isInitPage && <TopHeader title={translate('top_up_balance')} />}
      <div id="main-content" className="hide-scrollbar">
        {!isInitPage && (
          <CustomTable
            renderHeading={() => tableHeader()}
            renderData={renderData}
            totalColumn={5}
            setCurrentPage={setCurrentPage}
            pagination={topUpBalanceHistoryPagination}
            isLoading={isFetching}
            showOldDataWhenReload={false}
          />
        )}
      </div>
      {selectedBalance && (
        <HistoryDetailModal
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          balance={selectedBalance as any}
          refreshData={getInitialData}
        />
      )}
    </>
  );
};
