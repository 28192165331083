import axios, { axiosWebHook, defaultRequestHandler, getToken, params } from '../common/Fetcher';
import {
  debugPayQrCodeProps,
  GetTopUpBalanceHistoryResponse,
  GetTopUpBalanceHistoryDetailsResponse,
  TopUpBalanceFieldProps,
} from './interface';

export const topUpBalance = (store_id: string, body: TopUpBalanceFieldProps) =>
  defaultRequestHandler(
    axios.post(`/stores/${store_id}/payment-links-topup`, params(body), {
      headers: {
        authorization: getToken(),
      },
    }),
  );

export const getTopUpBalanceHistory = (
  company_id: string,
  data = {},
): Promise<GetTopUpBalanceHistoryResponse> =>
  defaultRequestHandler(
    axios.get(`/companies/${company_id}/topup-payment-history?${params(data)}`, {
      headers: {
        authorization: getToken(),
      },
      timeout: 60000,
    }),
  );

export const getTopUpBalanceHistoryDetails = (
  company_id: string,
  id: string,
  data = {},
): Promise<GetTopUpBalanceHistoryDetailsResponse> =>
  defaultRequestHandler(
    axios.get(`/companies/${company_id}/topup-payment-history/${id}?${params(data)}`, {
      headers: {
        authorization: getToken(),
      },
      timeout: 60000,
    }),
  );

export const debugPayQrCode = (body: debugPayQrCodeProps): Promise<any> =>
  defaultRequestHandler(
    axiosWebHook.post(`/fps/dbs`, params(body), {
      headers: {
        authorization: getToken(),
      },
    }),
  );
