import payPlusGatewayImg from 'assets/img/navbar_components/payplus_gateway.png';
import payPlusGatewayActiveImg from 'assets/img/navbar_components/payplus_gateway_active.png';
import payPlusTransactionImg from 'assets/img/navbar_components/payplus_transaction.png';
import payPlusTransactionActiveImg from 'assets/img/navbar_components/payplus_transaction_active.png';
import bankImg from 'assets/img/side_navbar_components/bank.png';
import bankActiveImg from 'assets/img/side_navbar_components/bank_active.png';
import checkoutDomainsImg from 'assets/img/side_navbar_components/checkout_domains.png';
import checkoutDomainsActiveImg from 'assets/img/side_navbar_components/checkout_domains_active.png';
import companyInfoImg from 'assets/img/side_navbar_components/company_info.png';
import companyInfoActiveImg from 'assets/img/side_navbar_components/company_info_active.png';
import deviceImg from 'assets/img/side_navbar_components/device.png';
import deviceActiveImg from 'assets/img/side_navbar_components/device_active.png';
import documentImg from 'assets/img/side_navbar_components/document.png';
import documentActiveImg from 'assets/img/side_navbar_components/document_active.png';
import domainsImg from 'assets/img/side_navbar_components/domains.png';
import domainsActiveImg from 'assets/img/side_navbar_components/domains_active.png';
import keyImg from 'assets/img/side_navbar_components/key.png';
import keyActiveImg from 'assets/img/side_navbar_components/key_active.png';
import profileImg from 'assets/img/side_navbar_components/profile.png';
import profileActiveImg from 'assets/img/side_navbar_components/profile_active.png';
import storesImg from 'assets/img/side_navbar_components/stores.png';
import storesActiveImg from 'assets/img/side_navbar_components/stores_active.png';
import tokenImg from 'assets/img/side_navbar_components/token.png';
import tokenActiveImg from 'assets/img/side_navbar_components/token_active.png';
import usersImg from 'assets/img/side_navbar_components/users.png';
import usersActiveImg from 'assets/img/side_navbar_components/users_active.png';

export const accountSummary = '/account-summary';
export const transaction = '/transaction';
export const balance = '/balance';
export const settlement = '/settlement';
export const paymentGateway = '/payment-gateway';
export const PreAuthorize = '/pre-authorize';
export const codes = '/my-codes';
export const admin = '/admin/company-info';
export const payByLinkCodes = '/pay-by-link-codes';
export const analysis = '/analysis';

export const profile = '/profile';

export const payPlusTransaction = '/pay-plus/transaction';
export const payPlusPaymentGateway = '/pay-plus/payment-gateway';

export const staticPayLink = '/pay-links/static';
export const dynamicPayLink = '/pay-links/dynamic';

export const tokenization = '/tokenization';

export const company_info = '/admin/company-info';
export const documents = '/admin/documents';
export const stores = '/admin/stores';
export const checkout_domains = '/admin/checkout-domains';
export const aml_domains = '/admin/aml-domains';
export const users_and_roles = '/admin/users-and-roles';
export const bank_accounts = '/admin/bank-accounts';
export const device = '/admin/device';
export const ttp_device = '/admin/ttp-device';
export const app_keys = '/admin/app-keys';

export const profile_info = '/user/profile-info';
export const my_personal_token = '/user/my-personal-token';

export const routes = [
  accountSummary,
  transaction,
  balance,
  PreAuthorize,
  settlement,
  paymentGateway,
  codes,
  staticPayLink,
  analysis,
  payPlusTransaction,
  tokenization,
  admin,
  // profile
];

export const payPlusSidebarRoutes = [
  {
    name: 'pay_plus_transactions',
    path: payPlusTransaction,
    img: payPlusTransactionImg,
    activeImg: payPlusTransactionActiveImg,
  },
  {
    name: 'pay_plus_payment_gateway',
    path: payPlusPaymentGateway,
    img: payPlusGatewayImg,
    activeImg: payPlusGatewayActiveImg,
  },
];

export const payLinkSidebarRoutes = [
  {
    name: 'dynamic_pay_links',
    path: staticPayLink,
    img: payPlusTransactionImg,
    activeImg: payPlusTransactionActiveImg,
  }, // ALICE-509 changed "static" <=> "dynamic"
  {
    name: 'static_pay_links',
    path: dynamicPayLink,
    img: payPlusGatewayImg,
    activeImg: payPlusGatewayActiveImg,
  },
];

export const companyInfoSidebarRoutes = [
  {
    name: 'company_info',
    path: company_info,
    img: companyInfoImg,
    activeImg: companyInfoActiveImg,
  },
  { name: 'documents', path: documents, img: documentImg, activeImg: documentActiveImg },
  { name: 'aml_domains', path: aml_domains, img: domainsImg, activeImg: domainsActiveImg },
  {
    name: 'checkout_domains',
    path: checkout_domains,
    img: checkoutDomainsImg,
    activeImg: checkoutDomainsActiveImg,
  },
  { name: 'stores', path: stores, img: storesImg, activeImg: storesActiveImg },
  {
    name: 'users_and_roles',
    path: users_and_roles,
    img: usersImg,
    activeImg: usersActiveImg,
  },
  {
    name: 'bank_accounts',
    path: bank_accounts,
    img: bankImg,
    activeImg: bankActiveImg,
  },
  { name: 'device', path: device, img: deviceImg, activeImg: deviceActiveImg },
  { name: 'ttp_device', path: ttp_device, img: deviceImg, activeImg: deviceActiveImg },
  { name: 'app_keys', path: app_keys, img: keyImg, activeImg: keyActiveImg },
];

export const userRoutes = [
  { name: 'profile_info', path: profile_info, img: profileImg, activeImg: profileActiveImg },
  { name: 'my_personal_token', path: my_personal_token, img: tokenImg, activeImg: tokenActiveImg },
];
