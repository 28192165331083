import { useTranslate } from 'context/TranslateContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQrcode, faPhone, faEnvelope, faTrash } from '@fortawesome/free-solid-svg-icons';

import { MfaProps } from 'services/API/MFA/interface';
import * as API from 'services/API/MFA';

import { EmptyResult } from 'components/custom/table/emptyResult';
import { Input } from 'components/custom/input';

import { MfaType, MFA_OPTIONS } from 'constants/mfa';
import { translateLabel } from 'constants/format';

import { CreateMfaModal } from './CreateMfaModal';

import { PaginationProps } from 'services/API/common/Fetcher';
import { Pagination } from 'components/custom/pagination';

import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Collapse,
  Spinner,
} from 'reactstrap';

import { useState } from 'react';
import { useSwal } from 'helpers/sweetalert';
import { useSelector } from 'react-redux';
import { GlobalTypes } from 'redux/types';

import { maskPhoneNumber, maskEmail } from 'helpers/functions/masking';
import { UserSelect } from 'components/custom/ReactSelectAsync/Select/UserSelect';
import { ANY_OBJECT } from 'constants/format';
import { validateAddOtherMfa } from './validator';

interface MfaCardProps {
  mfaList: MfaProps[];
  getMfaList: () => Promise<void>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isSelfMfa: boolean;
  pagination?: PaginationProps | null;
  setCurrentPage?: React.Dispatch<React.SetStateAction<number>>;
}

export const MfaCard = ({
  mfaList,
  getMfaList,
  isLoading,
  setIsLoading,
  isSelfMfa,
  pagination,
  setCurrentPage,
}: MfaCardProps) => {
  const {
    auth: { user, features },
  } = useSelector((state: GlobalTypes.RootState) => state);

  const { translate, language } = useTranslate();
  const [Swal] = useSwal();

  const [createMfaType, setCreateMfaType] = useState<MfaType | null>(null);

  const [targetUser, setTargetUser] = useState<any>(null);
  const [errors, setErrors] = useState<ANY_OBJECT>({});

  const [openAddMfaDropdown, setOpenAddMfaDropdown] = useState(false);

  const [isCreateMfaModalOpen, setIsCreateMfaModalOpen] = useState(false);
  const [isCreateMfaCollapseOpen, setIsCreateMfaCollapseOpen] = useState(false);

  const [isAddingMfa, setIsAddingMfa] = useState(false);

  const getMfaIcon = (mfaType: string) => {
    let icon = null;
    switch (mfaType) {
      case MfaType.TOTP:
        icon = faQrcode;
        break;
      case MfaType.SMS:
        icon = faPhone;
        break;
      case MfaType.EMAIL:
        icon = faEnvelope;
        break;
      default:
        break;
    }

    return icon != null ? <FontAwesomeIcon fixedWidth icon={icon} /> : null;
  };

  const getMfaTypeDisplayName = (mfaType: string) => {
    const mfaOption = MFA_OPTIONS.find((mfa) => mfa.value == mfaType);

    return mfaOption != null ? translate(mfaOption.label) : '-';
  };

  const getDisplayName = (mfa: MfaProps) => {
    switch (mfa.challengeName) {
      case MfaType.TOTP:
        return mfa.display;
      case MfaType.SMS:
        return mfa.display + ' (' + maskPhoneNumber(mfa.entity) + ')';
      case MfaType.EMAIL:
        return mfa.display + ' (' + maskEmail(mfa.entity) + ')';
      default:
        return '-';
    }
  };

  const getDefaultCell = (mfa: MfaProps) => {
    if (mfa.isDefault) {
      return <span>{translate('default')}</span>;
    }

    if (!canShowAction(mfa)) {
      return <span>{translate('not_default')}</span>;
    }

    const userId = (isSelfMfa ? user?.id : mfa?.userId) ?? '';
    return (
      <a href="#" className="link-primary" onClick={() => setAsDefault(mfa.id, userId)}>
        {translate('set_as_default')}
      </a>
    );
  };

  const spacingBetweenWords = () => {
    return language == 'en' ? ' ' : '';
  };

  const showErrorDialog = async (message: string | undefined) => {
    await Swal.fire({
      icon: 'error',
      title: translate('two_step_verification'),
      text: message ?? 'Oops',
    });
  };

  const showSuccessDialog = async (message: string) => {
    await Swal.fire({
      icon: 'success',
      title: translate('two_step_verification'),
      text: message ?? 'Success',
    });
  };

  const getMfaMax = (mfaType: MfaType | null) => {
    if (mfaType == null) {
      return 0;
    }

    const mfa = MFA_OPTIONS.find((mfa) => mfa.value == mfaType);

    return mfa?.max ?? 0;
  };

  const canShowAction = (mfa: MfaProps) => {
    return (
      isSelfMfa || (user?.role == 'owner' && features?.auth?.mfa_enabled && user?.id != mfa?.userId)
    );
  };

  const canCreateMfa = (mfaType: MfaType | null, userId = '') => {
    if (mfaType == null) {
      return false;
    }

    const maxNumOfMfa = getMfaMax(mfaType);

    return (
      mfaList.filter((mfa) => {
        if (userId !== '') {
          return mfa.challengeName === mfaType && mfa.userId === userId;
        }
        return mfa.challengeName === mfaType;
      }).length < maxNumOfMfa
    );
  };

  const showCanCreateMfaErrorDialog = (mfaType: MfaType | null) => {
    const displayName = getMfaTypeDisplayName((mfaType ?? '') as string);

    const errMsg =
      translate('exceeds_limit') +
      ' (' +
      translate(displayName) +
      spacingBetweenWords() +
      translate('max_number_limit') +
      ': ' +
      getMfaMax(mfaType) +
      ')';

    showErrorDialog(errMsg);
  };

  const onAddSelfMfaButtonClick = (mfaType: MfaType | null) => {
    if (!canCreateMfa(mfaType)) {
      showCanCreateMfaErrorDialog(mfaType);
      return;
    }

    setCreateMfaType(mfaType);
    setIsCreateMfaModalOpen(true);
  };

  const onAddOtherMfaButtonClick = () => {
    // validate form input
    const validateData = {
      createMfaType: createMfaType,
      userId: targetUser?.id ?? '',
    };

    const { isValid, errors } = validateAddOtherMfa(validateData);
    setErrors(errors);

    if (!isValid) {
      return;
    }

    // validate can create mfa for target user
    if (!canCreateMfa(createMfaType, targetUser?.id)) {
      showCanCreateMfaErrorDialog(createMfaType);
      return;
    }

    setIsCreateMfaModalOpen(true);
  };

  const setAsDefault = async (mfaId: string, userId: string) => {
    setIsLoading(true);

    if (userId) {
      const res = await API.setMfaAsDefault(userId, mfaId);

      if (!isSelfMfa && res?.error_code === 'permission_denied') {
        await showErrorDialog(translate('please_create_two_step_verification_for_yourself_first'));
        setIsLoading(false);
        return;
      }

      if (!res.success) {
        await showErrorDialog(res?.message);
        setIsLoading(false);
        return;
      }
    } else {
      await showErrorDialog('User Not Found');
      setIsLoading(false);
      return;
    }

    showSuccessDialog(translate('set_as_default'));
    getMfaList();
  };

  const deleteMfa = async (mfa: MfaProps) => {
    setIsLoading(true);

    const userId = (isSelfMfa ? user?.id : mfa?.userId) ?? '';

    if (userId) {
      const res = await API.deleteMfa(userId, mfa.id);
      if (!isSelfMfa && res?.error_code === 'permission_denied') {
        await showErrorDialog(translate('please_create_two_step_verification_for_yourself_first'));
        setIsLoading(false);
        return;
      }

      if (!res.success) {
        await showErrorDialog(res?.message);
        setIsLoading(false);
        return;
      }
    } else {
      await showErrorDialog('User Not Found');
      setIsLoading(false);
      return;
    }

    showSuccessDialog(translate('delete_mfa'));
    getMfaList();
  };

  return (
    <div className="px-3">
      <div className="row g-0 align-items-top justify-content-between">
        <div className="col-10">
          <h4 className="text-secondary">{translate('channels')}</h4>
          {isSelfMfa && <p className="fs-6">{translate('mfa_reminder')}</p>}
        </div>
        <div className="col-auto">
          <div className="mr-2">
            {isSelfMfa ? (
              <Dropdown
                disabled={isLoading || isAddingMfa}
                isOpen={openAddMfaDropdown}
                toggle={() => setOpenAddMfaDropdown(!openAddMfaDropdown)}
                className="d-inline-block">
                <DropdownToggle
                  disabled={isLoading || isAddingMfa}
                  className="header-button btn btn-primary"
                  caret
                  color="#ffffff">
                  {isAddingMfa ? <Spinner size="sm" /> : translate('add')}
                </DropdownToggle>
                {MFA_OPTIONS.length >= 1 && (
                  <DropdownMenu classnames="lang-dropdown">
                    {MFA_OPTIONS?.map((item) => (
                      <DropdownItem
                        key={item.value}
                        classnames="btn btn-sm btn-muted text-center"
                        onClick={() => onAddSelfMfaButtonClick(item.value)}>
                        {translate(item?.label ?? '')}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                )}
              </Dropdown>
            ) : (
              user?.role == 'owner' &&
              features?.auth?.mfa_enabled &&
              !isCreateMfaCollapseOpen && (
                <button
                  className="btn btn-primary"
                  onClick={() => setIsCreateMfaCollapseOpen(!isCreateMfaCollapseOpen)}
                  disabled={isLoading}>
                  {translate('add')}
                </button>
              )
            )}
          </div>
        </div>
        <Collapse isOpen={isCreateMfaCollapseOpen}>
          <>
            <h6 className="colfax-regular text-secondary m-0">
              {translate('create_two_step_verification_for_users')}
            </h6>
            <div className="row align-items-top g-0">
              <div className="col-lg-6">
                <UserSelect
                  isRequired
                  showStatus
                  legend={translate('username').toUpperCase()}
                  setUser={setTargetUser}
                  user={targetUser}
                  error={errors.userId}
                />
              </div>
              <div className="col-lg-6">
                <Input
                  isRequired
                  isSearchable={false}
                  legend={translate('type').toUpperCase()}
                  type="react-select"
                  options={translateLabel(
                    MFA_OPTIONS.filter((mfa) => mfa.canRegisterByOther),
                    translate,
                    true,
                  )}
                  placeholder={translate('select')}
                  value={createMfaType ?? ''}
                  onChange={setCreateMfaType}
                  name="status"
                  error={translate(...(errors.createMfaType ?? ''))}
                />
              </div>
            </div>
            <div className="row justify-content-end g-0 mt-2">
              <button
                className="btn btn-primary"
                type="button"
                onClick={onAddOtherMfaButtonClick}
                disabled={isLoading}>
                {isAddingMfa ? <Spinner size="sm" /> : <span>{translate('add')}</span>}
              </button>
            </div>
          </>
        </Collapse>
      </div>
      <div className="mt-3">
        <table className="table table-hover">
          <thead>
            <tr>
              {!isSelfMfa && (
                <th scope="col" className="border-top-0">
                  {translate('username')}
                </th>
              )}
              <th scope="col" className="border-top-0">
                {translate('type')}
              </th>
              <th scope="col" className="border-top-0">
                {translate('name')}
              </th>
              <th scope="col" className="border-top-0">
                {translate('actions')}
              </th>
            </tr>
          </thead>
          <tbody>
            {!isLoading &&
              (mfaList.length > 0 ? (
                mfaList.map((mfa) => {
                  return (
                    <tr key={mfa.id}>
                      {!isSelfMfa && (
                        <td className="align-middle text-break" style={{ maxWidth: '300px' }}>
                          {mfa.username}
                        </td>
                      )}
                      <td
                        className="align-middle"
                        style={{ minWidth: language == 'en' ? '150px' : '100px' }}>
                        <span className="d-inline-block pe-2">{getMfaIcon(mfa.challengeName)}</span>
                        <span className="d-inline-block">
                          {getMfaTypeDisplayName(mfa.challengeName)}
                        </span>
                      </td>
                      <td className="align-middle text-break" style={{ maxWidth: '300px' }}>
                        {getDisplayName(mfa)}
                      </td>
                      {
                        <td className="align-middle">
                          <div className="row align-items-center">
                            <span className="col-7" style={{ minWidth: '60px' }}>{getDefaultCell(mfa)}</span>
                            <span className="col-5">
                              {canShowAction(mfa) && (
                                <button
                                  className="btn btn-danger btn-sm"
                                  onClick={() => deleteMfa(mfa)}
                                  disabled={isLoading}>
                                  <FontAwesomeIcon icon={faTrash} color="#ffffff" />
                                </button>
                              )}
                            </span>
                          </div>
                        </td>
                      }
                    </tr>
                  );
                })
              ) : (
                <tr className="">
                  <td colSpan={5} rowSpan={5} className="text-center">
                    <EmptyResult text="no_data" />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {isLoading && (
          <div className="d-flex justify-content-center">
            <div className="spinner-border">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
        {pagination && !isLoading && (
          <div className="table-responsive admin mt-0">
            <Pagination pagination={pagination} setCurrentPage={setCurrentPage} />
            <div className="mb-5 mb-md-0"></div>
          </div>
        )}
        <div className="mb-5 mb-md-0"></div>
        <CreateMfaModal
          isModalOpen={isCreateMfaModalOpen}
          setIsModalOpen={setIsCreateMfaModalOpen}
          setIsAddingMfa={setIsAddingMfa}
          userId={isSelfMfa ? user?.id : targetUser?.id}
          mfaType={createMfaType}
          reload={getMfaList}
          isSelfCreateMfa={isSelfMfa}
        />
      </div>
    </div>
  );
};
