import { login as loginAction } from 'actions/AuthActions';
import { FormContainer, FormHeader } from 'components/custom/form';
import { Input } from 'components/custom/input';
import { AuthenticationLayout } from 'components/layouts/authentication.layout';
import { useTranslate } from 'context/TranslateContext';
import { removeErrorOnChange } from 'helpers/functions/removeErrorOnChange';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { useSwal } from 'helpers/sweetalert';
import { login, redirectProps } from 'types';
import version from 'version.json';
import { validate } from './validator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { MfaType, MFA_OPTIONS } from 'constants/mfa';
import { OtpInput } from 'components/custom/OtpInput';

const initialState = {
  username: '',
  password: '',

  challengeId: '',
  challengeName: '',
  entity: '',
  answer: '',
};

const config = (window as any).yedpayConfig;

const Login = ({ loginAction }: any) => {
  const [Swal] = useSwal();
  const { translate, language } = useTranslate();
  const history = useHistory();

  const [form, setForm] = useState<login>(initialState);
  const [redirect, setRedirect] = useState<redirectProps>({
    to: '',
    shouldRedirect: false,
  });
  const [errors, setError] = useState<any>({});
  const [serviceProviderCode, setSetServiceProviderCode] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isResendingCode, setIsResendingCode] = useState<boolean>(false);

  const [step, setStep] = useState(1);

  const { username, password, challengeId, challengeName, entity, answer } = form;
  const { to, shouldRedirect } = redirect;

  useEffect(() => {
    const windowLocationParams = new URLSearchParams(decodeURIComponent(window.location.search));
    const urlParams = new URLSearchParams(decodeURIComponent(localStorage.urlParams || ''));

    windowLocationParams.forEach(function (value, key) {
      urlParams.set(key, value);
    });
    setSetServiceProviderCode(urlParams.get('service_provider_id') ?? null);
    localStorage.urlParams = urlParams.toString();
  }, []);

  const goToNextStep = () => {
    setStep(2);
  };

  const reset = () => {
    setStep(1);

    setIsLoading(false);

    setForm(initialState);
    setError({});
  };

  const setMfaDataFromResponse = async (res: any) => {
    if (!res?.challengeId || !res?.challengeName) {
      await showErrorDialog('Invalid 2-Step verification data');
      return false;
    }

    setForm({
      ...form,
      challengeId: res.challengeId,
      challengeName: res.challengeName,
      entity: res.entity,
    });

    return true;
  };

  const spacingBetweenWords = () => {
    return language == 'en' ? ' ' : '';
  };

  const getVerifyMfaReminder = () => {
    const msgPrefix = translate('please_enter_the_verification_code') + spacingBetweenWords();

    const mfaType = form.challengeName as MfaType;

    switch (mfaType) {
      case MfaType.SMS:
        return msgPrefix + translate('sent_to') + spacingBetweenWords() + entity;
      case MfaType.EMAIL:
        return msgPrefix + translate('sent_to') + spacingBetweenWords() + entity;
      case MfaType.TOTP:
        return msgPrefix + translate('shown_in_your_authenticator_application');
      default:
        return '';
    }
  };

  const showErrorDialog = async (message: string | undefined) => {
    await Swal.fire({
      icon: 'error',
      title: translate('login_failed'),
      text: message ?? 'Oops',
    });
  };

  const canResendCode = () => {
    const mfa = MFA_OPTIONS.find((mfa) => mfa.value == challengeName);

    if (mfa != null) {
      return mfa.canResendCode;
    }

    return false;
  };

  const resendCode = async () => {
    // if Mfa type is TOTP , not support resend code
    if (challengeName == MfaType.TOTP) {
      return;
    }

    const loginFormData = getLoginFormData();

    setIsResendingCode(true);

    // send setup mfa API
    try {
      const res = await loginAction(loginFormData);

      if (res?.error_code !== 'mfa_challenge_required') {
        await showErrorDialog(res?.data?.message);
        setIsResendingCode(false);
        return;
      }

      // Go to verify mfa step
      setMfaDataFromResponse(res);
    } finally {
      setIsResendingCode(false);
    }
  };

  const handleFormChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const error = removeErrorOnChange(errors, evt.target.name);
    setForm({
      ...form,
      [evt.target.name]: evt.target.value,
    });
    setError(error);
  };

  const handleVerifyCodeChange = (verifyCode: string) => {
    if (/^\d*$/.test(verifyCode)) {
      setForm({
        ...form,
        answer: verifyCode,
      });
    }
  };

  const handleRedirect = (path: string) => {
    setRedirect({
      to: path,
      shouldRedirect: true,
    });
  };

  const getLoginFormData = (withMfa = false) => {
    const basicFormData = {
      client_id: config.client_id,
      client_secret: config.client_secret,
      username,
      password,
      grant_type: 'password',
    };

    if (withMfa) {
      return {
        ...basicFormData,
        challengeId: challengeId,
        answer: answer,
      };
    }

    return basicFormData;
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (isLoading) {
      return;
    }

    const withMfa = step == 2;

    setIsLoading(true);

    const { isValid, errors } = validate(form, withMfa);

    setError(errors);

    if (!isValid) {
      setIsLoading(false);
      return;
    }

    try {
      localStorage.username = username;

      const loginFormData = getLoginFormData(withMfa);
      const res = (await loginAction(loginFormData)) as any;

      if (res?.error_code === 'rate_limited') {
        await showErrorDialog(res.message);
        reset();
        return;
      }

      if (res?.error_code === 'mfa_challenge_required') {
        const isSuccess = await setMfaDataFromResponse(res);
        if (isSuccess) {
          goToNextStep();
        }
        return;
      }

      if (res?.error_code === 'password_expired') {
        await showErrorDialog(res.message);
        const url = res.action.url;
        return history.replace(url);
      }

      if (res?.error_code !== undefined || res?.success === false) {
        showErrorDialog(res.message);
        return;
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  if (shouldRedirect) {
    return <Redirect to={to} />;
  }

  return (
    <AuthenticationLayout>
      <FormContainer>
        <FormHeader title={translate('login')} noImage />
        <form onSubmit={onSubmit}>
          {step == 1 && (
            <>
              <Input
                legend={translate('username')}
                value={username}
                name="username"
                onChange={handleFormChange}
                placeholder={translate('enter_username')}
                type="text"
                autoComplete="username"
                error={translate(...(errors.username ?? ''))}
                disabled={isLoading}
              />
              <Input
                legend={translate('password')}
                value={password}
                name="password"
                onChange={handleFormChange}
                placeholder={translate('enter_password')}
                type="password"
                autoComplete="current-password"
                error={translate(...(errors.password ?? ''))}
                disabled={isLoading}
              />
              {!serviceProviderCode && (
                <div className="text-end mt-2">
                  {isLoading ? (
                    <span className="disabled-link">{translate('forgot_password')}?</span>
                  ) : (
                    <Link to="/forget-password">
                      <span className="colfax-regular">{translate('forgot_password')}?</span>
                    </Link>
                  )}
                </div>
              )}
            </>
          )}
          {step == 2 && (
            <>
              <p className="fs-6">{getVerifyMfaReminder()}</p>
              <OtpInput
                legend={translate('verify_code')}
                name="verifyCode"
                value={answer ?? ''}
                handleChange={handleVerifyCodeChange}
                placeholder={translate('enter_verify_code')}
                isLoading={isLoading}
                errMsg={translate(...(errors.answer ?? ''))}
                showResendButton={canResendCode()}
                onResendClick={resendCode}
                startCountDownWhenInit={true}
              />
            </>
          )}
          <div className="row mt-4">
            {!serviceProviderCode && step == 1 && (
              <div className="col d-grid">
                <button
                  type="button"
                  className="btn btn-outline-secondary mb-lg-0 mb-2"
                  onClick={(e) => handleRedirect('/register')}
                  disabled={isLoading || isResendingCode}>
                  {translate('register')}
                </button>
              </div>
            )}
            <div className="col d-grid">
              <button
                className="btn btn-primary mb-lg-0 mb-2"
                disabled={isLoading || isResendingCode}>
                {translate('login')} {isLoading && <FontAwesomeIcon spin={true} icon={faSpinner} />}
              </button>
            </div>
          </div>
        </form>
      </FormContainer>
      <div style={{ position: 'fixed', bottom: '.5rem', right: '.5rem' }}>
        <span>v.{version.version}</span>&nbsp;©&nbsp;The Payment Cards Group Limited. All rights
        reserved.
      </div>
    </AuthenticationLayout>
  );
};

export default connect(null, { loginAction })(Login);
