import { params } from 'helpers/functions/param';
import axios, {
  AnyResponse,
  ContentType,
  defaultRequestHandler,
  getToken,
} from '../common/Fetcher';
import {
  GetMerchantTokensResponse,
  GetMerchantTokenDetailsResponse,
  CreateMerchantTokenProps,
  CreateMerchantTokenResponse,
  EditMerchantTokenProps,
  CreateMerchantTokenTransactionProps,
} from './interface';

export const getMerchantTokens = (
  company_id: string,
  data = {},
): Promise<GetMerchantTokensResponse> =>
  defaultRequestHandler(
    axios.get(`/companies/${company_id}/payment-tokens?${params(data)}`, {
      headers: {
        authorization: getToken(),
      },
    }),
  );

export const getMerchantTokenDetails = (
  company_id: string,
  token_id: string,
  data = {},
): Promise<GetMerchantTokenDetailsResponse> =>
  defaultRequestHandler(
    axios.get(`/companies/${company_id}/payment-token/${token_id}?${params(data)}`, {
      headers: {
        authorization: getToken(),
      },
    }),
  );

export const precreateMerchantToken = (
  company_id: string,
  body: CreateMerchantTokenProps,
): Promise<CreateMerchantTokenResponse> =>
  defaultRequestHandler(
    axios.post(`companies/${company_id}/payment-token/precreate-token`, body, {
      headers: {
        authorization: getToken(),
        'Content-Type': ContentType.json,
      },
    }),
  );

export const editMerchantToken = (
  token_id: string,
  body: EditMerchantTokenProps,
): Promise<CreateMerchantTokenResponse> =>
  defaultRequestHandler(
    axios.put(`payment-token/${token_id}`, body, {
      headers: {
        authorization: getToken(),
        'Content-Type': ContentType.json,
      },
    }),
  );

export const createMerchantTokenTransaction = (
  token_id: string,
  body: CreateMerchantTokenTransactionProps,
): Promise<any> =>
  defaultRequestHandler(
    axios.post(`payment-token/${token_id}/payment`, body, {
      headers: {
        authorization: getToken(),
        'Content-Type': ContentType.json,
      },
    }),
  );

export const deleteMerchantToken = (token_id: string): Promise<AnyResponse> =>
  defaultRequestHandler(
    axios.delete(`payment-token/${token_id}`, {
      headers: {
        authorization: getToken(),
        'Content-Type': ContentType.json,
      },
    }),
  );
