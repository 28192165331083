import { BankCodeSelector } from 'components/custom/BankCodeSelector/BankCodeSelector';
import { Input } from 'components/custom/input';
import GATEWAY, {
  CYBERSOURCE_GATEWAY,
  DBS_FPS_OFFLINE_GATEWAY_CODE,
  DBS_FPS_ONLINE_GATEWAY_CODE,
  HSBC_FPS_OFFLINE_GATEWAY_CODE,
  HSBC_FPS_ONLINE_GATEWAY_CODE,
} from 'constants/TransactionGateways';
import { useTranslate } from 'context/TranslateContext';
import { useSwal } from 'helpers/sweetalert';
import { ChangeEvent, useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap';
import { CredentialProps } from 'redux/state/AuthState';
import { UpdateFpsDetailsProps } from 'services/API/FpsTransaction/interface';
import { updateCredential } from 'services/API/Gateway';
import { UpdateCredentialBody } from 'services/API/Gateway/interface';
import { validate } from './validator';

export interface UpdateGatewayModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  credential: CredentialProps;
  getCredentials: () => Promise<void>;
}

const initialState = {
  clearing_code: '004',
  fps_id: '',
  email: '',
  phone: '',
} as UpdateFpsDetailsProps;

const findImageById = (gateway_code: string) => {
  return GATEWAY.find((gateway) => gateway.gateway_code === gateway_code)?.img ?? '';
};

export const UpdateGatewayModal = ({
  isOpen,
  setIsOpen,
  credential,
  getCredentials,
}: UpdateGatewayModalProps) => {
  const isHsbcOnline = credential.gateway_code == HSBC_FPS_ONLINE_GATEWAY_CODE;
  const isHsbcOffline = credential.gateway_code == HSBC_FPS_OFFLINE_GATEWAY_CODE;

  const { translate } = useTranslate();
  const [Swal] = useSwal();

  const isCybersource = CYBERSOURCE_GATEWAY.includes(credential.gateway_code);
  const fpsWithProxy = [
    HSBC_FPS_OFFLINE_GATEWAY_CODE,
    DBS_FPS_OFFLINE_GATEWAY_CODE,
    DBS_FPS_ONLINE_GATEWAY_CODE,
  ].includes(credential.gateway_code);

  const isDBSFps = [DBS_FPS_OFFLINE_GATEWAY_CODE, DBS_FPS_ONLINE_GATEWAY_CODE].includes(
    credential.gateway_code,
  );

  const [fpsDetails, setFpsDetails] = useState(initialState);
  const [merchantId, setMerchantId] = useState(credential.merchant_id);
  const [isSignatureRequired, setIsSignatureRequired] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<any>({});

  const { clearing_code, fps_id, email, phone } = fpsDetails;
  const [fpsProxy, setFpsProxy] = useState<any | ''>('');
  let fpsProxyArr = [
    {
      label: translate('fps_id').toUpperCase(),
      value: 'fps_id',
    },
  ];

  if (isHsbcOffline) {
    fpsProxyArr = [
      ...fpsProxyArr,
      {
        label: translate('email').toUpperCase(),
        value: 'email',
      },
      {
        label: translate('phone').toUpperCase(),
        value: 'phone',
      },
    ];
  }

  const toggle = () => {
    setIsOpen(!isOpen);
    // setFpsDetails(initialState);
    setErrors({});
  };

  const onSelectChange = (name: string) => (val: any) => {
    setFpsDetails({
      ...fpsDetails,
      [name]: val.clearing_code,
    });
  };

  const onFpsDetailsChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFpsDetails({
      ...fpsDetails,
      [e.target.name]: e.target.value,
    });
  };

  const onFpsProxyChange = (e: string) => {
    setFpsProxy(e);

    const tempFpsProxyArr = [...fpsProxyArr].filter((item) => item?.value !== e);

    const tempProxy: any = {};
    tempFpsProxyArr.forEach((item) => {
      tempProxy[item.value] = '';
      setFpsDetails({
        ...fpsDetails,
        ...tempProxy,
      });
    });
  };

  const onMerchantIdChange = (e: ChangeEvent<HTMLInputElement>) => {
    setMerchantId(e.target.value);
  };

  const onCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsSignatureRequired(e.target.checked);
  };

  const onSubmit = async () => {
    if (!credential) {
      return;
    }

    let data: UpdateCredentialBody = {};

    if (isCybersource) {
      data = { signature_required: isSignatureRequired };
    } else if (isHsbcOnline) {
      data = { merchant_id: merchantId };
    } else if (isDBSFps) {
      data = {
        merchant_id: merchantId,
        ...fpsDetails,
      };
    } else {
      data = fpsDetails;
    }

    const { errors, isValid } = validate(
      data,
      isCybersource,
      fpsWithProxy,
      credential.gateway_code,
      fpsProxy,
    );
    setErrors(errors);
    if (!isValid) {
      return;
    }
    setErrors({});

    const res = await updateCredential(credential.id, data);
    await Swal.fire({
      icon: res.success ? 'success' : 'error',
      title: translate('edit_credential'),
      text: res.success ? translate('edit_credential_successful') : res?.message || 'Oops',
    });

    await getCredentials();
    setIsLoading(false);
    toggle();
  };
  useEffect(() => {
    if (credential && isCybersource) {
      setIsSignatureRequired(credential.signature_required!);
    }
    const { clearing_code, fps_id, email, phone } = credential;
    const state = { clearing_code, fps_id, email, phone };

    setFpsDetails(state ?? initialState);
    (isHsbcOnline || isDBSFps) && setMerchantId(credential?.merchant_id);
    fpsWithProxy &&
      setFpsProxy(
        state.fps_id !== ''
          ? 'fps_id'
          : state.email !== ''
          ? 'email'
          : state.phone !== ''
          ? 'phone'
          : '',
      );
  }, [credential, isOpen]);

  return (
    <Modal toggle={toggle} isOpen={isOpen}>
      <ModalHeader toggle={toggle}>{translate('edit_gateway')}</ModalHeader>
      <ModalBody>
        <div className="text-center">
          <img
            src={findImageById(`${credential.gateway_code}`)}
            alt={credential.name}
            className="img-fluid py-3 w-50"
          />
          {(isHsbcOnline || isHsbcOffline) && <p>{translate('fps_notice')}</p>}
          {isDBSFps && <p>{translate('fps_dbs_notice')}</p>}
        </div>

        {isCybersource && (
          <div className="py-2">
            <input
              type="checkbox"
              checked={isSignatureRequired}
              onChange={onCheckboxChange}
              id="signature-required-checkbox"
            />
            &nbsp;
            <label className="form-label" htmlFor="signature-required-checkbox">
              {translate('require_signature_optional')}
            </label>
          </div>
        )}

        {(isHsbcOnline || isDBSFps) && (
          <div className="mb-6">
            <Input
              isRequired
              legend={translate(isDBSFps ? 'fps_org_id' : 'fps_merchant_id').toUpperCase()}
              name="fps_merchant_id"
              value={merchantId!}
              onChange={onMerchantIdChange}
              type="text"
              error={translate(...(errors.merchant_id ?? ''))}
            />
          </div>
        )}

        {fpsWithProxy && (
          <div>
            <BankCodeSelector
              value={clearing_code!}
              onChange={onSelectChange('clearing_code')}
              error={translate(...(errors.clearing_code ?? ''))}
              disabled={isDBSFps}
            />

            <Input
              value={fpsProxy}
              onChange={onFpsProxyChange}
              name="fps_proxy_type"
              options={fpsProxyArr}
              // defaultLabel={translate('select')}
              type="react-select"
              legend={translate('fps_account_info').toUpperCase()}
            />

            {fpsProxy !== '' && (
              <Input
                legend={translate(fpsProxy).toUpperCase()}
                name={fpsProxy}
                value={
                  fpsProxy == 'fps_id'
                    ? fps_id!
                    : fpsProxy == 'email'
                    ? email!
                    : fpsProxy == 'phone'
                    ? phone!
                    : ''
                }
                onChange={onFpsDetailsChange}
                type="text"
                error={translate(...(errors[`${fpsProxy}`] ?? ''))}
              />
            )}
          </div>
        )}

        <div className="d-grid">
          <button className="btn btn-primary mt-3" onClick={onSubmit} disabled={isLoading}>
            {isLoading ? <Spinner size="sm" /> : translate('submit')}
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};
