import { MAX_UPLOAD_NUMBER, MAX_UPLOAD_SIZE } from 'constants/format';
import _ from 'lodash';
import moment from 'moment';
import validator from 'validator';

export const accountHolder: validateParam = (data, fields, options = {}) =>
  check(fields, (field) => {
    if (_.get(data, field) !== '' && !/^(?!\s*$)[A-Z0-9_ .]+$/.test(_.get(data, field))) {
      return options.err_msg || ['error_invalid_account_holder_format'];
    }
  });

export const address: validateParam = (data, fields, options = {}) =>
  check(fields, (field) => {
    if (_.get(data, field) !== '' && !/^[- ,/.&()'"0-9a-zA-Z]+$/.test(_.get(data, field))) {
      return options.err_msg || ['error_invalid_address_format'];
    }
  });

export const required: validateParam = (data, fields, options = {}) =>
  check(fields, (field) => {
    if (!_.get(data, field) || validator.isEmpty(_.get(data, field) + '', options)) {
      return options.err_msg || ['error_required'];
    }
  });

export const isInt: validateParam = (data, fields, options = {}) =>
  check(fields, (field) => {
    if (_.get(data, field) !== '' && !validator.isInt(_.get(data, field) + '', options)) {
      return options.err_msg || ['error_must_be_digits'];
    }
  });

export const isDate: validateParam = (data, fields, options = {}) =>
  check(fields, (field) => {
    if (
      _.get(data, field) !== '' &&
      !moment(_.get(data, field), options.format || 'DD/MM/YYYY').isValid()
    ) {
      return options.err_msg || ['error_invalid_date_format'];
    }
  });

export const greaterThan: validateParam = (data, fields, options = {}) =>
  check(fields, (field) => {
    if (_.get(data, field) !== '' && !validator.isFloat(_.get(data, field) + '', options)) {
      return options.err_msg || ['error_must_be_greater_than', [options.gt]];
    }
  });

export const greaterEqualThan: validateParam = (data, fields, options = {}) =>
  check(fields, (field) => {
    if (_.get(data, field) !== '' && !validator.isFloat(_.get(data, field) + '', options)) {
      return options.err_msg || ['error_must_be_greater_equal_than', [options.min]];
    }
  });

export const between: validateParam = (data, fields, options = {}) =>
  check(fields, (field) => {
    if (_.get(data, field) !== '' && !validator.isFloat(_.get(data, field) + '', options)) {
      return options.err_msg || ['error_must_between', [options.min, options.max]];
    }
  });

export const lengthEqual: validateParam = (data, fields, options = {}) =>
  check(fields, (field) => {
    options.max = options.max ?? options.min;
    if (_.get(data, field) !== '' && !validator.isLength(_.get(data, field) + '', options)) {
      return options.err_msg || ['error_length_must_be_equal', [options.min || options.max]];
    }
  });

export const lengthBetween: validateParam = (data, fields, options = {}) =>
  check(fields, (field) => {
    if (_.get(data, field) !== '' && !validator.isLength(_.get(data, field) + '', options)) {
      return options.err_msg || ['error_length_must_between', [options.min, options.max]];
    }
  });

export const lengthLessEqualThan: validateParam = (data, fields, options = {}) =>
  check(fields, (field) => {
    if (_.get(data, field) !== '' && !validator.isLength(_.get(data, field) + '', options)) {
      return options.err_msg || ['error_length_must_be_less_equal_than', [options.max]];
    }
  });

export const email: validateParam = (data, fields, options = {}) =>
  check(fields, (field) => {
    if (_.get(data, field) !== '' && !validator.isEmail(_.get(data, field) + '', options)) {
      return options.err_msg || ['error_invalid_email_format'];
    }
  });

export const url: validateParam = (data, fields, options = {}) =>
  check(fields, (field) => {
    if (_.get(data, field) !== '' && !validator.isURL(_.get(data, field) + '', options)) {
      return options.err_msg || ['error_invalid_url_format'];
    }
  });

export const domain: validateParam = (data, fields, options = {}) =>
  check(fields, (field) => {
    let value: string = _.get(data, field) + '';
    if (options?.allowWildcard) {
      value = value.replace('*', 'xxx');
    }

    if (_.get(data, field) !== '' && !validator.isFQDN(value, options)) {
      return options.err_msg || ['error_invalid_domain_format'];
    }
  });

export const fileCountLessEqualThan: validateParam = (data, fields, options = {}) =>
  check(fields, (field) => {
    if (!options?.max) {
      options.max = MAX_UPLOAD_NUMBER;
    }
    if (_.get(data, field) !== '' && !validator.isInt(_.get(data, field).length + '', options)) {
      return options.err_msg || ['error_must_be_less_equal_than_files', [options.max]];
    }
  });

export const fileSizeLessThan: validateParam = (data, fields, options = {}) =>
  check(fields, (field) => {
    if (!options?.max) {
      options.max = MAX_UPLOAD_SIZE;
    }
    if (_.get(data, field) && !validator.isInt(_.get(data, field).size + '', options)) {
      return (
        options.err_msg || [
          'error_file_size_must_be_less_than',
          [`${options.max / Math.pow(2, 20)}MB`],
        ]
      );
    }
  });

export const username: validateParam = (data, fields, options = {}) =>
  check(fields, (field) => {
    if (
      _.get(data, field) !== '' &&
      !/^[a-zA-Z0-9](_(?!(\.|_))|\.(?!(_|\.))|[a-zA-Z0-9]){6,18}[a-zA-Z0-9]$/.test(
        _.get(data, field),
      )
    ) {
      return options.err_msg || ['error_invalid_username_format'];
    }
  });

export const password: validateParam = (data, fields, options = {}) =>
  check(fields, (field) => {
    if (
      _.get(data, field) !== '' &&
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d~!@#$%^&*()=[\]{};?]{8,100}$/.test(
        _.get(data, field),
      )
    ) {
      return options.err_msg || ['error_invalid_password_format'];
    }
  });

export const phone: validateParam = (data, fields, options = {}) =>
  check(fields, (field) => {
    if (_.get(data, field) !== '' && !/^[^1]/.test(_.get(data, field))) {
      return options.err_msg || ['error_invalid_phone_format'];
    }
  });

export const check = (fields: string | Array<string>, validateFn: (field: string) => any): any => {
  const errors: any = {};

  if (typeof fields === 'string') {
    fields = [fields];
  }

  fields.map((field) => {
    const error = validateFn(field);
    if (error) {
      errors[field] = error;
    }
  });
  return errors;
};

export type validateParam = (data: any, fields: string | Array<string>, options?: any) => any;
