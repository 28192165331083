import { gateway } from 'redux/reducers/dataProps';
import {
  ALIPAY_HEADER,
  AMEX_HEADER,
  APPLY_PAY_HEADER,
  CREDIT_CARD_HEADER,
  DBS_FPS_HEADER,
  DBS_FPS_HEADER_EN,
  GOOGLE_PAY_HEADER,
  HSBC_FPS_HEADER,
  HSBC_FPS_HEADER_EN,
  MASTERCARD_HEADER,
  ONLINE_HEADER,
  UNIONPAY_HEADER,
  UNIONPAY_QUICKPASS_HEADER,
  VISA_HEADER,
  WECHAT_HEADER,
} from './gateways/gatewayHeaders';
import {
  ALIPAY_CN_SVG,
  ALIPAY_LOGO,
  AMEX_SVG,
  DBS_FPS_IMG,
  FPS_SVG,
  MASTERCARD_LOGO,
  QUICKPASS_HORIZONTAL_WHITE_SVG,
  UNIONPAY_PNG,
  VISA_LOGO,
  WECHATPAY_LOGO_CHINESE_PNG,
  WECHAT_LOGO,
} from './gateways/gatewayLogos';
import {
  ALIPAY_THUMB,
  AMEX_THUMB,
  MASTER_THUMB,
  UNIONPAY_THUMB,
  VISA_THUMB,
  WECHAT_THUMB,
} from './gateways/gatewayThumb';

export const AVAILABLE_TXN_GATEWAY_IDS = [1, 8];

export const ALIPAY_GATEWAY_CODE = '1_1';
export const YEDPAY_GATEWAY_CODE = '3_1';
export const VISA_GATEWAY_CODE = '7_1';
export const MASTER_GATEWAY_CODE = '7_2';
export const AMEX_GATEWAY_CODE = '7_5';
export const WECHATPAY_GATEWAY_CODE = '8_1';
export const UNIONPAY_GATEWAY_CODE = '10_1';
export const HSBC_FPS_ONLINE_GATEWAY_CODE = '13_1';
export const HSBC_FPS_OFFLINE_GATEWAY_CODE = '13_2';
export const DBS_FPS_ONLINE_GATEWAY_CODE = '13_3';
export const DBS_FPS_OFFLINE_GATEWAY_CODE = '13_4';
export const DBS_FPS_YEDPAY_ONLINE_CODE = '13_5';
export const TAP_TO_PHONE_CYBERSOURCE_VISA_GATEWAY_CODE = '15_1';
export const TAP_TO_PHONE_CYBERSOURCE_MASTERCARD_GATEWAY_CODE = '15_2';

export const QR_CODE_AVAILABLE_GATEWAY_CODES = ['1_1', '8_1', '10_1', '13_2', '13_4'];
export const FIXED_CODE_AVAILABLE_GATEWAY_CODES = ['1_1', '8_1'];
export const EMV_CODE_AVAILABLE_GATEWAY_CODES = ['8_1', '10_1', '13_2'];
export const APPLY_ONLINE_STORE_GATEWAY = ['4_2', '8_2', '9_1', '12_1'];
export const PAY_LINK_GATEWAY = ['4_1', '8_2', '9_1', '9_5'];
export const CYBERSOURCE_GATEWAY = ['7_1', '7_2', '7_5'];
export const PAY_PLUS_GATEWAY = ['13_1', '13_2', '13_3', '13_4'];
export const PAY_BY_LINK_GATEWAY = ['4_1', '8_2', '9_1', '9_5'];
export const HIDE_GATEWAY = ['9_3', '9_4', '12_3', '12_4'];
export const TAP_TO_PHONE_GATEWAY = ['15_1', '15_2'];
export const PAY_PLUS_ONLINE_GATEWAY = ['13_1', '13_3'];
export const PAY_PLUS_OFFLINE_GATEWAY = ['13_2', '13_4'];

export const GATEWAY_ORDER = [
  '1_1',
  '4_1',
  '4_2',
  '4_3',
  '8_1',
  '8_2',
  '8_3',
  '7_1',
  '12_1',
  '7_2',
  '12_2',
  '7_5',
  '12_3',
  '12_4',
  '11_1',
  '11_2',
  '9_1',
  '9_2',
  '9_5',
  '9_6',
  '10_1',
  '10_2',
  '13_3',
  '13_4',
  '13_1',
  '13_2',
];
const GATEWAY_ARRAY: gateway[] = [
  {
    gateway_id: '1',
    gateway_sub_id: '1',
    gateway_code: '1_1',
    gateway_sub_name: 'Alipay',
    name: 'alipay',
    img: ALIPAY_LOGO,
    gateway_type: 'offline',
    wallet: 'HKCN',
    thumbnail: ALIPAY_THUMB,
    logo: ALIPAY_LOGO,
    header: ALIPAY_HEADER,
    header_en: ALIPAY_HEADER,
    hasConfig: false,
    canControlDisplay: false,
  },
  {
    gateway_id: '4',
    gateway_sub_id: '1',
    gateway_code: '4_1',
    gateway_sub_name: 'Alipay Online WAP',
    name: 'alipay_online',
    img: ALIPAY_CN_SVG,
    gateway_type: 'online',
    wallet: 'HKCN',
    thumbnail: ALIPAY_THUMB,
    header: ALIPAY_HEADER,
    header_en: ALIPAY_HEADER,
    hasConfig: false,
    canControlDisplay: true,
  },
  {
    gateway_id: '4',
    gateway_sub_id: '2',
    gateway_code: '4_2',
    gateway_sub_name: 'Alipay Online PC2Mobile',
    name: 'alipay_online',
    img: ALIPAY_CN_SVG,
    gateway_type: 'online',
    wallet: 'HKCN',
    thumbnail: ALIPAY_THUMB,
    header: ALIPAY_HEADER,
    header_en: ALIPAY_HEADER,
    hasConfig: false,
    canControlDisplay: true,
  },
  {
    gateway_id: '4',
    gateway_sub_id: '3',
    gateway_code: '4_3',
    gateway_sub_name: 'Alipay Online SDK',
    name: 'alipay_online',
    img: ALIPAY_CN_SVG,
    gateway_type: 'online',
    wallet: 'HKCN',
    thumbnail: ALIPAY_THUMB,
    header: ALIPAY_HEADER,
    header_en: ALIPAY_HEADER,
    hasConfig: false,
    canControlDisplay: true,
  },
  {
    gateway_id: '8',
    gateway_sub_id: '1',
    gateway_code: '8_1',
    gateway_sub_name: 'Wechat Pay',
    name: 'wechatpay',
    img: WECHATPAY_LOGO_CHINESE_PNG,
    gateway_type: 'offline',
    wallet: 'CN',
    thumbnail: WECHAT_THUMB,
    logo: WECHAT_LOGO,
    header: WECHAT_HEADER,
    header_en: WECHAT_HEADER,
    hasConfig: false,
    canControlDisplay: false,
  },
  {
    gateway_id: '8',
    gateway_sub_id: '2',
    gateway_code: '8_2',
    gateway_sub_name: 'WeChat Pay Online',
    name: 'wechatpay',
    img: WECHATPAY_LOGO_CHINESE_PNG,
    gateway_type: 'online',
    wallet: 'HKCN',
    thumbnail: WECHAT_THUMB,
    logo: WECHAT_LOGO,
    header: WECHAT_HEADER,
    header_en: WECHAT_HEADER,
    hasConfig: false,
    canControlDisplay: true,
  },
  {
    gateway_id: '8',
    gateway_sub_id: '3',
    gateway_code: '8_3',
    gateway_sub_name: 'WeChat Pay Online SDK',
    name: 'wechatpay',
    img: WECHAT_LOGO,
    gateway_type: 'online',
    wallet: 'HKCN',
    thumbnail: WECHAT_THUMB,
    logo: WECHAT_LOGO,
    header: WECHAT_HEADER,
    header_en: WECHAT_HEADER,
    hasConfig: false,
    canControlDisplay: true,
  },
  {
    gateway_id: '7',
    gateway_sub_id: '1',
    gateway_code: '7_1',
    gateway_sub_name: 'VISA',
    name: 'visa',
    img: VISA_LOGO,
    gateway_type: 'offline',
    wallet: '',
    thumbnail: VISA_THUMB,
    header: VISA_HEADER,
    header_en: VISA_HEADER,
    hasConfig: true,
    canControlDisplay: false,
  },
  {
    gateway_id: '7',
    gateway_sub_id: '2',
    gateway_code: '7_2',
    gateway_sub_name: 'Mastercard',
    name: 'mastercard',
    img: MASTERCARD_LOGO,
    gateway_type: 'offline',
    wallet: '',
    thumbnail: MASTER_THUMB,
    header: MASTERCARD_HEADER,
    header_en: MASTERCARD_HEADER,
    hasConfig: true,
    canControlDisplay: false,
  },
  {
    gateway_id: '7',
    gateway_sub_id: '5',
    gateway_code: '7_5',
    gateway_sub_name: 'AMEX',
    name: 'amex',
    img: AMEX_SVG,
    gateway_type: 'offline',
    wallet: '',
    thumbnail: AMEX_THUMB,
    header: AMEX_HEADER,
    header_en: AMEX_HEADER,
    hasConfig: true,
    canControlDisplay: false,
  },
  {
    gateway_id: '12',
    gateway_sub_id: '1',
    gateway_code: '12_1',
    gateway_sub_name: 'VISA Online',
    name: 'visa_online',
    img: VISA_LOGO,
    gateway_type: 'online',
    wallet: '',
    thumbnail: VISA_THUMB,
    header: VISA_HEADER,
    header_en: VISA_HEADER,
    hasConfig: false,
    canControlDisplay: true,
  },
  {
    gateway_id: '12',
    gateway_sub_id: '2',
    gateway_code: '12_2',
    gateway_sub_name: 'Mastercard Online',
    name: 'mastercard_online',
    img: MASTERCARD_LOGO,
    gateway_type: 'online',
    wallet: '',
    thumbnail: MASTER_THUMB,
    header: MASTERCARD_HEADER,
    header_en: MASTERCARD_HEADER,
    hasConfig: false,
    canControlDisplay: true,
  },
  {
    gateway_id: '12',
    gateway_sub_id: '3',
    gateway_code: '12_3',
    gateway_sub_name: 'Google Pay',
    name: 'google_pay',
    img: '',
    gateway_type: 'online',
    wallet: '',
    thumbnail: '',
    header: GOOGLE_PAY_HEADER,
    header_en: GOOGLE_PAY_HEADER,
    hasConfig: false,
    canControlDisplay: false,
  },
  {
    gateway_id: '12',
    gateway_sub_id: '4',
    gateway_code: '12_4',
    gateway_sub_name: 'Apple Pay',
    name: 'apple_pay',
    img: '',
    gateway_type: 'online',
    wallet: '',
    thumbnail: '',
    header: APPLY_PAY_HEADER,
    header_en: APPLY_PAY_HEADER,
    hasConfig: false,
    canControlDisplay: false,
  },
  {
    gateway_id: '11',
    gateway_sub_id: '1',
    gateway_code: '11_1',
    gateway_sub_name: 'UnionPay',
    name: 'unionpay_cards',
    img: UNIONPAY_PNG,
    gateway_type: 'offline',
    wallet: '',
    thumbnail: UNIONPAY_THUMB,
    header: UNIONPAY_HEADER,
    header_en: UNIONPAY_HEADER,
    hasConfig: false,
    canControlDisplay: false,
  },
  {
    gateway_id: '11',
    gateway_sub_id: '2',
    gateway_code: '11_2',
    gateway_sub_name: 'UnionPay POS V2',
    name: 'unionpay_cards',
    img: UNIONPAY_PNG,
    gateway_type: 'offline',
    wallet: '',
    thumbnail: UNIONPAY_THUMB,
    header: UNIONPAY_HEADER,
    header_en: UNIONPAY_HEADER,
    hasConfig: false,
    canControlDisplay: false,
  },
  {
    gateway_id: '9',
    gateway_sub_id: '1',
    gateway_code: '9_1',
    gateway_sub_name: 'UnionPay ExpressPay',
    name: 'unionpay_online',
    img: UNIONPAY_PNG,
    gateway_type: 'online',
    wallet: '',
    thumbnail: UNIONPAY_THUMB,
    header: UNIONPAY_HEADER,
    header_en: UNIONPAY_HEADER,
    hasConfig: false,
    canControlDisplay: true,
  },
  {
    gateway_id: '9',
    gateway_sub_id: '2',
    gateway_code: '9_2',
    gateway_sub_name: 'UnionPay ExpressPay CN',
    name: 'unionpay_online',
    img: UNIONPAY_PNG,
    gateway_type: 'online',
    wallet: '',
    thumbnail: UNIONPAY_THUMB,
    header: UNIONPAY_HEADER,
    header_en: UNIONPAY_HEADER,
    hasConfig: false,
    canControlDisplay: true,
  },
  {
    gateway_id: '9',
    gateway_sub_id: '5',
    gateway_code: '9_5',
    gateway_sub_name: 'UnionPay UPOP',
    name: 'unionpay_online',
    img: UNIONPAY_PNG,
    gateway_type: 'online',
    wallet: '',
    thumbnail: UNIONPAY_THUMB,
    header: UNIONPAY_HEADER,
    header_en: UNIONPAY_HEADER,
    hasConfig: false,
    canControlDisplay: true,
  },
  {
    gateway_id: '9',
    gateway_sub_id: '6',
    gateway_code: '9_6',
    gateway_sub_name: 'UnionPay QR',
    name: 'unionpay_online',
    img: QUICKPASS_HORIZONTAL_WHITE_SVG,
    gateway_type: 'online',
    wallet: '',
    thumbnail: UNIONPAY_THUMB,
    header: UNIONPAY_QUICKPASS_HEADER,
    header_en: UNIONPAY_QUICKPASS_HEADER,
    hasConfig: false,
    canControlDisplay: true,
  },
  {
    gateway_id: '10',
    gateway_sub_id: '1',
    gateway_code: '10_1',
    gateway_sub_name: 'UnionPay Merchant Presented Code',
    name: 'unionpay',
    img: QUICKPASS_HORIZONTAL_WHITE_SVG,
    gateway_type: 'offline',
    wallet: '',
    thumbnail: UNIONPAY_THUMB,
    header: UNIONPAY_QUICKPASS_HEADER,
    header_en: UNIONPAY_QUICKPASS_HEADER,
    hasConfig: false,
    canControlDisplay: false,
  },
  {
    gateway_id: '10',
    gateway_sub_id: '2',
    gateway_code: '10_2',
    gateway_sub_name: 'UnionPay Customer Presented Code',
    name: 'unionpay',
    img: UNIONPAY_PNG,
    gateway_type: 'offline',
    wallet: '',
    thumbnail: UNIONPAY_THUMB,
    header: UNIONPAY_QUICKPASS_HEADER,
    header_en: UNIONPAY_QUICKPASS_HEADER,
    hasConfig: false,
    canControlDisplay: false,
  },
  {
    gateway_id: '13',
    gateway_sub_id: '1',
    gateway_code: '13_1',
    gateway_sub_name: 'HSBC FPS Online',
    name: 'fps',
    img: FPS_SVG,
    gateway_type: 'online',
    wallet: '',
    thumbnail: '',
    header: HSBC_FPS_HEADER,
    header_en: HSBC_FPS_HEADER_EN,
    hasConfig: true,
    canControlDisplay: true,
  },
  {
    gateway_id: '13',
    gateway_sub_id: '2',
    gateway_code: '13_2',
    gateway_sub_name: 'HSBC FPS Offline',
    name: 'fps',
    img: FPS_SVG,
    gateway_type: 'offline',
    wallet: '',
    thumbnail: '',
    header: HSBC_FPS_HEADER,
    header_en: HSBC_FPS_HEADER_EN,
    hasConfig: true,
    canControlDisplay: false,
  },
  {
    gateway_id: '13',
    gateway_sub_id: '3',
    gateway_code: '13_3',
    gateway_sub_name: 'DBS MAX FPS Online',
    name: 'fps',
    img: DBS_FPS_IMG,
    gateway_type: 'online',
    wallet: '',
    thumbnail: '',
    header: DBS_FPS_HEADER,
    header_en: DBS_FPS_HEADER_EN,
    hasConfig: true,
    canControlDisplay: true,
  },
  {
    gateway_id: '13',
    gateway_sub_id: '4',
    gateway_code: '13_4',
    gateway_sub_name: 'DBS MAX FPS Offline',
    name: 'fps',
    img: DBS_FPS_IMG,
    gateway_type: 'offline',
    wallet: '',
    thumbnail: '',
    header: DBS_FPS_HEADER,
    header_en: DBS_FPS_HEADER_EN,
    hasConfig: true,
    canControlDisplay: false,
  },
  {
    gateway_id: '13',
    gateway_sub_id: '5',
    gateway_code: '13_5',
    gateway_sub_name: 'Yedpay FPS',
    name: 'fps',
    img: '',
    gateway_type: 'offline',
    wallet: '',
    thumbnail: '',
    header: '',
    header_en: '',
    hasConfig: false,
    canControlDisplay: false,
  },
];
export default GATEWAY_ARRAY;

export const findGatewayByCode = (code: string) => {
  return GATEWAY_ARRAY.find((gateway) => gateway.gateway_code === code);
};

export interface GatewayGroup {
  name: string;
  code: Array<string>;
  wallet: string;
  thumbnail: Array<any>;
  img: Array<any>;
  header: any;
  header_en: any;
  type: string;
  hasConfig: boolean;
  addendumUrl: string;
}

export enum GatewayGroupEnum {
  ALIPAY = 'alipay',
  ALIPAY_ONLINE = 'alipay_online',
  VISA_MASTERCARD = 'visa_mastercard',
  WECHATPAY = 'wechatpay',
  WECHATPAY_ONLINE = 'wechatpay_online',
  UNIONPAY_ONLINE = 'unionpay_online',
  UNIONPAY = 'unionpay',
  VISA_MASTERCARD_ONLINE = 'visa_mastercard_online',
  HSBC_FPS_ONLINE = 'fps_hsbc_online',
  HSBC_FPS_OFFLINE = 'fps_hsbc_offline',
  DBS_FPS_ONLINE = 'fps_dbs_online',
  DBS_FPS_OFFLINE = 'fps_dbs_offline',
  AMEX = 'AMEX',
}
export const GATEWAY_GROUP_ARRAY: Array<GatewayGroup> = [
  {
    name: GatewayGroupEnum.ALIPAY,
    code: ['1_1'],
    wallet: 'HKCN',
    thumbnail: [ALIPAY_THUMB],
    img: [ALIPAY_LOGO],
    header: ALIPAY_HEADER,
    header_en: ALIPAY_HEADER,
    type: 'offline',
    hasConfig: false,
    addendumUrl: '',
  },
  {
    name: GatewayGroupEnum.ALIPAY_ONLINE,
    code: ['4_1', '4_2', '4_3'],
    wallet: 'HKCN',
    thumbnail: [ALIPAY_THUMB],
    img: [ALIPAY_LOGO],
    header: ALIPAY_HEADER,
    header_en: ALIPAY_HEADER,
    type: 'online',
    hasConfig: false,
    addendumUrl: '',
  },
  {
    name: GatewayGroupEnum.VISA_MASTERCARD,
    code: ['7_1', '7_2'],
    wallet: '',
    thumbnail: [VISA_THUMB, MASTER_THUMB],
    img: [VISA_LOGO, MASTERCARD_LOGO],
    header: CREDIT_CARD_HEADER,
    header_en: CREDIT_CARD_HEADER,
    type: 'offline',
    hasConfig: true,
    addendumUrl: 'https://api.yedpay.com/visa_mastercard_application_form.pdf',
  },
  {
    name: GatewayGroupEnum.AMEX,
    code: ['7_5'],
    wallet: '',
    thumbnail: [AMEX_THUMB],
    img: [AMEX_SVG],
    header: AMEX_HEADER,
    header_en: AMEX_HEADER,
    type: 'offline',
    hasConfig: true,
    addendumUrl: '',
  },
  {
    name: GatewayGroupEnum.WECHATPAY,
    code: ['8_1'],
    wallet: 'CN',
    thumbnail: [WECHAT_THUMB],
    img: [WECHAT_LOGO],
    header: WECHAT_HEADER,
    header_en: WECHAT_HEADER,
    type: 'offline',
    hasConfig: false,
    addendumUrl: '',
  },
  {
    name: GatewayGroupEnum.WECHATPAY_ONLINE,
    code: ['8_2', '8_3'],
    wallet: 'CN',
    thumbnail: [WECHAT_THUMB],
    img: [WECHAT_LOGO],
    header: WECHAT_HEADER,
    header_en: WECHAT_HEADER,
    type: 'online',
    hasConfig: false,
    addendumUrl: '',
  },
  {
    name: GatewayGroupEnum.UNIONPAY_ONLINE,
    code: ['9_1', '9_2', '9_5', '9_6'],
    wallet: '',
    thumbnail: [UNIONPAY_THUMB],
    img: [UNIONPAY_PNG],
    header: UNIONPAY_HEADER,
    header_en: UNIONPAY_HEADER,
    type: 'online',
    hasConfig: false,
    addendumUrl: '',
  },
  {
    name: GatewayGroupEnum.UNIONPAY,
    code: ['10_1', '10_2', '11_1', '11_2'],
    wallet: '',
    thumbnail: [UNIONPAY_THUMB],
    img: [UNIONPAY_PNG],
    header: UNIONPAY_HEADER,
    header_en: UNIONPAY_HEADER,
    type: 'offline',
    hasConfig: false,
    addendumUrl: '',
  },
  {
    name: GatewayGroupEnum.VISA_MASTERCARD_ONLINE,
    code: ['12_1', '12_2'],
    wallet: '',
    thumbnail: [VISA_THUMB, MASTER_THUMB],
    img: [VISA_LOGO, MASTERCARD_LOGO],
    header: CREDIT_CARD_HEADER,
    header_en: CREDIT_CARD_HEADER,
    type: 'online',
    hasConfig: false,
    addendumUrl: '',
  },
  {
    name: GatewayGroupEnum.HSBC_FPS_ONLINE,
    code: ['13_1'],
    wallet: '',
    thumbnail: [],
    img: [FPS_SVG],
    header: HSBC_FPS_HEADER,
    header_en: HSBC_FPS_HEADER_EN,
    type: 'online',
    hasConfig: true,
    addendumUrl: '',
  },
  {
    name: GatewayGroupEnum.HSBC_FPS_OFFLINE,
    code: ['13_2'],
    wallet: '',
    thumbnail: [],
    img: [FPS_SVG],
    header: HSBC_FPS_HEADER,
    header_en: HSBC_FPS_HEADER_EN,
    type: 'offline',
    hasConfig: true,
    addendumUrl: '',
  },
  {
    name: GatewayGroupEnum.DBS_FPS_ONLINE,
    code: ['13_3'],
    wallet: '',
    thumbnail: [],
    img: [DBS_FPS_IMG],
    header: DBS_FPS_HEADER,
    header_en: DBS_FPS_HEADER_EN,
    type: 'online',
    hasConfig: true,
    addendumUrl: '',
  },
  {
    name: GatewayGroupEnum.DBS_FPS_OFFLINE,
    code: ['13_4'],
    wallet: '',
    thumbnail: [],
    img: [DBS_FPS_IMG],
    header: DBS_FPS_HEADER,
    header_en: DBS_FPS_HEADER_EN,
    type: 'offline',
    hasConfig: true,
    addendumUrl: '',
  },
  {
    name: 'online',
    code: [
      '4_1',
      '4_2',
      '4_3',
      '8_2',
      '8_3',
      '9_1',
      '9_2',
      '9_5',
      '9_6',
      '12_1',
      '12_2',
      '12_3',
      '12_4',
    ],
    wallet: '',
    thumbnail: [ALIPAY_THUMB, WECHAT_THUMB, UNIONPAY_THUMB, VISA_THUMB, MASTER_THUMB],
    img: [ALIPAY_LOGO, WECHAT_LOGO, UNIONPAY_PNG, VISA_LOGO, MASTERCARD_LOGO],
    header: ONLINE_HEADER,
    header_en: ONLINE_HEADER,
    type: 'online',
    hasConfig: false,
    addendumUrl: '',
  },
];
export const ONLINE_GATEWAY_GROUP = {
  name: 'online',
  code: [
    '4_1',
    '4_2',
    '4_3',
    '8_2',
    '8_3',
    '9_1',
    '9_2',
    '9_5',
    '9_6',
    '12_1',
    '12_2',
    '12_3',
    '12_4',
  ],
  wallet: '',
  thumbnail: [ALIPAY_THUMB, WECHAT_THUMB, UNIONPAY_THUMB, VISA_THUMB, MASTER_THUMB],
  img: [ALIPAY_LOGO, WECHAT_LOGO, UNIONPAY_PNG, VISA_LOGO, MASTERCARD_LOGO],
  header: ONLINE_HEADER,
  header_en: ONLINE_HEADER,
  type: 'online',
  hasConfig: false,
  addendumUrl: '',
};

export const findGatewayGroupByCode = (code: string): GatewayGroup | undefined => {
  return GATEWAY_GROUP_ARRAY.find((gateway) => gateway.code.includes(code));
};
