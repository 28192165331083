import { useTranslate } from 'context/TranslateContext';
import { useDispatch, useSelector } from 'react-redux';
import { FunctionDispatch, GlobalTypes } from 'redux/types';
import { useSwal } from 'helpers/sweetalert';
import { getMe as apiGetMe } from 'actions/AuthActions';
import { useEffect, useState } from 'react';
import { updateBalance } from 'actions/BalanceActions';
import { Spinner } from 'reactstrap';

export const BalanceBox = () => {
  const { auth } = useSelector((state: GlobalTypes.RootState) => ({
    auth: state?.auth,
  }));

  const { translate } = useTranslate();
  const [Swal] = useSwal();
  const dispatch = useDispatch<FunctionDispatch>();
  const [isLoading, setIsLoading] = useState(true);

  const getInitialData = async () => {
    setIsLoading(true);
    const res = await dispatch(updateBalance());
    setIsLoading(false);
    if (!res.success) {
      return Swal.fire({
        icon: 'error',
        title: translate('balance'),
        text: res.message || 'Oops',
      });
    }
  };

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="balance-box bg-white mb-4 round-sm shadow-sm">
        <label className="form-label text-warning">{translate('balance_available')}:</label>
        <div className="text-end" style={{ flex: 1 }}>
          {isLoading ? (
            <Spinner size="sm" />
          ) : (
            <>
              <span className="balance text-warning">
                {auth?.user?.balance?.data?.available_balance_float}
              </span>
              <span className="currency text-warning">
                &nbsp;{auth?.user?.balance?.data?.currency}
              </span>
            </>
          )}
        </div>
        <div className="break"></div>

        <label className="form-label">{translate('holding_amount')}:</label>
        <div className="text-end" style={{ flex: 1 }}>
          {isLoading ? (
            <Spinner size="sm" />
          ) : (
            <>
              <span className="balance text-warning">
                {auth?.user?.balance?.data?.holding_balance_float}
              </span>
              <span className="currency text-warning">
                &nbsp;{auth?.user?.balance?.data?.currency}
              </span>
            </>
          )}
        </div>
        <div className="break"></div>
        <label className="form-label">{translate('auto_settlement')}:</label>
        <span className="auto-settlement text-success">
          {translate(auth?.user.company.auto_settlement ? 'enabled' : 'disabled')}
        </span>
      </div>
    </>
  );
};
