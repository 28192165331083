import { params } from 'helpers/functions/param';
import axios, { ContentType, defaultRequestHandler, getToken } from '../common/Fetcher';
import {
  GetPreAuthTransactionResponse,
  CancelPreAuthTransactionResponse,
  GetPreAuthTransactionDetailsResponse,
  CapturePreAuthTransactionResponse,
} from './interface';

export const getPreAuthTransactions = (
  company_id: string,
  data = {},
): Promise<GetPreAuthTransactionResponse> =>
  defaultRequestHandler(
    axios.get(`/companies/${company_id}/authorizations?${params(data)}`, {
      headers: {
        authorization: getToken(),
      },
    }),
  );

export const cancelPreAuthTransaction = (id: string): Promise<CancelPreAuthTransactionResponse> =>
  defaultRequestHandler(
    axios.put(
      `/authorization/${id}/cancel`,
      {},
      {
        headers: {
          authorization: getToken(),
          'Content-Type': ContentType.urlEncoded,
        },
      },
    ),
  );

export const capturePreAuthTransaction = (
  id: string,
  data = {},
): Promise<CapturePreAuthTransactionResponse> =>
  defaultRequestHandler(
    axios.put(`/authorization/${id}/capture`, data, {
      headers: {
        authorization: getToken(),
        'Content-Type': ContentType.urlEncoded,
      },
    }),
  );

export const getPreAuthTransactionDetails = (
  company_id: string,
  auth_id: string,
  data = {},
): Promise<GetPreAuthTransactionDetailsResponse> =>
  defaultRequestHandler(
    axios.get(`/companies/${company_id}/authorization/${auth_id}?${params(data)}`, {
      headers: {
        authorization: getToken(),
      },
    }),
  );
