import moment from 'moment';
import { validateParam } from './standard';

export const dateRangeDurationLessEqualThan: validateParam = (data, fields, options = {}): any => {
  const d1 = moment(data[fields[0]]);
  const d2 = moment(data[fields[1]]);

  if (options.start_date_time) {
    const [fromHours, fromMinutes, fromSeconds] = options.start_date_time.split(':').map(Number);
    d1.set({ hours: fromHours, minutes: fromMinutes, seconds: fromSeconds });
  } else {
    d1.set({ hours: 0, minutes: 0, seconds: 0 });
  }

  if (options.end_date_time) {
    const [toHours, toMinutes, toSeconds] = options.end_date_time.split(':').map(Number);
    d2.set({ hours: toHours, minutes: toMinutes, seconds: toSeconds });
  } else {
    d2.set({ hours: 23, minutes: 59, seconds: 59 });
  }
  const maxInSeconds = options.max * 24 * 60 * 60;
  const diffInSeconds = d2.diff(d1, 'seconds');

  if (diffInSeconds > maxInSeconds) {
    return {
      [fields[0]]: options.err_msg || [
        'error_duration_must_be_less_equal_than',
        [options.max, options.unit],
      ],
      [fields[1]]: options.err_msg || [
        'error_duration_must_be_less_equal_than',
        [options.max, options.unit],
      ],
    };
  }
};
