import axios, {
  defaultRequestHandler,
  rawResponseRequestHandler,
  getToken,
  params,
} from '../common/Fetcher';

import { CreateMfaProps, VerifyMfaProps, GetMfaListResponse } from './interface';
import { DefaultResponse } from './../common/Fetcher';

export const getMfaList = (user_id: string): Promise<GetMfaListResponse> =>
  defaultRequestHandler(
    axios.get(`/users/${user_id}/security/mfa`, {
      headers: {
        authorization: getToken(),
      },
    }),
  );

export const getCompanyMfaList = (company_id: string, data: object): Promise<GetMfaListResponse> =>
  defaultRequestHandler(
    axios.get(`/companies/${company_id}/security/mfa?${params(data)}`, {
      headers: {
        authorization: getToken(),
      },
    }),
  );

export const setMfaAsDefault = (user_id: string, mfa_id: string): Promise<DefaultResponse> =>
  defaultRequestHandler(
    axios.put(
      `/users/${user_id}/security/mfa-setup/${mfa_id}/default`,
      {},
      {
        headers: {
          authorization: getToken(),
        },
      },
    ),
  );

export const deleteMfa = (user_id: string, mfa_id: string): Promise<DefaultResponse> =>
  defaultRequestHandler(
    axios.delete(`/users/${user_id}/security/mfa-setup/${mfa_id}`, {
      headers: {
        authorization: getToken(),
      },
    }),
  );

export const createMfa = (user_id: string, data: CreateMfaProps): Promise<DefaultResponse> =>
  rawResponseRequestHandler(
    axios.post(`/users/${user_id}/security/mfa-setup`, params(data), {
      headers: {
        authorization: getToken(),
      },
    }),
  );

export const verifyMfa = (data: VerifyMfaProps): Promise<DefaultResponse> =>
  rawResponseRequestHandler(
    axios.post(`/me/security/mfa-setup-verify`, params(data), {
      headers: {
        authorization: getToken(),
      },
    }),
  );
