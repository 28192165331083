import { uploadContract } from 'actions/CompanyActions';
import { applyCredential, applyCredentialByType } from 'actions/GatewayActions';
import { BankCodeSelector } from 'components/custom/BankCodeSelector/BankCodeSelector';
import { Input } from 'components/custom/input';
import { SelectOptionProps } from 'components/custom/input/interfaces';
import {
  AMEX_GATEWAY_CODE,
  CYBERSOURCE_GATEWAY,
  DBS_FPS_OFFLINE_GATEWAY_CODE,
  DBS_FPS_ONLINE_GATEWAY_CODE,
  GatewayGroup,
  HSBC_FPS_OFFLINE_GATEWAY_CODE,
  HSBC_FPS_ONLINE_GATEWAY_CODE,
  MASTER_GATEWAY_CODE,
  ONLINE_GATEWAY_GROUP,
  PAY_PLUS_GATEWAY,
  VISA_GATEWAY_CODE,
} from 'constants/TransactionGateways';
import { useTranslate } from 'context/TranslateContext';
import { removeEmptyValuePair } from 'helpers/functions/removeEmptyValuePair';
import { useSwal } from 'helpers/sweetalert';
import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { FunctionDispatch, GlobalTypes } from 'redux/types';
import { UpdateFpsDetailsProps } from 'services/API/FpsTransaction/interface';
import { UpdateCredentialBody } from 'services/API/Gateway/interface';
import './index.scss';
import { validate } from './validator';

export interface ApplyModalProps {
  isOpen: boolean;
  setIsOpen: (isModalOpen: boolean) => void;
  gateway: GatewayGroup;
  getCredentials: () => Promise<void>;
}

export const ApplyModal = ({ isOpen, setIsOpen, gateway, getCredentials }: ApplyModalProps) => {
  const isHsbcOnline = gateway.code[0] == HSBC_FPS_ONLINE_GATEWAY_CODE;
  const isHsbcOffline = gateway.code[0] == HSBC_FPS_OFFLINE_GATEWAY_CODE;

  const initialState = {
    clearing_code: isHsbcOffline || isHsbcOnline ? '004' : '016',
    fps_id: '',
    email: '',
    phone: '',
  } as UpdateFpsDetailsProps;

  const [Swal] = useSwal();
  const { translate, language } = useTranslate();
  const dispatch = useDispatch<FunctionDispatch>();
  const {
    auth: {
      user: { company_id },
    },
  } = useSelector((state: GlobalTypes.RootState) => state);

  const [uploadText, setUploadText] = useState<any>({});
  const [merchantId, setMerchantId] = useState('');

  const [fpsDetails, setFpsDetails] = useState(initialState);
  const [fpsProxy, setFpsProxy] = useState<string>('');
  const [signature_required, setSignatureRequired] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [agreeTerms, setAgreeTerms] = useState(0);

  const isCybersource =
    CYBERSOURCE_GATEWAY.filter((value) => gateway.code.includes(value)).length > 0;
  const isPayPlusGateway =
    PAY_PLUS_GATEWAY.filter((value) => gateway.code.includes(value)).length > 0;
  const isAmex = gateway.code.includes(AMEX_GATEWAY_CODE);

  const fpsWithProxy = [
    HSBC_FPS_OFFLINE_GATEWAY_CODE,
    DBS_FPS_OFFLINE_GATEWAY_CODE,
    DBS_FPS_ONLINE_GATEWAY_CODE,
  ].includes(gateway.code[0]);

  const isDBSFps = [DBS_FPS_OFFLINE_GATEWAY_CODE, DBS_FPS_ONLINE_GATEWAY_CODE].includes(
    gateway.code[0],
  );

  const { clearing_code, fps_id, email, phone } = fpsDetails;

  let fpsProxyArr: Array<SelectOptionProps> = [
    {
      label: translate('fps_id').toUpperCase(),
      value: 'fps_id',
    },
  ];

  if (isHsbcOffline) {
    fpsProxyArr = [
      ...fpsProxyArr,
      {
        label: translate('email').toUpperCase(),
        value: 'email',
      },
      {
        label: translate('phone').toUpperCase(),
        value: 'phone',
      },
    ];
  }

  const toggle = () => {
    setIsOpen(!isOpen);
    setErrors({});
  };

  const onFpsDetailsChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFpsDetails({
      ...fpsDetails,
      [e.target.name]: e.target.value,
    });
  };

  const onFpsProxyChange = (e: string) => {
    setFpsProxy(e);

    const tempFpsProxyArr = [...fpsProxyArr].filter((item) => item?.value !== e);

    const tempProxy: any = {};
    tempFpsProxyArr.forEach((item) => {
      tempProxy[item.value] = '';
      setFpsDetails({
        ...fpsDetails,
        ...tempProxy,
      });
    });
  };

  const onSelectChange = (name: string) => (val: any) => {
    setFpsDetails({
      ...fpsDetails,
      [name]: val.clearing_code,
    });
  };

  const onMerchantIdChange = (e: ChangeEvent<HTMLInputElement>) => {
    setMerchantId(e.target.value);
  };

  const onSubmit = async () => {
    let data: UpdateCredentialBody = {};
    if (isHsbcOnline) {
      data = { merchant_id: merchantId };
    } else if (isDBSFps) {
      data = {
        merchant_id: merchantId,
        ...fpsDetails,
      };
      data['fps_proxy_type'] = fpsProxy;
    } else if (fpsWithProxy) {
      data = fpsDetails;
      data['fps_proxy_type'] = fpsProxy;
    }

    const { errors, isValid } = validate(
      { ...data, agreeTerms },
      isCybersource,
      fpsWithProxy,
      gateway.code[0],
      fpsProxy,
    );
    setErrors(errors);
    if (!isValid) {
      return;
    }
    setErrors({});

    let res;

    if (isPayPlusGateway) {
      switch (gateway.code[0]) {
        case HSBC_FPS_ONLINE_GATEWAY_CODE:
          res = await dispatch(
            applyCredential(company_id, { gateway_code: gateway.code[0], merchant_id: merchantId }),
          );
          break;
        case DBS_FPS_OFFLINE_GATEWAY_CODE:
        case DBS_FPS_ONLINE_GATEWAY_CODE:
          res = await dispatch(
            applyCredential(company_id, {
              gateway_code: gateway.code[0],
              merchant_id: merchantId,
              fps_proxy_identifier: JSON.stringify(
                removeEmptyValuePair({
                  clearing_code,
                  fps_id,
                  email,
                  phone,
                }),
              ),
            }),
          );
          break;
        case HSBC_FPS_OFFLINE_GATEWAY_CODE:
        default:
          res = await dispatch(
            applyCredential(company_id, {
              gateway_code: gateway.code[0],
              fps_proxy_identifier: JSON.stringify(
                removeEmptyValuePair({
                  clearing_code,
                  fps_id,
                  email,
                  phone,
                }),
              ),
            }),
          );
          break;
      }
    } else if (gateway.type === 'offline') {
      switch (gateway.code[0]) {
        case VISA_GATEWAY_CODE:
        case MASTER_GATEWAY_CODE:
        case AMEX_GATEWAY_CODE:
          res = await dispatch(
            applyCredential(company_id, { gateway_code: gateway.code[0], signature_required }),
          );
          break;

        default:
          res = await dispatch(applyCredential(company_id, { gateway_code: gateway.code[0] }));
          break;
      }
    } else if (gateway.name === ONLINE_GATEWAY_GROUP.name) {
      res = await dispatch(applyCredentialByType(company_id, { type: 2 }));
    } else {
      // Apply online gateway by Gateway Group
      res = await dispatch(applyCredential(company_id, { gateway_code: gateway.code[0] }));
    }

    setIsLoading(false);
    setIsOpen(false);

    await Swal.fire({
      icon: res.success ? 'success' : 'error',
      title: translate('apply_for_credential'),
      text: res.message ?? 'Oops',
    });

    await getCredentials();
  };

  useEffect(() => {
    setFpsDetails(initialState);
    setFpsProxy('');
  }, [isOpen]);

  const handleUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target?.files?.[0];
    if (!file) {
      return;
    }
    const fd = new FormData();
    fd.append('type', 'contract');
    fd.append('file', file);

    try {
      const res = await dispatch(uploadContract(fd, company_id));

      if (!res.success) {
        setUploadText({
          error: res.message,
        });
      } else {
        setUploadText({
          success: translate('successful_upload_contract'),
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      const fileInput = document.getElementById('contract');
      if (fileInput) {
        (fileInput as HTMLInputElement).value = '';
      }
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} unmountOnClose>
      <ModalHeader toggle={toggle}>{translate('add_gateway')}</ModalHeader>
      <ModalBody className="apply-gateway-modal">
        <div className="text-center mb-4 gateway-info">
          {gateway.img.map((thumbnail, index) => (
            <img src={thumbnail} key={index} alt="" className="img-fluid gateway-img" />
          ))}
          <p>{translate('processing_time')}</p>
          {gateway.hasConfig && (isHsbcOnline || isHsbcOffline) && <p>{translate('fps_notice')}</p>}
          {gateway.hasConfig && isDBSFps && <p>{translate('fps_dbs_notice')}</p>}
        </div>

        {gateway.hasConfig && isCybersource && !isAmex && (
          <div>
            <hr className="hr-line-dashed" />
            <h3 className="text-center mb-4">
              {translate('addendum_is_required_for_application')}
            </h3>
            <div className="row">
              <div className="col-xs-12 col-md-10 offset-md-1">
                <p className="mb-4 apply-visa-step" data-step="1">
                  <a
                    href={gateway.addendumUrl}
                    className="download-link"
                    target="_blank"
                    rel="noreferrer noopener">
                    {translate('download_addendum')}
                  </a>
                </p>
                <p className="mb-4 apply-visa-step" data-step="2">
                  {translate('sign_and_upload_addendum')}
                </p>
                <div className="mb-2 mt-4">
                  <div className="checkbox">
                    <label className="form-label">
                      <input
                        type="checkbox"
                        name="signature_required"
                        value={`${signature_required}`}
                        onChange={() => setSignatureRequired(!signature_required)}
                      />
                      <span>{translate('require_signature_optional')}</span>
                    </label>
                  </div>
                </div>
                <div className="text-center">
                  <input
                    type="file"
                    className="d-none"
                    name="contract"
                    id="contract"
                    accept=".pdf,.doc,.docx,image/*"
                    onChange={(e) => handleUpload(e)}
                  />
                  <button
                    className="btn btn-primary btn-round"
                    onClick={() => document.getElementById('contract')?.click()}>
                    {translate('upload')}
                  </button>
                  <div className="mt-2">
                    {uploadText.error && (
                      <span className="help-block error-text colfax-regular">
                        {uploadText.error}
                      </span>
                    )}
                    {uploadText.success && (
                      <span className="help-block text-success colfax-regular">
                        {uploadText.success}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <hr className="hr-line-dashed" />
          </div>
        )}
        {gateway.hasConfig && (isHsbcOnline || isDBSFps) && (
          <div>
            <Input
              isRequired
              legend={translate(isDBSFps ? 'fps_org_id' : 'fps_merchant_id').toUpperCase()}
              name="fps_merchant_id"
              value={merchantId}
              onChange={onMerchantIdChange}
              type="text"
              error={translate(...(errors.merchant_id ?? ''))}
            />
          </div>
        )}
        {gateway.hasConfig && fpsWithProxy && (
          <>
            <BankCodeSelector
              value={clearing_code!}
              onChange={onSelectChange('clearing_code')}
              disabled={isDBSFps}
            />

            <Input
              value={fpsProxy}
              onChange={onFpsProxyChange}
              name="fps_proxy_type"
              options={fpsProxyArr}
              defaultLabel={translate('select')}
              type="react-select"
              legend={translate('fps_account_info').toUpperCase()}
              error={translate(...(errors.fps_proxy_type ?? ''))}
            />

            {fpsProxy !== '' && (
              <Input
                legend={translate(fpsProxy).toUpperCase()}
                name={fpsProxy}
                value={
                  fpsProxy == 'fps_id'
                    ? fps_id ?? ''
                    : fpsProxy == 'email'
                    ? email ?? ''
                    : fpsProxy == 'phone'
                    ? phone ?? ''
                    : ''
                }
                onChange={onFpsDetailsChange}
                type="text"
                error={translate(...(errors[`${fpsProxy}`] ?? ''))}
              />
            )}
          </>
        )}
        <div className="d-flex flex-column align-items-center justify-content-center mt-4 mb-2">
          {gateway.hasConfig && isCybersource && isAmex && (
            <Input
              type="checkbox"
              name=""
              value={signature_required ? 1 : 0}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setSignatureRequired(e.target.checked)
              }
              isRequired>
              <div style={{ color: '#9092a5', width: language == 'en' ? 350 : 165 }}>
                <span>{translate('require_signature_optional')}</span>
              </div>
            </Input>
          )}
          <Input
            type="checkbox"
            name=""
            value={agreeTerms}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setAgreeTerms(e.target.checked ? 1 : 0)}
            isRequired>
            <div style={{ width: language == 'en' ? 350 : 165 }}>
              <span style={{ color: '#9092a5' }}>
                {translate('i_have_read_and_understand')}
                &nbsp;
                <a href="/terms.html" target="_blank">
                  {translate('terms')}
                </a>
              </span>
            </div>
          </Input>
          {errors.agreeTerms && (
            <div>
              <span className="help-block text-center error-text fx-12 m-auto">
                {translate(...(errors.agreeTerms ?? ''))}
              </span>
            </div>
          )}
        </div>

        <div className="text-center">
          <button className="btn btn-primary" onClick={onSubmit} disabled={isLoading}>
            {translate('apply')}
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};
