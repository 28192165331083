import { DetailRow } from 'components/custom/DetailRow';
import { CANCELLED } from 'constants/format';
import GATEWAYS from 'constants/TransactionGateways';
import { useTranslate } from 'context/TranslateContext';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { userProps } from 'redux/state/AuthState';
import { GlobalTypes } from 'redux/types';
import { showUser } from 'services/API/User';
import { useSwal } from 'helpers/sweetalert';
import { PreAuthTransactionProps } from 'services/API/PreAuthorize/interface';

interface PayByLinkAuthorizationViewModalProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  preAuth?: PreAuthTransactionProps;
  isAgentOrGatewayProvider: boolean;
}

// @todo Make this function global in TransactionGateways.ts
const findGatewayByCode = (gateway_code: string) => {
  return GATEWAYS.find((gateway) => gateway.gateway_code === gateway_code)?.name;
};

export const PayByLinkAuthorizationViewModal = ({
  isOpen,
  setIsOpen,
  preAuth,
  isAgentOrGatewayProvider,
}: PayByLinkAuthorizationViewModalProps) => {
  if (!preAuth) {
    return <></>;
  }
  const [Swal] = useSwal();
  const { translate } = useTranslate();

  const {
    auth: { user },
  } = useSelector((state: GlobalTypes.RootState) => state);

  const [transactionUser, setTransactionUser] = useState<userProps>({} as userProps);

  const printRef = useRef<HTMLDivElement>(null);

  const getUser = async (user_id: string) => {
    if (!user_id) {
      return;
    }
    const res = await showUser(user_id);
    if (!res.success) {
      Swal.fire({
        title: translate('user'),
        text: res?.message || 'Oops',
        icon: 'error',
      });
      return;
    }
    setTransactionUser(res.data);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (!isAgentOrGatewayProvider) {
      getUser(preAuth.user_id ?? '');
    }
    // eslint-disable-next-line
  }, [preAuth]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="md">
      <ModalHeader toggle={toggle}>{translate('pre_authorize_details')}</ModalHeader>
      <ModalBody>
        <div ref={printRef} className="p-3">
          <div className="transaction-content">
            <h4>
              <DetailRow
                name={translate('company_name')}
                value={user?.company?.name ?? ''}
                nameWidth="col-5"
              />
              <DetailRow
                name={translate('authorization_id')}
                value={preAuth.authorization_id ?? ''}
                nameWidth="col-5"
              />
              <DetailRow
                name={translate('reference_id')}
                value={preAuth.reference_id || '-'}
                nameWidth="col-5"
              />
              <DetailRow
                name={translate('custom_id')}
                value={preAuth.custom_id || '-'}
                nameWidth="col-5"
              />
              {isAgentOrGatewayProvider && (
                <>
                  <DetailRow
                    name={translate('store_name')}
                    value={preAuth.store?.name ?? ''}
                    nameWidth="col-5"
                  />
                  <DetailRow
                    name={translate('store_address')}
                    value={preAuth.store?.address ?? ''}
                    nameWidth="col-5"
                  />
                </>
              )}
              {!isAgentOrGatewayProvider && (
                <>
                  <DetailRow
                    name={translate('created_at')}
                    value={preAuth.created_at ?? ''}
                    nameWidth="col-5"
                  />
                  <DetailRow
                    name={translate('authorized_at')}
                    value={preAuth.authorized_at ?? ''}
                    nameWidth="col-5"
                  />
                  {preAuth.status === CANCELLED.toLowerCase() && (
                    <DetailRow
                      name={translate('cancelled_at')}
                      value={preAuth.cancelled_at ?? ''}
                      nameWidth="col-5"
                    />
                  )}
                </>
              )}
            </h4>
          </div>
          <hr className="dash-border" />
          <div className="transaction-content">
            <h4>
              <div className="row">
                <div className="col-12">
                  <DetailRow
                    name={translate('store_name')}
                    value={preAuth.store?.name ?? ''}
                    nameWidth="col-5"
                  />
                  <DetailRow
                    name={translate('store_address')}
                    value={preAuth.store?.address ?? ''}
                    nameWidth="col-5"
                  />
                  <DetailRow
                    name={translate('phone')}
                    value={preAuth.store?.phone ?? ''}
                    nameWidth="col-5"
                  />
                  <DetailRow
                    name={translate(transactionUser.role)}
                    value={`${transactionUser.first_name} ${transactionUser.last_name}` ?? ''}
                    nameWidth="col-5"
                  />
                </div>
              </div>
            </h4>
          </div>
          {!isAgentOrGatewayProvider && (
            <>
              <hr className="dash-border" />
              <div className="transaction-content">
                <h4>
                  <DetailRow
                    name={translate('amount')}
                    value={`${preAuth.amount} ${preAuth.currency}` ?? ''}
                    nameWidth="col-5"
                  />
                  <DetailRow
                    name={translate('pre_auth_captured')}
                    value={`${preAuth.captured} ${preAuth.currency}` ?? ''}
                    nameWidth="col-5"
                  />
                  <DetailRow
                    name={translate('remaining_amount')}
                    value={`${preAuth.remaining_amount} ${preAuth.currency}` ?? ''}
                    nameWidth="col-5"
                  />
                </h4>
              </div>
              <hr className="dash-border" />
              <div className="transaction-content">
                <h4>
                  <DetailRow
                    name={translate('status')}
                    value={translate(preAuth.status ?? '')}
                    nameWidth="col-5"
                    valueWidth="col-6 h3"
                  />
                </h4>
              </div>
              <hr className="dash-border" />
              <div className="transaction-content">
                <h4>
                  <DetailRow
                    name={translate('gateway')}
                    value={findGatewayByCode(`${preAuth.gateway_code}`) ?? ''}
                    nameWidth="col-5"
                  />
                  <DetailRow
                    name={translate('gateway_id')}
                    value={`${preAuth.gateway_id}` ?? ''}
                    nameWidth="col-5"
                  />
                  <DetailRow
                    name={translate('wallet')}
                    value={preAuth.wallet ?? '-'}
                    nameWidth="col-5"
                  />
                </h4>
              </div>
              <hr className="dash-border" />

              {preAuth.online_payment?.id && (
                <div className="transaction-content">
                  <h4>
                    <DetailRow
                      name={translate('subject')}
                      value={preAuth.online_payment.subject ?? ''}
                      nameWidth="col-5"
                    />
                    <DetailRow
                      name={translate('email')}
                      value={preAuth.online_payment.payment_data?.email ?? ''}
                      nameWidth="col-5"
                    />
                    <DetailRow
                      name={translate('remarks')}
                      value={preAuth.online_payment.remarks ?? ''}
                      nameWidth="col-5"
                    />
                  </h4>
                </div>
              )}
            </>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};
