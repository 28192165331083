import { getList } from 'actions/PreAuthorizeActions';
import { DetailsModal } from 'components/PreAuthorize/DetailsModal';
import { ReportSection } from 'components/custom/ReportSection';
import { Input } from 'components/custom/input';
import { SideFilter } from 'components/custom/sideFilter';
import { CustomTable } from 'components/custom/table/CustomTable';
import { TopHeader } from 'components/layouts/page/topHeader';
import { isAgent, isGatewayProvider } from 'constants/Roles';
import GATEWAY_ARRAY from 'constants/TransactionGateways';
import { PRE_AUTH_STATUS_OPTIONS, TIME_ZONE_FORMAT, translateLabel } from 'constants/format';
import { useTranslate } from 'context/TranslateContext';
import { getNDaysEarlier } from 'helpers/functions/getDays';
import { useSwal } from 'helpers/sweetalert';
import moment from 'moment';
import { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoadOptions } from 'react-select-async-paginate';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Spinner } from 'reactstrap';
import { FunctionDispatch, GlobalTypes } from 'redux/types';
import { getCompanyCredentialList } from 'services/API/Company';
import { PreAuthTransactionProps } from 'services/API/PreAuthorize/interface';

const initialFilterState = {
  start: new Date(getNDaysEarlier(7)),
  end: new Date(),
  authorization_id: '',
  amount: '',
  status: '',
};

const valueToLabel = (value: any) => {
  if (!value) {
    return { label: '', value: null };
  }

  value.toString = () => value.id;
  return {
    label:
      GATEWAY_ARRAY.find((gateway) => gateway.gateway_code === value.gateway_code)
        ?.gateway_sub_name ?? value.gateway_sub_name,
    value: value,
  };
};

export const PreAuthorize = () => {
  const {
    auth: { user, roles, features },
    data: { preAuthTransactions },
  } = useSelector((state: GlobalTypes.RootState) => state);
  const dispatch = useDispatch<FunctionDispatch>();

  const [Swal] = useSwal();
  const { translate } = useTranslate();

  const [orderBy, setOrderBy] = useState('created_at');
  const [orderSortBy, setOrderSortBy] = useState('-');
  const { preAuthTransactions: preAuthData, preAuthTransactionsPagination } = preAuthTransactions;

  const reportSectionRef = useRef<any>(null);
  const [filter, setFilter] = useState(initialFilterState);
  const [isFetching, setIsFetching] = useState(false);
  const [isExportLoading, setIsExportDropdownLoading] = useState(false);
  const [isPageInit, setIsPageInit] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);

  const [selectedPreAuth, setSelectedPreAuth] = useState<null | PreAuthTransactionProps>(null);
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [gateways, setGateways] = useState<Array<any>>([]);

  const [openExportDropdown, setOpenExportDropdown] = useState(false);

  const { start, end, authorization_id, amount, status } = filter;

  const ORDER_TYPE = [
    {
      label: 'created_at',
      value: 'created_at',
    },
    {
      label: 'updated_at',
      value: 'updated_at',
    },
    {
      label: 'authorized_at',
      value: 'authorized_at',
    },
    {
      label: 'expired_at',
      value: 'expired_at',
    },
  ];

  const ORDER_SORT_TYPE = [
    {
      label: 'asc',
      value: '',
    },
    {
      label: 'desc',
      value: '-',
    },
  ];

  const getInitialData = async () => {
    const data = filterToParams();
    setIsFetching(true);
    const res = await dispatch(getList(user?.company_id, data));
    setIsFetching(false);
    if (!res.success) {
      return Swal.fire({
        icon: 'error',
        title: translate('pre_authorize'),
        text: res.message || 'Oops',
      });
    }
  };

  const getCredentialOptions: LoadOptions<any, any> = async () => {
    const filterOptions = { type: 'preAuth' };
    const result = await getCompanyCredentialList(user?.company_id, filterOptions);
    const hasMore = !!result?.meta?.pagination?.links.next ?? false;

    return {
      options: result.data.map(valueToLabel),
      hasMore,
    };
  };

  const exportReport = async (type: string) => {
    const data = filterToParams();

    data.asyncExport = true;
    data.export = type;
    data.include = 'company';
    data.limit = preAuthTransactionsPagination.total || 0;

    setIsExportDropdownLoading(true);
    const res = await dispatch(getList(user?.company_id, data));
    await reportSectionRef?.current?.loadReports();
    setIsExportDropdownLoading(false);
    if (!res.success) {
      Swal.fire({
        icon: 'error',
        title: translate('export'),
        text: res.message || 'Oops',
      });
      return;
    }
  };

  const onFilterDateChange = (date: Date, name: string) => {
    setFilter({
      ...filter,
      [name]: date,
    });
  };

  const onFilterChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    if (e.target.name === 'amount') {
      // Cleave's Event handler has rawValue
      setFilter({
        ...filter,
        amount: (e.target as any).rawValue,
      });
      return;
    }

    setFilter({
      ...filter,
      [e.target.name]: e.target.value,
    });
  };

  const onFilterSelectChange = (name: string) => (value: any) => {
    setFilter({
      ...filter,
      [name]: value,
    });
  };

  const onFilterSearch = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (currentPage === 1) {
      getInitialData();
    } else {
      setCurrentPage(1);
    }
  };

  const filterToParams = () => {
    const data: { [key: string]: any } = {
      gateway_code: gateways.map((g) => g.gateway_code).join(','),
      authorization_id: authorization_id,
      amount: amount,
      status: status,
    };

    const names = Object.keys(data);
    const values = Object.values(data);

    values.forEach((val, index) => {
      if (val === '' || val === '0' || val.length === 0) {
        delete data[names[index]];
      }
    });

    Object.assign(data, {
      'created_at>': moment(start).startOf('day').format(TIME_ZONE_FORMAT),
      'created_at<': moment(end).endOf('day').format(TIME_ZONE_FORMAT),
      limit: 10,
      page: currentPage,
      include: 'store',
      sort: `${orderSortBy}${orderBy}`,
    });

    return data;
  };

  const resetFilter = () => {
    setFilter(initialFilterState);
    setGateways([]);
  };

  const onOrderByChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setOrderBy(e.target.value as any);
  };

  const onOrderSortChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setOrderSortBy(e.target.value as any);
  };

  const tableHeader = () => {
    return (
      <tr>
        <th className="border-end border-top-0">{translate('expired_at')}</th>
        <th className="border-end border-top-0">{translate('store')}</th>
        <th className="border-end border-top-0">{translate('gateway')}</th>
        <th className="border-end border-top-0">{translate('amount')}</th>
        <th className="border-end border-top-0">{translate('pre_auth_captured')}</th>
        <th className="border-end border-top-0">{translate('remaining_amount')}</th>
        <th className="border-end border-top-0">{translate('status')}</th>
        <th className="border-end border-top-0">{translate('created_at')}</th>
        <th className="border-end border-top-0">{translate('authorized_at')}</th>
        <th className="border-end border-top-0">{translate('updated_at')}</th>
        <th className="border-end border-top-0">{translate('captured_at')}</th>
        <th className="border-end border-top-0">{translate('authorization_id')}</th>
      </tr>
    );
  };

  const renderData = () => {
    if (isFetching) {
      return [];
    }

    return preAuthData.map((data, index) => {
      const {
        created_at,
        authorization_id,
        store,
        store_id,
        amount,
        gateway_sub_name,
        currency,
        captured,
        remaining_amount,
        status,
        captured_at,
        updated_at,
        authorized_at,
        expired_at,
      } = data;

      const onClick = () => {
        setSelectedPreAuth(data as any);
        setIsDetailModalOpen(true);
      };

      return (
        <tr className="cursor-pointer" key={index} onClick={onClick}>
          <td className="">{expired_at}</td>
          <td className="">{store?.name ?? store_id}</td>
          <td className="">{gateway_sub_name}</td>
          <td className="">
            <span className="amount" data-currency={currency}>
              {amount}&nbsp;
            </span>
          </td>
          <td className="">
            <span className="amount" data-currency={currency}>
              {captured}&nbsp;
            </span>
          </td>
          <td className="">
            <span className="amount" data-currency={currency}>
              {remaining_amount}&nbsp;
            </span>
          </td>
          <td className="">
            <span>{translate(`preAuthStatus.${status}`)}</span>
          </td>
          <td className="">{created_at}</td>
          <td className="">{authorized_at}</td>
          <td className="">{updated_at}</td>
          <td className="">{captured_at}</td>
          <td className="">{authorization_id}</td>
        </tr>
      );
    });
  };

  useEffect(() => {
    if (features) {
      if (features?.pre_auth?.enable || features?.stores_enabled_tips_adjustment) {
        setIsPageInit(false);
        getInitialData();
        return;
      }
    }
    window.location.href = '/';
    // eslint-disable-next-line
  }, [currentPage]);

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line
  }, [orderBy, orderSortBy]);

  return (
    <>
      {isPageInit ? (
        <p></p>
      ) : (
        <>
          <TopHeader title={translate('pre_authorize')}>
            <Dropdown
              disabled={isFetching}
              isOpen={openExportDropdown}
              toggle={() => setOpenExportDropdown(!openExportDropdown)}
              className="d-inline-block">
              <DropdownToggle
                disabled={isExportLoading}
                className="header-button btn btn-success"
                caret
                color="#ffffff">
                {isExportLoading ? <Spinner size="sm" /> : translate('export')}
              </DropdownToggle>
              <DropdownMenu classnames="lang-dropdown">
                <DropdownItem
                  classnames="btn btn-sm btn-muted text-center"
                  onClick={() => exportReport('xlsx')}>
                  {translate('generate_excel_report')}
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </TopHeader>
          <div id="main-content" className="hide-scrollbar">
            <CustomTable
              renderHeading={() => tableHeader()}
              renderData={renderData}
              totalColumn={11}
              setCurrentPage={setCurrentPage}
              pagination={preAuthTransactionsPagination}
              isLoading={isFetching}
            />
          </div>
          <div id="side-content" className="hide-scrollbar">
            <ReportSection ref={reportSectionRef} type="authorizations" />
            <div className="filter-container d-flex flex-column justify-content-between bg-white shadow-sm mb-4">
              <form className="filter-form mt-0">
                <Input
                  type="select"
                  options={translateLabel(ORDER_TYPE, translate, true)}
                  value={orderBy}
                  onChange={onOrderByChange}
                  legend={translate('order').toUpperCase()}
                  name="type"
                  disabled={isFetching}
                />
                <Input
                  type="select"
                  options={translateLabel(ORDER_SORT_TYPE, translate, true)}
                  value={orderSortBy}
                  onChange={onOrderSortChange}
                  legend={translate('order_sort').toUpperCase()}
                  name="type"
                  disabled={isFetching}
                />
              </form>
            </div>
            <SideFilter
              isLoading={isFetching || isExportLoading}
              onFilter={onFilterSearch}
              resetFilter={resetFilter}>
              <Input
                type="date"
                value={start}
                legend={translate('from').toUpperCase()}
                name="start"
                onChange={onFilterDateChange}
                placeholder="YYYY-MM-DD"
              />
              <Input
                type="date"
                value={end}
                legend={translate('to').toUpperCase()}
                name="end"
                onChange={onFilterDateChange}
                placeholder="YYYY-MM-DD"
              />
              <Input
                type="cleave"
                cleaveOptions={{
                  numeral: true,
                  numeralDecimalScale: 2,
                  numeralIntegerScale: 7,
                  numeralPositiveOnly: true,
                }}
                legend={translate('amount').toUpperCase()}
                value={amount}
                name="amount"
                onChange={onFilterChange}
                placeholder={translate('amount')}
              />
              <Input
                type="text"
                legend={translate('authorization_id').toUpperCase()}
                value={authorization_id}
                name="authorization_id"
                onChange={onFilterChange}
                placeholder={translate('authorization_id')}
              />
              <Input
                type="react-select"
                legend={translate('status').toUpperCase()}
                value={status}
                options={translateLabel(PRE_AUTH_STATUS_OPTIONS, translate)}
                defaultLabel={translate('all_types')}
                name="status"
                onChange={onFilterSelectChange('status')}
                menuPortalTarget={document.body}
              />
              {!isGatewayProvider(user.role) && !isAgent(user.role) && (
                <Input
                  isMulti
                  type="react-select-async"
                  legend={translate('gateway').toUpperCase()}
                  loadOptions={getCredentialOptions}
                  value={gateways.map(valueToLabel)}
                  name="gateways"
                  onChange={setGateways}
                  placeholder={translate('gateway')}
                  menuPortalTarget={document.body}
                />
              )}
            </SideFilter>
          </div>
          {selectedPreAuth && (
            <DetailsModal
              isAgentOrGatewayProvider={isAgent(user.role) || isGatewayProvider(user.role)}
              refreshData={getInitialData}
              isOpen={isDetailModalOpen}
              setIsOpen={setIsDetailModalOpen}
              preAuth={selectedPreAuth as any}
            />
          )}
        </>
      )}
    </>
  );
};
