import yedpay from 'assets/img/navbar_components/logo.png';
import scanLogo from 'assets/img/print_qrcode/scan-logo.png';
import { useTranslate } from 'context/TranslateContext';
import { useSwal } from 'helpers/sweetalert';
import { Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap';
import { ANY_OBJECT, SUCCESS } from 'constants/format';
import { useState } from 'react';
import { debugPayQrCode } from 'services/API/TopUpBalance';

interface DisplayCodeModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  QRCodeStore: ANY_OBJECT;
}

export const DisplayCodeModal = ({ isOpen, setIsOpen, QRCodeStore }: DisplayCodeModalProps) => {
  const { translate } = useTranslate();
  const [Swal] = useSwal();

  const [isImgLoading, setIsImgLoading] = useState(true);
  const [isDebugButtonLoading, setIsDebugButtonLoading] = useState(false);

  const FPS_STATUS_SUCCESS = 8000;
  const FPS_STATUS_FAIL = 8002;

  const toggle = async () => {
    if (isOpen) {
      const result = await Swal.fire({
        title: translate('close'),
        text: translate('are_you_sure_close_qr_code'),
        icon: 'warning',
        showCancelButton: true,
      });

      result.value && setIsOpen(false);
      return;
    }
    setIsOpen(!isOpen);
  };

  const debugQrCodePay = (debugResponseStatus: number) => async () => {
    setIsDebugButtonLoading(true);

    const data = {
      ref: debugReferenceId,
      amount: debugAmount,
      status: debugResponseStatus,
    };

    try {
      const res = await debugPayQrCode(data);

      if (res?.status === 'SUCCESS') {
        const statusString = debugResponseStatus == FPS_STATUS_SUCCESS ? 'Success' : 'Fail';
        Swal.fire({
          icon: 'success',
          title: translate('qr_code'),
          text: 'Pay with QR Code (status : ' + statusString + ')',
        });

        setIsDebugButtonLoading(false);
        return;
      }
      Swal.fire({
        icon: 'error',
        title: translate('qr_code'),
        text: res?.message || 'Oops',
      });

      setIsDebugButtonLoading(false);
      return;
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: translate('qr_code'),
        text: error?.response?.data?.message || 'Oops',
      });

      setIsDebugButtonLoading(false);
    }
  };

  const storeName = QRCodeStore?.store_name ?? '';
  const QRCode = QRCodeStore?._links?.[0]?.href ?? '';

  // for debug use
  const debugReferenceId = QRCodeStore?.debug_reference_id;
  const debugAmount = QRCodeStore?.amount;
  const debugShowFpsFailButton = QRCodeStore?.debug_show_fps_fail_button;

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{translate('qr_code')}</ModalHeader>
      <ModalBody>
        <div className="text-center">
          <h1 className="fx-35">{storeName}</h1>
          <div className="row justify-content-center">
            <div className="col-9 border bg-muted rounded py-2">
              <img
                src={QRCode}
                alt="qr-code"
                onLoad={() => setIsImgLoading(false)}
                className="img-fluid w-80"
              />
              {isImgLoading && (
                <div>
                  <Spinner />
                </div>
              )}
              <div className="mt-2">
                <div className="d-flex justify-content-center align-items-center">
                  <img src={scanLogo} alt="" className="img-fluid me-3 w-15" />
                  <p className="fx-14 m-0">{translate('scan_with_apps_with_FPS')}</p>
                </div>
              </div>
            </div>
            <div className="text-center text-danger mt-3 px-5">
              <h4 className="fx-12">{translate('top_up_balance_code_warning')}</h4>
            </div>
            <div className="text-center mt-2">
              <h4 className="fx-16">{translate('payment_tech_provided_by_yedpay')}</h4>
              <img src={yedpay} alt="" className="img-fluid w-30" />
            </div>
            {debugReferenceId && (
              <div className="text-center mt-2">
                <button
                  className="btn btn-success"
                  type="button"
                  disabled={isImgLoading || isDebugButtonLoading}
                  onClick={debugQrCodePay(FPS_STATUS_SUCCESS)}>
                  {isImgLoading || isDebugButtonLoading ? (
                    <Spinner size="sm" />
                  ) : (
                    'Testing-Pay-Success'
                  )}
                </button>
              </div>
            )}
            {debugShowFpsFailButton && debugReferenceId && (
              <div className="text-center mt-2">
                <button
                  className="btn btn-danger"
                  type="button"
                  disabled={isImgLoading || isDebugButtonLoading}
                  onClick={debugQrCodePay(FPS_STATUS_FAIL)}>
                  {isImgLoading || isDebugButtonLoading ? (
                    <Spinner size="sm" />
                  ) : (
                    'Testing-Pay-Fail'
                  )}
                </button>
              </div>
            )}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
