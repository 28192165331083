import { useTranslate } from 'context/TranslateContext';
import { ReactSelectAsync } from '..';
import { getUserList } from 'services/API/User';
import { components as ReactSelectComponents } from 'react-select';
import { SelectOptionProps } from 'components/custom/input/interfaces';

export interface UserSelectProps {
  showStatus?: boolean;
  isRequired?: boolean;
  disabled?: boolean;
  defaultOption?: Array<SelectOptionProps>;
  showDefault?: boolean;
  setUser: (store: any) => void;
  user: any;
  searchOptionsParams?: Record<string, any>;
  error?: string;
  legend?: string;
}

export const UserSelect = ({
  isRequired,
  disabled,
  defaultOption,
  showDefault,
  showStatus,
  setUser,
  user,
  searchOptionsParams,
  error,
  legend,
}: UserSelectProps) => {
  const { translate } = useTranslate();
  defaultOption =
    defaultOption ??
    (showDefault === true ? [{ label: translate('select'), value: null }] : undefined);

  const onSelectUserChange = () => (val: any) => {
    setUser(val);
  };

  const valueToLabel = (value: any) => {
    if (!value) {
      return defaultOption?.[0];
    }
    value.toString = () => value.id;
    return {
      label: `${value.username}`,
      value: value,
      status: value.disabled === false ? 'enabled' : 'disabled',
    };
  };

  const Option = (props: any) => {
    return (
      <ReactSelectComponents.Option {...props}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>{props.data.label}</span>
        </div>
      </ReactSelectComponents.Option>
    );
  };

  return (
    <ReactSelectAsync
      disabled={disabled}
      isRequired={isRequired}
      name="user"
      legend={legend ?? ''}
      onSelectChange={onSelectUserChange}
      value={user}
      valueToLabel={valueToLabel}
      defaultOption={defaultOption}
      searchOptions={getUserList}
      searchOptionsParams={{ limit: 20, ...searchOptionsParams }}
      components={{ Option }}
      error={translate(error ?? '')}
      style={{
        menu: (provided: any) => ({
          ...provided,
          zIndex: 10,
        }),
        input: (provided: any) => ({
          ...provided,
          marginTop: '2px',
          marginBottom: '0px',
        }),
      }} //To show the dropdown above the pagnation
    />
  );
};
