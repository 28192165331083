import { DetailRow } from 'components/custom/DetailRow';
import { Input } from 'components/custom/input';
import {
  ACTIVE,
  INACTIVE,
  MERCHANT_TOKEN_STATUS_OPTIONS,
  PENDING,
  translateLabel,
} from 'constants/format';
import { useTranslate } from 'context/TranslateContext';
import { useSwal } from 'helpers/sweetalert';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';
import { GlobalTypes } from 'redux/types';
import {
  deleteMerchantToken,
  editMerchantToken,
  getMerchantTokenDetails,
} from 'services/API/MerchantToken';
import { MerchantTokenProps } from 'services/API/MerchantToken/interface';
import { validateEditToken } from './validator';

interface DetailsModalProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  paymentToken?: MerchantTokenProps;
  refreshData: () => void;
}

const initialData = {
  custom_id: '',
  status: '',
};

export const DetailsModal = ({
  isOpen,
  setIsOpen,
  paymentToken,
  refreshData,
}: DetailsModalProps) => {
  if (!paymentToken) {
    return <></>;
  }

  const [Swal] = useSwal();
  const { translate } = useTranslate();
  const {
    auth: { user },
  } = useSelector((state: GlobalTypes.RootState) => state);

  const [formData, setFormData] = useState(initialData);
  const [errors, setErrors] = useState<any>({});
  const [isDetailsLoading, setIsDetailsLoading] = useState(true);
  const [detailsData, setDetailsData] = useState<MerchantTokenProps>({} as MerchantTokenProps);
  const [isLoading, setIsLoading] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const onChange = (e: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const onFieldChange = (name: string) => (val: string | number) => {
    setFormData({
      ...formData,
      [name]: val,
    });
  };

  const getStatusOptions = (status: string) => {
    if (status !== PENDING) {
      return MERCHANT_TOKEN_STATUS_OPTIONS.filter((item) => item.value !== PENDING);
    }
    return MERCHANT_TOKEN_STATUS_OPTIONS;
  };

  const getDetails = async () => {
    setIsDetailsLoading(true);
    const data = {
      include: ['store'],
    };

    try {
      const res = await getMerchantTokenDetails(user?.company_id, paymentToken?.id, data);
      if (res.success) {
        setDetailsData(res.data);
        setFormData({
          custom_id: res.data.custom_id,
          status: res.data.status,
        });
      } else {
        throw new Error(res.message);
      }
    } catch (error: any) {
      await Swal.fire({
        title: translate('payment_token_details'),
        text: error.message || 'Oops',
        icon: 'error',
      });
      toggle();
    } finally {
      setIsDetailsLoading(false);
    }
  };

  const deleteToken = async () => {
    setIsLoading(true);
    const res = await deleteMerchantToken(detailsData?.id);
    setIsLoading(false);

    await Swal.fire({
      icon: res.success ? 'success' : 'error',
      title: translate('delete_token'),
      text: res.message ?? 'Oops',
    });
    if (!res.success) {
      return;
    }

    refreshData();
    toggle();
  };

  const confirmDeleteToken = () => {
    Swal.fire({
      icon: 'warning',
      title: translate('delete_token'),
      text: translate('are_you_sure_delete_token'),
      showConfirmButton: true,
      showCancelButton: true,
      preConfirm: () => deleteToken(),
    });
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const data = {
      ...formData,
    };

    const { errors, isValid } = validateEditToken(data, user?.company_id ?? '');
    setErrors(errors);
    if (!isValid) {
      return;
    }

    setIsLoading(true);
    const res = await editMerchantToken(detailsData?.id, data);
    setIsLoading(false);

    if (!res.success) {
      await Swal.fire({
        title: translate('edit_payment_token'),
        text: res.message || 'Oops',
        icon: 'error',
      });
      return;
    }
    await Swal.fire({
      icon: 'success',
      title: translate('edit_payment_token'),
      text: translate('congratulations_payment_token_successfully_updated'),
    });
    refreshData();
    toggle();
  };

  useEffect(() => {
    isOpen && getDetails();
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="md">
      <ModalHeader toggle={toggle}>{translate('payment_token_details')}</ModalHeader>
      <ModalBody>
        <div className="p-2">
          {isDetailsLoading ? (
            <>
              <div
                style={{
                  textAlign: 'left',
                  margin: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                }}>
                <Spinner />
              </div>
            </>
          ) : (
            <form onSubmit={onSubmit}>
              <div className="text-left">
                <Input
                  isRequired
                  type="text"
                  value={formData.custom_id ?? ''}
                  name="custom_id"
                  legend={translate('custom_id').toUpperCase()}
                  onChange={onChange}
                  error={translate(...(errors.custom_id ?? ''))}
                />
                <Input
                  isRequired
                  type="text"
                  value={detailsData?.store?.name ?? ''}
                  name="store"
                  legend={translate('store').toUpperCase()}
                  disabled={true}
                />
                {detailsData.gateway_sub_name && (
                  <Input
                    type="text"
                    value={detailsData.gateway_sub_name ?? ''}
                    name="gateway"
                    legend={translate('gateway').toUpperCase()}
                    disabled={true}
                  />
                )}
                {detailsData.name && (
                  <Input
                    type="text"
                    value={detailsData.name ?? ''}
                    name="name"
                    legend={translate('name').toUpperCase()}
                    disabled={true}
                  />
                )}
                <Input
                  isRequired
                  legend={translate('status').toUpperCase()}
                  type="react-select"
                  options={translateLabel(getStatusOptions(formData.status), translate, true)}
                  value={formData.status}
                  onChange={onFieldChange('status')}
                  name="status"
                  disabled={detailsData.status == PENDING ? true : false}
                  error={translate(...(errors.status ?? ''))}
                />
                <Input
                  type="text"
                  value={detailsData.created_at ?? ''}
                  name="created_at"
                  legend={translate('created_at').toUpperCase()}
                  disabled={true}
                />
              </div>
              <div className="text-center flex-fill">
                <button className="btn btn-primary btn-sm me-2" disabled={isLoading}>
                  {isLoading ? <Spinner size="sm" /> : translate('submit')}
                </button>
                {[ACTIVE, INACTIVE].indexOf(detailsData.status) > -1 && (
                  <button
                    type="button"
                    className="btn btn-danger btn-sm"
                    disabled={isLoading}
                    onClick={confirmDeleteToken}>
                    {translate('delete')}
                  </button>
                )}
              </div>
            </form>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};
