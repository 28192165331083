import {
  AddOtherMfaValidateProps,
  CreateMfaValidateProps,
  VerifyCodeValidateProps,
  VerifyMfaValidateProps,
} from './interface';
import { MfaType } from 'constants/mfa';
import {
  email,
  packValidateResults,
  required,
  isInt,
  lengthEqual,
  phone,
} from 'helpers/validators';

export const validateAddOtherMfa = (data: AddOtherMfaValidateProps) => {
  return packValidateResults([required(data, Object.keys(data))]);
};

export const validateCreateMfa = (data: CreateMfaValidateProps, mfaType: MfaType | null) => {
  const result = [];

  switch (mfaType) {
    case MfaType.TOTP:
      result.push(required(data, ['display']));
      break;
    case MfaType.SMS:
      result.push(required(data, ['display', 'phone']));
      result.push(isInt(data, ['phone']));
      result.push(lengthEqual(data, ['phone'], { min: 8 }));
      result.push(phone(data, ['phone']));
      break;
    case MfaType.EMAIL:
      result.push(required(data, ['display', 'email']));
      result.push(email(data, ['email']));
      break;
    default:
      break;
  }

  return packValidateResults(result);
};

export const validateVerifyMfa = (data: VerifyMfaValidateProps, verifyCodeNo: number) => {
  const result = [];

  const validateData: { [key: string]: string } = {};

  const fieldName = `verifyCode${verifyCodeNo}`;

  validateData[fieldName] = data.verifyCode?.[verifyCodeNo] ?? '';

  result.push(required(validateData, [fieldName]));
  result.push(isInt(validateData, [fieldName]));
  result.push(lengthEqual(validateData, [fieldName], { min: 6 }));

  return packValidateResults(result);
};

export const validateVerifyCode = (data: VerifyCodeValidateProps) => {
  const result = [];

  result.push(required(data, ['verifyCode']));
  result.push(isInt(data, ['verifyCode']));
  result.push(lengthEqual(data, ['verifyCode'], { min: 6 }));

  return packValidateResults(result);
};
