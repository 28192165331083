import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useTranslate } from 'context/TranslateContext';
import { MFA_OPTIONS, MfaType } from 'constants/mfa';

import { useState } from 'react';
import { OtpInput } from 'components/custom/OtpInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import { validateVerifyCode } from './validator';

export interface VerifyCodeModalProps {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  mfaType: MfaType | undefined;
  entity?: string;
  onSubmit?: (verifyCode: string) => Promise<boolean>;
  onResendCode?: () => Promise<boolean>;
}

export const VerifyCodeModal = ({
  isModalOpen,
  setIsModalOpen,
  mfaType,
  entity,
  onSubmit,
  onResendCode,
}: VerifyCodeModalProps) => {
  const { translate, language } = useTranslate();

  const [verifyCode, setVerifyCode] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [isResendingCode, setIsResendingCode] = useState(false);

  const [errors, setErrors] = useState<any>({});

  const reset = () => {
    setIsLoading(false);
    setIsResendingCode(false);

    setVerifyCode('');
    setErrors({});
  };

  const toggle = () => {
    if (isLoading || isResendingCode) {
      return;
    }

    setIsModalOpen(!isModalOpen);
    reset();
  };

  const spacingBetweenWords = () => {
    return language == 'en' ? ' ' : '';
  };

  const getModelHeader = () => {
    return spacingBetweenWords() + translate('two_step_verification');
  };

  const getVerifyCodeReminder = () => {
    const msgPrefix = translate('please_enter_the_verification_code') + spacingBetweenWords();

    switch (mfaType) {
      case MfaType.SMS:
        return msgPrefix + translate('sent_to') + spacingBetweenWords() + entity;
      case MfaType.EMAIL:
        return msgPrefix + translate('sent_to') + spacingBetweenWords() + entity;
      case MfaType.TOTP:
        return msgPrefix + translate('shown_in_your_authenticator_application');
      default:
        return '';
    }
  };

  const canResendCode = () => {
    const mfa = MFA_OPTIONS.find((mfa) => mfa.value == mfaType);

    if (mfa != null) {
      return mfa.canResendCode;
    }

    return false;
  };

  const handleVerifyCodeChange = (verifyCode: string) => {
    if (/^\d*$/.test(verifyCode)) {
      setVerifyCode(verifyCode);
    }
  };

  const onSubmitButtonClick = async () => {
    setIsLoading(true);

    // validation
    const { errors, isValid } = validateVerifyCode({ verifyCode: verifyCode });

    setErrors(errors);

    if (!isValid) {
      setIsLoading(false);
      return;
    }

    if (onSubmit) {
      const isSuccess = await onSubmit(verifyCode);
      if (isSuccess) {
        setIsLoading(false);
        toggle();
        return;
      }
    }

    setIsLoading(false);
  };

  const onResendButtonClick = async () => {
    // if Mfa type is TOTP , not support resend code
    if (mfaType == MfaType.TOTP) {
      return;
    }

    setIsResendingCode(true);

    if (onResendCode) {
      const isSuccess = await onResendCode();
      if (isSuccess) {
        setIsResendingCode(false);
        return;
      }
    }

    setIsResendingCode(false);
    toggle();
  };

  return (
    <Modal isOpen={isModalOpen} toggle={toggle} backdrop="static">
      <ModalHeader toggle={toggle}>{getModelHeader()}</ModalHeader>
      <ModalBody>
        <p className="fs-6">{getVerifyCodeReminder()}</p>
        <OtpInput
          legend={translate('verify_code')}
          name={verifyCode}
          value={verifyCode}
          handleChange={handleVerifyCodeChange}
          placeholder={translate('enter_verify_code')}
          isLoading={isLoading || isResendingCode}
          errMsg={translate(...(errors.verifyCode ?? ''))}
          showResendButton={canResendCode()}
          onResendClick={onResendButtonClick}
          startCountDownWhenInit={true}
        />
        <div className="d-grid">
          <button
            className="btn btn-primary mt-3 rounded-3"
            onClick={onSubmitButtonClick}
            disabled={isLoading || isResendingCode}>
            {translate('submit')}
            {isLoading && <FontAwesomeIcon spin={true} icon={faSpinner} />}
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};
