import asyncRequestHandler from 'helpers/functions/AsyncRequestHandler';
import { FunctionDispatch, ThunkResult } from 'redux/types';
import { getTopUpBalanceHistory } from 'services/API/TopUpBalance';
import { receiveData } from './DataAction';

export const getTopUpBalanceList = (
  company_id: string,
  data: object,
): ThunkResult<Promise<Record<string, any>>> =>
  asyncRequestHandler(async (dispatch: FunctionDispatch) => {
    const res = await getTopUpBalanceHistory(company_id, data);
    if (res.success) {
      dispatch(receiveData(res, 'topUpBalanceHistory'));
    }

    return res;
  });
