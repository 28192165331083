import asyncRequestHandler from 'helpers/functions/AsyncRequestHandler';
import { FunctionDispatch, ThunkResult } from 'redux/types';
import { receiveData } from './DataAction';
import { AnyResponse } from 'services/API/common/Fetcher';
import { getMerchantTokens } from 'services/API/MerchantToken';

export const getList = (company_id: string, data: any = {}): ThunkResult<Promise<AnyResponse>> =>
  asyncRequestHandler(async (dispatch: FunctionDispatch) => {
    const res = await getMerchantTokens(company_id, data);
    if (!data.export && res.success) {
      dispatch(receiveData(res, 'merchantTokens'));
    }
    return res;
  });
