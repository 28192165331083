import { StoreSelect } from 'components/custom/ReactSelectAsync/Select/StoreSelect';
import { Input } from 'components/custom/input';
import { useTranslate } from 'context/TranslateContext';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap';
import { GlobalTypes } from 'redux/types';
import { precreateMerchantToken } from 'services/API/MerchantToken';
import { CreateMerchantTokenProps } from 'services/API/MerchantToken/interface';
import { validate } from './validator';
import { useSwal } from 'helpers/sweetalert';
import { ONLINE_STORE } from 'constants/format';

interface CreateMerchantTokenModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  reloadData: () => void;
}

const initialState = {
  custom_id: '',
} as CreateMerchantTokenProps;

export const CreateMerchantTokenModal = ({
  isOpen,
  setIsOpen,
  reloadData,
}: CreateMerchantTokenModalProps) => {
  const {
    auth: { user },
  } = useSelector((state: GlobalTypes.RootState) => state);
  const [Swal] = useSwal();
  const { translate } = useTranslate();

  const [form, setForm] = useState<CreateMerchantTokenProps>(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [store, setStore] = useState<any>(null);

  const { custom_id } = form;

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const onChange = (e: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const data = {
      ...form,
      ...(store && { store_id: store?.id ?? '' }),
    };

    const { errors, isValid } = validate(data, user.company_id);
    console.log(errors);
    setErrors(errors);
    if (!isValid) {
      return;
    }

    setIsLoading(true);
    const res = await precreateMerchantToken(user.company_id, data);
    setIsLoading(false);

    if (!res.success) {
      await Swal.fire({
        title: translate('create_payment_token'),
        text: res.message || 'Oops',
        icon: 'error',
      });
      return;
    }

    await Swal.fire({
      icon: 'success',
      title: translate('create_payment_token'),
      text: translate('congratulations_new_payment_token_successfully_created'),
    });
    reloadData();
    toggle();
  };

  useEffect(() => {
    if (!isOpen) {
      setForm(initialState);
      setStore(null);
      setErrors({});
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{translate('create_payment_token')}</ModalHeader>
      <ModalBody>
        <div className="p-2">
          <form className="d-flex flex-column" onSubmit={onSubmit}>
            <Input
              isRequired
              type="text"
              value={custom_id}
              name="custom_id"
              legend={translate('custom_id').toUpperCase()}
              onChange={onChange}
              error={translate(...(errors.custom_id ?? ''))}
            />
            <StoreSelect
              legend={translate('store').toUpperCase()}
              isRequired
              showStatus
              setStore={setStore}
              store={store}
              searchOptionsParams={{ include: 'status', type: ONLINE_STORE }}
              error={errors.store_id}
            />
            <button className="btn btn-primary btn-block mt-3" disabled={isLoading}>
              {isLoading ? <Spinner size="sm" /> : translate('submit')}
            </button>
          </form>
        </div>
      </ModalBody>
    </Modal>
  );
};
